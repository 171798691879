import { handleResponse, handleError, ajax } from "../utils/Api";

export const checkEmail = email => {
  return ajax
    .post("/zain/check-email", {email: email})
    .then(handleResponse)
    .catch(handleError);
}

export const validateIP = ip => {
  return ajax
  .get(`/zain/ftth-ip-check?ip=${ip}`)
  .then(handleResponse)
  .catch(handleError);
}