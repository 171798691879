import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { extractUrl, getBannerImageSrc } from "../utils/common";

const Settings = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { lng } = useSelector((state) => {
    return {
      lng: state?.app?.lng,
    };
  });

  const path = lng === "ar" ? "-ar" : "";

  const location = useLocation();
  const { telcoName, countryCode } = extractUrl(location.pathname);
  let logoEndpoint = "";

  if (countryCode === "sa" && telcoName === "zain") {
    logoEndpoint = `${telcoName}/${countryCode}/logos-header-${telcoName}${path}.svg`;
  } else if (
    (countryCode === "qa" || countryCode === "tn") &&
    telcoName === "ooredoo"
  ) {
    logoEndpoint = `${telcoName}/qa/logos-header-${telcoName}${path}.svg`;
  } else {
    logoEndpoint = `${telcoName}/logos-header-${telcoName}${path}.svg`;
  }

  return (
    <>
      <Header
        withBackBtn={false}
        position="relative"
        logoEndpoint={logoEndpoint}
      />
      <Outlet />
      {/* <div className="wrapper-bg" style={{backgroundImage: `url(${getBannerImageSrc(lng, isMobile)})`}}>
        <div className="wrapper-out">
          <Outlet />
        </div>
      </div>
      <Footer isRevamp={true} /> */}
    </>
  );
};

export default Settings;
