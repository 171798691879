import { handleResponse, handleError, ajax } from "../utils/Api";

export const getRetryEngineDetails =  (startDate, endDate) => {
  return ajax
  .post("/retryengine/getDetails", {startDate, endDate},{headers:{
  "Content-Type": "application/json",
  'Authorization': localStorage.getItem('token')}})
  .then(handleResponse)
  .catch(handleError);
  }
  
  export const getReTryEngineBatchDetails =  (batchId) => {
     return ajax
     .post("/retryengine/getBatchDetails", {batchId},{headers:{
     "Content-Type": "application/json",
     'Authorization': localStorage.getItem('token')}})
     .then(handleResponse)
     .catch(handleError);
     }

  
