import { withStyles } from "@material-ui/core/styles";

const GlobalCss = withStyles((theme) => ({
  "@global": {
    ".mB2": {
      marginBottom: theme.spacing(2),
    },
    ".mB3": {
      marginBottom: theme.spacing(3),
    },
    ".mT2": {
      marginTop: theme.spacing(2),
    },
    ".mT4": {
      marginTop: theme.spacing(4),
    },
    ".mT6": {
      marginTop: theme.spacing(6),
    },
    ".mL2": {
      marginLeft: theme.spacing(2),
    },
    ".wrapper-bg": {
      width: '100%',
      backgroundSize: '100%',
      // backgroundSize: 'cover',
      [theme.breakpoints.down("sm")]: {
        backgroundSize: '100%',
      },
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
    },
    '.wrapper-out': {
        [theme.breakpoints.down("sm")]: {
          padding: '12px 24px',
          width: "auto",
          position: "relative",
          margin: 'auto',
          boxShadow: 'none',
          borderRadius: 'none',
        },
        [theme.breakpoints.up("sm")]: {
          width: "560px",
        },
        [theme.breakpoints.up("md")]: {
          minWidth: 560,
          width: '45%',
          padding: '40px 7%',
          // backdropFilter: 'blur(20px)',
        // '-webkitBackdropFilter': 'blur(10px)',
          minHeight: '90vh',
          // backgroundColor: 'rgba(27, 27, 29, .5)',
        },
    },
    ".wrapper": {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: theme.spacing(5),
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        padding: '32px 20px 0 20px',
      },
      [theme.breakpoints.up("sm")]: {
        width: 375,
        marginLeft: "auto",
        marginRight: "auto",
       },
    },
    ".wrapper-error": {
      width: 335,
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: theme.spacing(5),
      '& .error-text': {
        paddingTop: '32px',
        '& a': {
          color: '#ff00d3',
        }
      },
      [theme.breakpoints.up("xs")]: {
        width: "auto",
        padding: '32px 10px 0 10px',
      },
      [theme.breakpoints.up("sm")]: {
        width: 335,
        marginLeft: "auto",
        marginRight: "auto",
       },
    },
    ".welcome-logo": {
      width: '212px',
      display: 'inline-block',
      '& img': {
        maxWidth: '100%',
        display: 'block',
      }
    },
    "#app" : {
      minWidth: 360,
    },
    "body[dir='rtl']" : {
      fontFamily: 'FrutigerLTArabicRoman',
      '& .MuiTypography-h1, & .wrapper-error .MuiTypography-colorSecondary': {
        fontFamily: 'FrutigerLTArabicBold',
      },
      '& .MuiTypography-body2, & .MuiTypography-subtitle2, & .MuiTypography-button': {
        fontFamily: 'FrutigerLTArabicRoman',
      },
      '& .MuiTypography-h4, & .MuiTypography-body1, & .text-wrapper .MuiTypography-h2, & .wrapper-error .MuiTypography-h2': {
        fontFamily: 'FrutigerLTArabicLight',
      }
    }
  },
}))(() => null);

export default GlobalCss;
