import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    color: '#ff00d3',
    fontSize: 14,
    background: 'transparent',
    border: 'none'
  },
  buttonD: {
    color: 'grey',
    fontSize: 14,
    background: 'transparent',
    border: 'none'
  }
}));

const customCsvHeader = {
  retryTime: 'Date',
  customerId: 'Customer ID',
  chargeAmount: 'Charge Amount',
  currency: 'Currency',
  paymentRetryStatus: 'Retry Status',
  reactivationStatus: 'Reactivation Success',
  error: 'Error',
  paymentMethod: 'Payment Method',
}

function JsonToCSVButton({ json, isRetryMap=false, isError=false, csvName="download" }) {
  const classes = useStyles();

  const  jsonToCsv = (items) => {
    const header = Object.keys(items[0]);
  
    const headerString = !isRetryMap ? header.join(',') : header.map((title) => {
      return customCsvHeader[title];
    }).join(',');
  
    // handle null or undefined values here
    const replacer = (key, value) => value ?? '';
  
    const rowItems = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
  
    // join header and body, and break into separate lines
    const csv = [headerString, ...rowItems].join('\r\n');
  
    return csv;
  }
  
  const handleDownloadCSV = () => {
    if(isError) {
      alert('You cannot download the batch details report due to incorrect data');
      return
    }
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(jsonToCsv(json));
    hiddenElement.target = '_blank';

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = `${csvName}_${new Date().toLocaleString()}.csv`;
    hiddenElement.click();
  }

  return (
    <Fragment>
      <button className={classes.button} onClick={handleDownloadCSV}>Download CSV</button>
    </Fragment>
  );
}

JsonToCSVButton.propTypes = {
  json: PropTypes.array,
  onClick: PropTypes.func,
  csvName: PropTypes.string,
  isRetryMap: PropTypes.bool,
  isError: PropTypes.bool
}

JsonToCSVButton.defaultProps = {
  json: [{"Id":1,"UserName":"Sam Smith"},
  {"Id":2,"UserName":"Fred Frankly"},
  {"Id":3,"UserName":"Zachary Zupers"}],
}

export default JsonToCSVButton;