import * as types from "../actions/actionTypes";

const initialState = {
  lng: null,
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_LNG:
      return { ...state, ...action.data };
    case types.GET_IP: 
      return {...state, ...action.data};
    default:
      return state;
  }
}
