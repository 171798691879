import React from 'react';
import { useSelector } from "react-redux";
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import PropType from 'prop-types';

const useStyles = makeStyles(() => ({
  packageWrapper: {
    height: 50,
    border: "1px solid rgb(255, 255, 255, 0.5)",
    cursor: "pointer",
    borderRadius: 12,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiTypography-h3': {
      fontFamily: props => props.lng === 'ar' ? 'FrutigerLTArabicBold' : 'FaricyNewBold',
    }
  },
  activeWrapper: {
    borderColor: "#FF00D3",
    backgroundColor: 'rgba(180, 0, 153, 0.3)',
  },
  opacity1: {
    opacity: "0.5",
  },
  divider: {
    width: "90%",
    display: "flex",
    height: 2,
    background: "#f0f0f0",
    borderRadius: "7.8px",
    margin: 'auto',
  },
  activeColor: {
    background: "#ff0000"
  }
}));

const Package = ({ isBorderRight, isDisabled, isActive, onClick, text }) => {
  const lng = useSelector((state) => state?.app?.lng);
  const classes = useStyles({lng});
  return (
    <>
      <Box onClick={() => onClick(isActive)} className={clsx(
          classes.packageWrapper,
          isBorderRight ? classes.rightBorder : classes.leftBoarder,
          isDisabled && classes.opacity1,
          isActive && classes.activeWrapper,
        )}>
        <Typography variant="h3" className={clsx(isDisabled && classes.opacity1)}>{text}</Typography>
      </Box>
    </>
  )
}

Package.propTypes = {
  isBorderRight: PropType.bool,
  isDisabled: PropType.bool,
  isActive: PropType.bool,
  onClick: PropType.func,
  text: PropType.string,
}

export default Package;