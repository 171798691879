import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sendEvent } from '../../api/events';
import withTelcoHOC from '../../hoc/withTelco';
import { getCustomerStatus,getValue } from '../../utils/common';

const Error = ({telcoName, countryCode, lang}) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const isZainkw = countryCode === "kw" && telcoName === "zain" ? true: false;

  useEffect(() => {
    sendEvent(`${telcoName}-${countryCode}-Timeout`, 'LANDED', `Something went wrong ${state?.prevPath}`);
    let timer = setTimeout(() => triggerDataLayer(), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const triggerDataLayer = () => {
    window.dataLayer.push({
      event: 'TelcoJourney',
      eventCategory:'Telco - SUF',
      eventAction:'Step 3 -Payment Timeout',
      OperatorName: telcoName || '',
      Country: countryCode || '',
      Language: lang,
      PaymentOption: 'telco',
      ErrorCode: '48',
      FailureReason: 'Timeout',
      Connection: state?.isHE,
      Type: state?.type || 'dcb',
      FreeTrialPeriod:state.customerStatus === null ? isZainkw ? getValue(state?.offer): state?.offer.freeTrialCycleNumber : null,
      FreeTrial: getCustomerStatus(state.customerStatus, state.offer),
      PackageName:state?.offer?.offerName?.toLowerCase(),
      PackagePrice:state?.offer?.price,
    });
  };

  return (
    <Box className="wrapper-error">
      <Typography className="mT4" variant="h2" align="center">{t('OTT_TLP_timeoutError_msg')}</Typography>
    </Box>
  );
}

Error.propTypes = {
  telcoName: PropTypes.string,
  countryCode: PropTypes.string,
  lang: PropTypes.string,
}

export default withTelcoHOC(Error);