import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  root: {
    flexGrow: 1,
  },
  palette: {
    primary: {
      main: "#FFFFFF",
      contrastText: "#303030",
    },
    secondary: {
      main: "#B40099",
      contrastText: "#fff",
      succ: "#eeffee",
      err: "#ffeeee",
    },
    text: {
      secondary: "#fff",
    },
    input: {
      border: "#d0d0d0",
      main: "transparent",
    },
    backgroundRgba: {
      paper: 'rgba(180, 0, 153, 0.3)'
    }
  },

  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: "2px solid #B40099",
        },
        '&:after': {
          borderBottom: "2px solid #B40099",
        },
        '&:before': {
          borderBottomColor: 'rgb(255 255 255 / 42%)',
        }
      },
    },
    MuiListItemText: {
      root: {
        display: "grid",
      },
    },
    MuiButton: {
      startIcon: {
        marginRight: 4,
      },
      contained: {
        boxShadow: 0,
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiMenuItem: {
      gutters: {
        padding: 10,
      }
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },

    MuiAlert: {
      icon: {
        display: "none",
      },
      standardSuccess: {
        width: 375,
        color: "#303030",
        backgroundColor: "#eeffee",
        display: "flex",
        justifyContent: "center",
      }
    },
    MuiSnackbar: {
      anchorOriginTopCenter: {
        top: "0px !important",
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiMenu: {
      list: {
        maxHeight: 400,
      },
    },
    MuiAvatar: {
      root: {
        borderRadius: 8,
        width: 59,
        height: 59,
      },
    },
    MuiInputLabel: {
      root: {
        padding: 0,
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 20,
      }
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: 10,
      }
    },
    // Custom Slider
    MuiSlider: {
      rail: {
        backgroundColor: "#C0C0C0",
      },
      mark: {
        height: 6,
        width: 6,
        top: "40%",
        // top: "43%",
        borderRadius: "50%",
        backgroundColor: "#C0C0C0",
      },
      markActive: {
        height: 6,
        width: 6,
        top: "40%",
        // top: "43%",
        borderRadius: "50%",
        backgroundColor: "#ff0000",
      },
      thumb: {
        height: 16,
        width: 16,
        backgroundColor: "#FFFFFF",
        border: "1px solid #ff0000",
        // boxShadow: iOSBoxShadow,
        marginTop: -8,
        marginLeft: -8,
        "&:focus, &:hover, &$active": {
          boxShadow:
            "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            // boxShadow: iOSBoxShadow,
          },
        },
      },
    },
  },

  typography: {
    h1: {
      fontSize: 22,
      textAlign: "center",
      lineHeight: "normal",
      fontFamily: "FaricyNewBold",
      fontWeight: "bold",
    },
    h2: {
      fontSize: 16,
      lineHeight: "normal",
      fontWeight: "normal",
      fontFamily: "FaricyNewRegular",      
    },
    h3: {
      fontSize: 20,
      fontFamily: "FaricyNewBold",
      letterSpacing: "0.15px",
      textAlign: "center",      
      fontWeight: "bold",
    },
    h4: {
      fontSize: 14,
      lineHeight: "normal",
      fontWeight: "normal",
      textAlign: "center",
      fontFamily: "FaricyNewRegular",
    },
    body1: {
      fontSize: 12,
      fontFamily: "FaricyNewRegular",
      fontWeight: "normal",
      color: "#303030",
    },
    body2: {
      fontSize: 16,
      fontFamily: "FaricyNewMedium",
      color: "#fff",
      textAlign: "center",
      fontWeight: 500,
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontSize: 12,
      fontFamily: "FaricyNewMedium",
      color: "#808080",
      fontWeight: "normal",
      textAlign: 'center',
    },
    subtitle2: {
      fontSize: 12,
      fontFamily: "FaricyNewRegular",
      color: "#d0d0d0",
      fontWeight: "normal",
      textAlign: 'center',
    },
    button: {
      fontSize: 16,
      fontFamily: "FaricyNewBold",
      fontWeight: "bold",
    },
    caption: {
      fontSize: 10,
    },
    header: {
      fontSize: 18,
      fontWeight: 500,
    },
    overline: {
      color: '#ff0000',
    }
  },
});

export default theme;
