import React, {useEffect} from "react";
import { AppBar, Grid, Button } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useStyles } from "./header.styles";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/actions/appActions";

const Header = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const arabicBtnColor = '#fff';

  const locale = new URLSearchParams(window.location.search).get("locale");
  const classes = useStyles({lng: locale});

  useEffect(() => {
    const lng = locale === "ar-AE" ? "ar" : "en"
    i18n.changeLanguage(lng);
    dispatch(changeLanguage({ lng }));
  }, [locale]);

  const handleClick = () => {
    // setAnchorEl(event.currentTarget);
    const href = window.location;
    const newUrl = new URL(href);
    if (!locale || locale === "en-US") {
      newUrl.searchParams.set("locale", "ar-AE");
    } else {
      newUrl.searchParams.set("locale", "en-US");
    }
    navigate(newUrl);
  };

  const state = useSelector((state) => ({
    lng: state?.app?.lng,
  }));
  return (
    <AppBar className={clsx(classes.appBar, classes.ulpHeader)}>
      <Toolbar
        className={classes.ulpToolBar}
      >
        <Grid container item xs={6} justify="flex-start">
          <img
            src={`${process.env.S3_ENDPOINT}/common/logo-osn.svg`}
            className="logo-osn"
            width="56"
            height="36"
          />
        </Grid>
        <Grid container justify="flex-end" item xs={6}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Typography className={classes.menuButton} noWrap style={{color: arabicBtnColor}}>
              {(state?.lng === "ar" && `${t('OTT_Language_Arabic')}`) || t("OTT_Language_Arabic")}
            </Typography>
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;