import React from "react";
import {
  Card,
  CardContent,
  Button, Grid, TextField
} from '@material-ui/core/'
import { verifyLogin } from "../../api/customer";
import {useNavigate} from 'react-router-dom';
const Swal = require('sweetalert2');
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../components/Loader";

const useStyles = makeStyles(() => ({
  loginCard :{
    width: "40%"
  },
  formLogo: {
    textAlign: "center",
    margin: "20px 20px 20px 20px"
  },
  crd:{
    width: "100%",
    textAlign: "center"
  },
  input:{
    width:"90%",
    '& [type="text"]': {
      color:'#333',
    },
    '& ::before': {
      borderBottomColor: 'rgb(0 0 0 / 42%)'
    },
    '& label.Mui-focused': {
      color:'#333',
    },
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus':  {
      '-webkit-text-fill-color': 'inherit',
    }
  }

}));


const Login =(props) =>{
  const classes = useStyles();

    const [username,setusername] = React.useState("");
    const [password,setpassword] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    const [loaderText, setLoaderText] = React.useState("");
    const navigate = useNavigate();
   
    const handleKeyDown = (e) =>{
      if (e.key === 'Enter') {
        handleLogin();
      }
    }
    const handleLogin =() =>{
      setLoader(true);
      setLoaderText("Loading details"); 
      if(username.length>0&& password.length>0)
    { 
      verifyLogin(username,password).then(data => {
        if (data.messageResponse && data.messageResponse == "Wrong credential") {
          errorAlertBox('Invalid Credentials');
        }
        else {
          setLoader(false);
          if (props.from === 'customercare') {
            if (data.role !== "RetryEngine") {
              setDataInStorage(data, username);
              navigate('/search');
            } else {
              errorAlertBox('Invalid Credentials')
            }
          } else {
            if(data.role === "RetryEngine") {
              setDataInStorage(data, username);
              navigate('/redashboard');
            } else {
              errorAlertBox('Invalid Credentials')
            }
          }
         
        }
      }).catch(error => {
        errorAlertBox("Cannot login")
      });
    }
    else{
      errorAlertBox("Please enter username and password")
    }
    };
    const errorAlertBox = (message) => {
      setLoader(false);
      Swal.fire({customClass:{confirmButton: 'MuiButton-root MuiButton-containedSecondary'},buttonsStyling: false,title: 'Error', text: message,icon: 'error'})
    }
    const setDataInStorage = (data, username) => {
      localStorage.setItem('user_data', JSON.stringify({
        ...data,
        user: username
      }))
      localStorage.setItem('token',data.token);
    }

    if (loader) {
      return <Loader loaderText={loaderText} />;
    }
    return(
        <div className="container">
        <div className={classes.formLogo}>
        <img alt="Osn" src={`${process.env.S3_ENDPOINT}/common/logo-osn.svg`} />
      </div>
      <Grid  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center">
      <Grid item className={classes.loginCard}>
        <Card variant="outlined" className={classes.crd}>
       <CardContent>
        <div className='form_title'>
          Log<span>I</span>n
        </div>
        <form className='form_items'>
          <div className='form_inputs'>
            <TextField variant="standard"
              type='text'
              label="Username"
              required
              onChange={e => setusername(e.target.value)}
              value={username}
              className={classes.input}
              />
          </div>
          <div className='form_inputs'>
            <TextField variant="standard"
              type='password'
              label="Password"
              required
              onChange={e => setpassword(e.target.value)}
              onKeyDown={handleKeyDown}
                value={password}
                className={classes.input}

              />
          </div>
          <br/>
          <Button variant="contained" color="secondary" onClick={handleLogin}>Log In</Button>
        </form>
        </CardContent>
      </Card>
      </Grid>
      </Grid>
      </div>
    );

}
export default Login;