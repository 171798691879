import { handleResponse, handleError, ajax, ajaxWithAuth } from "../utils/Api";
const API_TIMEOUT = process.env.API_TIMEOUT || 25000;
import axios from "axios";

export const getProducts = async (telcoName, countryCode) => {
  return ajaxWithAuth
    .get(`/telco/get-products?telcoName=${telcoName}&countryName=${countryCode}`,  {headers: {withCredentials: true}})
    .then(handleResponse)
    .catch(handleError);
}

export const sendOtp = async apiParams => {
  const reqParams = {...apiParams};
  return ajaxWithAuth
    .post('/telco/send-otp', { reqParams },  {headers: {withCredentials: true}})
    .then(handleResponse)
    .catch(handleError);
}

export const resendOtp = async apiParams => {
  const reqParams = {...apiParams};
  return ajaxWithAuth
    .post('/telco/resend-otp', { reqParams },  {headers: {withCredentials: true}})
    .then(handleResponse)
    .catch(handleError);
}

export const verifyOTp = async apiParams => {
  const reqParams = {...apiParams};
  return ajaxWithAuth
    .post('/telco/verify-otp', { reqParams }, {timeout: API_TIMEOUT})
    .then(handleResponse)
    .catch(handleError);
}

export const verifyCivilId = async apiParams => {
  const reqParams = {...apiParams};
  return ajaxWithAuth
    .post('/telco/verify-civilid', { reqParams }, {timeout: API_TIMEOUT})
    .then(handleResponse)
    .catch(handleError);
}

export const getSubscription = async () => {
  return ajax
  .get('/telco/get-subscription', {timeout: 10000})
  .then(handleResponse)
  .catch(handleError);
}

export const getProductsQueryParams = async (telcoName, countryCode, customerNumber = '', queryParams) => {
  let query = '';
  if(!queryParams) {
    query = '?'
  } else {
    query = `${queryParams}&`
  }
  return ajaxWithAuth
    .get(`/telco/get-products${query}customerNumber=${customerNumber}&telcoName=${telcoName}&countryName=${countryCode}`,  {headers: {withCredentials: true}})
    .then(handleResponse)
    .catch(handleError);
}

export const getIP = async() => {
  try {
  const response = await fetch('https://jsonip.com/');
  const data = await response.json();
  return data.ip;
  } catch(err) {
    return '234.099.99.00';
  }
}

export const getCountryWithIP = async() => {
  return axios.get(`${process.env.GATEWAY_ENDPOINT}/telco-spa-facade/v1/countries`,  { headers: {'Ocp-Apim-Subscription-Key': process.env.GATEWAY_KEY}});
}

export const subscribed = async apiParams => {
  const reqParams = {...apiParams};
  return ajaxWithAuth
  .post('/telco/subscribed', { reqParams }, {timeout: API_TIMEOUT})
  .then(handleResponse)
  .catch(handleError);
}