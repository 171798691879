import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    '& a': {
      color: theme.palette.secondary.main,
      fontSize: 14,
    }
  },
  input: {
    position: 'relative',
    border: '1px solid #d0d0d0',
    backgroundColor: theme.palette.input.main,
    textAlign: 'center',
    fontSize: 24,
    height: 56,
    width: '50px !important',
    borderRadius: 4,
  },
  inputContainer: {
    justifyContent: 'center',
    '& div': {
      marginRight: 10,
    }
  },
  incorrectMsg: {
    fontSize: 10,
    letterSpacing: '0.25px',
    color: '#ff0000',
    fontWeight: 600,
    marginTop: 16
  },
  mobileText: {
    fontWeight:'bold',
  },
  otpHeading: {
    '& span': {
      color: '#95c11f',
    }
  },
  button: {
    color: '#ff0000',
    fontSize: 14,
    background: 'transparent',
    border: 'none'
  },
}));

export default useStyles;