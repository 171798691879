import React, { useState, useEffect } from 'react';
import { Button, TextField, Select, MenuItem} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from "@material-ui/core/styles";
import ConfirmDialog from '../../components/ConfirmDialog';

const useStyles = makeStyles(() => ({
  modifyPopup : {
    color: '#000',
    fontSize: 14,
    '& .MuiDialog-paperScrollPaper': {
       border: '5px solid #B40099',
    },
    '& .MuiTypography-colorTextSecondary' : {
      color: '#000',
    },
    '& .MuiInputLabel-root': {
      display: 'inline-block',
      marginRight: 10,
    },
    '& .MuiDialogContent-root': {
      fontSize: 16,
    },
    '& .MuiDialogContentText-root': {
      fontSize: 16,
    },
    '& .row-wise': {
      marginBottom: 20,
      '& .MuiInput-multiline': {
        width: '100%',
        border: '1px solid #cbcbcb',
        padding: 5,
      },
      '&.comment-box': {
        '& .MuiFormControl-root':  {
          width: '70%'
        }
      }
    },
    '& .MuiFormControl-root': {
      'flex-direction': 'row',
      width: '30%'
    },
    '& .MuiInput-root': {
      marginLeft: 10,
      fontSize: 14,
    },
    '& .MuiSelect-selectMenu': {
      width: 90,
      padding: 5,
      border: '1px solid #cbcbcb',
    }
  },
  modifyPopupTitle: {
    backgroundColor: '#B40099',
    color: '#fff',
    padding: '7px 24px'
  },
  modifyPopupContent: {
    paddingTop: 30,
    '& sup': {
      color: '#B40099'
    }
  }
}));

let initialStatus;
const getOfferData = (operatorName, eligbileData) => {
  const selectedTelcoProducts = eligbileData.operators.find((operator)=> operator.operatorName === operatorName);
  return selectedTelcoProducts.productDetails;
}
const ModifyCustomerDialog = (props) => {
  const {handleClose, openDialog, customerIdProp, currentStatusProp, currentTelcoProp, eligbileData, handleUpdate, selectedCountry } = props;
  const classes = useStyles();
  const [currentStatus,setCurrentStatus] = useState(currentStatusProp);
  const [currentTelco,setCurrentTelco] = useState(currentTelcoProp);
  const [currentOffer,setCurrentOffer] = useState("");
  const [currentProductCode,setCurrentProductCode] = useState("");
  const [currentTelcoProduct,setCurrentTelcoProduct] = useState((eligbileData && currentTelco) ? getOfferData(currentTelco, eligbileData) : "");
  const [disabledOptions,setDisabledOptions] = useState(currentStatusProp === 'ACTIVE' ? true : false);
  const [commentValue,setCommentValue] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  

  useEffect(() => {
    if(currentStatusProp !== "ACTIVE" ? (commentValue.length > 5 && initialStatus !== currentStatus && currentTelco && currentOffer) : (commentValue.length > 5 && initialStatus !== currentStatus)) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
  },[commentValue, currentStatus, currentTelco, currentOffer]);

  useEffect(() => {
    initialStatus = currentStatusProp;
  },[]);

  
  const handleStatusChange = (e)=>{
    setCurrentStatus(e.target.value);
  }
  const handleTelcoChange = (e)=>{
    const targetVal = e.target.value;
    setCurrentTelco(targetVal);
    setCurrentTelcoProduct(targetVal ? getOfferData(targetVal, eligbileData): "");
    setCurrentOffer("");
  }
  const handleOfferChange = (e)=>{
    const targetValue = e.target.value;
    const selectedTelcoProducts = currentTelcoProduct.find((product)=> product.offerCode === targetValue);
    setCurrentProductCode(selectedTelcoProducts.productCode)
    setCurrentOffer(targetValue);
  }
  const openConfirDialogBox = () => {
    setOpenConfirmDialog(true);
  }
  const hideConfirDialogBox = (yesNo) => {
    setOpenConfirmDialog(false);
    if (yesNo === 'yes') {
      handleUpdate({ customerId:customerIdProp, operation: currentStatus, operatorName: currentTelco, offerCode: currentOffer, comments:commentValue,productCode:currentProductCode })
    }
  }

  
  return (
    <Dialog className={classes.modifyPopup} fullWidth={true} maxWidth='md' minWidth='md' open={openDialog} onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle className={classes.modifyPopupTitle} id="max-width-dialog-title">Modify Customer Details</DialogTitle>
      <DialogContent className={classes.modifyPopupContent}>
        <Box className='row-wise'>
        <strong>Customer ID/Subscription ID/Email</strong>: {customerIdProp}
        </Box>
        <Box className='row-wise'>
          <FormControl>
            <strong>Change Status</strong>
            <Select
              labelId="status-select-label"
              id="demo-simple-select"
              value={currentStatus}
              displayEmpty
              label="Status"
              onChange={handleStatusChange}
            >
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">Inactive</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <strong>Change Telco</strong>
            <Select
              labelId="telco-select-label"
              value={currentTelco}
              displayEmpty
              label="Telco"
              onChange={handleTelcoChange}
              disabled={disabledOptions}
            >
              <MenuItem value="">Options</MenuItem>
              {eligbileData && eligbileData.operators.map((operator, index) => {
                return <MenuItem key={index} value={operator.operatorName}>{operator.operatorName} {selectedCountry}</MenuItem>
              })}
              {!eligbileData && <MenuItem value={currentTelcoProp}>{currentTelcoProp} {selectedCountry}</MenuItem>}
            </Select>
          </FormControl>
          <FormControl>
            <strong>Change Offer</strong>
            <Select
              labelId="offer-select-label"
              value={currentOffer}
              displayEmpty
              label="Offer"
              onChange={handleOfferChange}
              disabled={disabledOptions}
            >
              <MenuItem value="">Options</MenuItem>
              {currentTelcoProduct && currentTelcoProduct.map((telcoProduct, index) => {
                return <MenuItem key={index} value={telcoProduct.offerCode}>{telcoProduct.offerCode}-{telcoProduct.offerName}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
        <Box className='row-wise comment-box'>
          <strong>Comments<sup>*</sup></strong>
          <TextField
            placeholder="300 Limits"
            multiline
            rows={5}
            maxRows={8}
            inputProps={{ maxLength: 300 }}
            required={true}
            value={commentValue}
            onChange={(e) => setCommentValue(e.target.value)} 
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {openConfirmDialog && <ConfirmDialog openConfirmDialog={openConfirmDialog} hideConfirDialogBox={hideConfirDialogBox} />}
        <Button variant="contained" onClick={handleClose} >Cancel</Button>
        <Button variant="contained" disabled={btnDisabled} onClick={openConfirDialogBox} color="secondary">Update</Button>
      </DialogActions>
    </Dialog>  
  )

}
export default ModifyCustomerDialog;
