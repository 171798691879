import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import validator from "validator";
import qs from "qs";
import Timer from "../../../components/Timer";
import SnackMessage from "../../../components/SnackMessage";
import { verifyOTp, sendOtp } from "../../../api/packs";
import Loader from "../../../components/Loader";

import {
  SUCCESS,
  INCORRECTOTP,
  OTPLIMITREACHED,
  CUSTOMER_ALREADY_EXIST,
  USER_BLOCKED,
  INSUFFICIENT_BALANCE,
  TIMEOUT,
  TIMEOUT_STRING,
  CUSTOMER_DOSE_NOT_EXIST,
  OTP_EXPIRED,
} from "../../../constants/sdpCodes";
import {
  LANDED,
  CLICKED_OTP,
  LANDED_WITH_REDIRECT,
  CLICKED_RESEND,
  OTP_SUCCESS,
  OTP_INCORRECT,
  OTP_FAILED,
  VerifyOTP,
  VerifyOTPFails,
  PaymentSuccess,
  PaymentFails,
  CLICKED_RESEND_FAILED,
  TIMEOUT_HIT,
  ERROR_API,
} from "../../../constants/events";
import { sendEvent } from "../../../api/events";
import { constructUtmParams, gaDataLayer, freeTrialCyclePeriod } from "../../../utils/common";
import { cEventOnVerifyOtpAuthJourney, cEventOnVerifyOtpFailedAuthJourney, cEventOnTelcoOtpPageView, cEventOnUserLogin } from "../../../utils/clevertap";
import otpStyles from "../../../utils/otpStyles";
import { ksaZainURL } from "../../../constants/urls";
import { ZAIN_KSA_PACKAGES } from "../../../constants/packages";

const timerOtp = 60;

const page = "ZAIN-SA-OTP-VERIFICATION";
const telcoParams = { telcoName: "zain", country: "sa" };

const OtpVerification = () => {
  const { state } = useLocation();
  let navigate = useNavigate();
  const classes = otpStyles();
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [isIncorrectOtp, setIncorrectOtp] = useState({ show: false, msg: "" });
  const [otpMsgBlock, setOtpMsgBlock] = useState({ msg: "", level: "" });
  const [loaderText, setLoaderText] = useState("");
  const [purchaseToken, setPurchaseToken] = useState(null);
  const lng = useSelector((state) => state?.app?.lng);
  const ipAddress = useSelector((state) => state?.app?.clientIp);
  const qsParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const locale = qsParams?.locale ?? "en-US";
  const ksaZainUrls = ksaZainURL(locale);

  const gaParams = {
    ...telcoParams,
    type: state?.type,
    offer: state?.offer,
    lng: lng,
    connection: state?.isHE,
    customerStatus: state?.customerStatus,
  };

  useEffect(() => {
    if (otpMsgBlock.msg) {
      setOpen(true);
    }
  }, [otpMsgBlock]);

  useEffect(() => {
    sendEvent(page, LANDED, `MobileNumber: ${state?.mobileNumber}`);
    gaDataLayer(gaParams);
    cEventOnTelcoOtpPageView('sa', window.location.href, "OTP Page");
  }, []);

  if (!state?.mobileNumber || !state?.purchaseToken) {
    sendEvent(page, LANDED_WITH_REDIRECT, "Landed on otp page but redirected");
    return <Navigate to={ksaZainUrls.pack} />;
  }

  const handleChange = (otp) => {
    validator.isLength(otp, { min: 4 })
      ? setDisableBtn(true)
      : setDisableBtn(false);
    setOtp(otp);
  };

  // resent otp code logic
  const resendCode = () => {
    setLoader(true);
    setLoaderText(t("OTT_TLP_sendotp_Loader_Msg"));
    const params = {
      ...constructUtmParams({}),
      customerId: state?.mobileNumber,
      subscriptionTypeId: state?.offer?.subscriptionTypeId,
      offerCode: state?.offer?.offerCode,
      telcoName: "zain",
      countryName: "sa",
      tid: state?.tid,
      ipAddress,
    };
    sendOtp(params)
      .then((data) => {
        const { responseCode, purchaseId } = data;
        if (responseCode === SUCCESS) {
          sendEvent(
            page,
            CLICKED_RESEND,
            `Code resend to this customer ${state?.mobileNumber}`
          );
          setLoader(false);
          setPurchaseToken(purchaseId);
          setOtpMsgBlock({ msg: "OTP code just resent", level: "success" });
          setOtp("");
        } else if (responseCode === OTPLIMITREACHED) {
          setLoader(false);
          setOtpMsgBlock({
            msg: t("OTT_TLP_OTP_maximumLimitReached_msg"),
            level: "error",
          });
          window.history.replaceState({}, document.title);
          setTimeout(() => {
            navigate(ksaZainUrls.pack);
          }, 3000);
        } else {
          sendEvent(
            page,
            CLICKED_RESEND_FAILED,
            `Code resend failed to this customer ${state?.mobileNumber}`
          );
          window.history.replaceState({}, document.title);
          navigate(ksaZainUrls.error, {
            state: { prevPath: location.pathname },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        sendEvent(
          page,
          ERROR_API,
          `${state?.mobileNumber}`,
          JSON.stringify(err),
          "error"
        );
        navigate(ksaZainUrls.error, { state: { prevPath: location.pathname } });
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const verifyOtpReq = () => {
    sendEvent(page, CLICKED_OTP, "");
    setLoader(true);
    setLoaderText(t("OTT_TLP_OTP_verifyOtp_loader_msg"));
    const verifyOtpParams = {
      pin: otp,
      purchaseId: purchaseToken ? purchaseToken : state?.purchaseToken,
      mobileNumber: state?.mobileNumber,
      offerCode: state?.offer?.offerCode,
      language: lng,
      telcoName: "zain",
      countryName: "sa",
      tid: state?.tid || "",
      ipAddress,
    };
    const offer = state?.offer;
    const subscriptionPlan = offer.offerName;
    verifyOTp(verifyOtpParams)
      .then((data) => {
        // const data = {
        //   osnGatewayCustomerId: "c41e48ae-85ae-4ddf-9607-b89b6ef8a052",
        //   subscriptionGroupId: "d76afbab-5588-4bcf-955f-2a1ff7292cec",
        //   responseCode: 0,
        //   messageResponse: "Success",
        //   productOffer: {
        //     productCode: "07",
        //     offerCode: "350701",
        //     offerName: "monthly",
        //     productCategory: "VOICE",
        //     price: 39,
        //     cycleNumber: 1,
        //     freeTrialCycleNumber: 7,
        //     cyclePeriod: "MONTH",
        //     freeTrialCyclePeriod: "DAY",
        //     currency: "SAR",
        //     subscriptionTypeId: "1000036431",
        //     productType: 'PREMIUM',
        //     mopEnabled: false,
        //     cancelFromSettings: false,
        //     displayPrice: null,
        //     displayCurrency: null
        //   },
        //   isFreeTrial: true,
        //   newCustomer: true,
        // };
        if (data.responseCode === SUCCESS) {
          sendEvent(
            page,
            OTP_SUCCESS,
            `OTP verified to this customer ${state?.mobileNumber}`
          );
          cEventOnVerifyOtpAuthJourney('zain', 'sa');
          cEventOnUserLogin(data.osnGatewayCustomerId, '+'+state?.mobileNumber);
          gaDataLayer({ 
            ...gaParams,
            isFreeTrial: data.isFreeTrial,
            trialPeriod: freeTrialCyclePeriod(
              data.isFreeTrial ? state?.offer?.freeTrialCyclePeriod : null,
              state?.offer?.freeTrialCycleNumber
            ),
            eventParams: VerifyOTP 
          });
          gaDataLayer({ ...gaParams, 
            isFreeTrial: data.isFreeTrial,
            trialPeriod: freeTrialCyclePeriod(
              data.isFreeTrial ? state?.offer?.freeTrialCyclePeriod : null,
              state?.offer?.freeTrialCycleNumber
            ),
            eventParams: PaymentSuccess });
          window.history.replaceState({}, document.title);
          navigate(ksaZainUrls.welcome, {
            state: {
              offer: state?.offer,
              data,
              prevPath: location.pathname,
              isHE: state?.isHE,
              type: state?.type,
            },
          });
        } else if (data.responseCode === INCORRECTOTP) {
          sendEvent(
            page,
            OTP_INCORRECT,
            `Incorrect OTP to this customer ${state?.mobileNumber}`
          );
          const errorParams = {
            code: data.responseCode,
            reason: "otp verification Failed",
          };
          gaDataLayer({
            ...gaParams,
            eventParams: VerifyOTPFails,
            errorParams,
          });
          setIncorrectOtp({ show: true, msg: "OTT_TLP_OTP_incorrectOtp" });
          setLoader(false);
          setOtp('');
          setDisableBtn(false);
          cEventOnVerifyOtpFailedAuthJourney('zain', 'sa', subscriptionPlan, offer.productType, offer.price, offer.currency, data.responseCode, 'Incorrect OTP');
        } else if (data.responseCode === OTP_EXPIRED) {
          setIncorrectOtp({ show: true, msg: "OTT_TLP_OTP_expiredOtp" });
          setLoader(false);
          setOtp('');
          setDisableBtn(false);
          cEventOnVerifyOtpFailedAuthJourney('zain', 'sa', subscriptionPlan, offer.productType, offer.price, offer.currency, data.responseCode, 'OTP get expired');
        } else if (data.responseCode === CUSTOMER_ALREADY_EXIST) {
          sendEvent(
            page,
            OTP_FAILED,
            `Customer Already Exist ${state?.mobileNumber}`
          );
          gaDataLayer({
            ...gaParams,
            eventParams: {
              freeTrialCycleNumber: false,
              freeTrialCyclePeriod: 0,
            },
          });
          cEventOnVerifyOtpFailedAuthJourney('zain', 'sa', subscriptionPlan, offer.productType, offer.price, offer.currency, data.responseCode, 'Customer Already Exist');
          navigate(ksaZainUrls.subError, {
            state: { prevPath: location.pathname, isHE: state?.isHE },
          });
        } else if (data.responseCode === INSUFFICIENT_BALANCE) {
          sendEvent(
            page,
            OTP_FAILED,
            `insufficient balance ${state?.mobileNumber}`
          );
          cEventOnVerifyOtpFailedAuthJourney('zain', 'sa', subscriptionPlan, offer.productType, offer.price, offer.currency, data.responseCode, 'insufficient balance');
          navigate(ksaZainUrls.insufficientBalance, {
            state: { prevPath: location.pathname, isHE: state?.isHE },
          });
        } else if (data.responseCode === USER_BLOCKED) {
          sendEvent(page, OTP_FAILED, `User blocked ${state?.mobileNumber}`);
          setIncorrectOtp({ show: true, msg: "OTT_TLP_OTP_userBlocked" });
          cEventOnVerifyOtpFailedAuthJourney('zain', 'sa', subscriptionPlan, offer.productType, offer.price, offer.currency, data.responseCode, 'User blocked');
        } else if (data.responseCode === TIMEOUT) {
          sendEvent(page, TIMEOUT_HIT, `${state?.mobileNumber}`);
          cEventOnVerifyOtpFailedAuthJourney('zain', 'sa', subscriptionPlan, offer.productType, offer.price, offer.currency, data.responseCode, `Time out due to backend error`);
          navigate(ksaZainUrls.timeout, {
            state: {
              prevPath: location.pathname,
              isHE: state?.isHE,
              offer: state?.offer,
              customerStatus: state?.customerStatus,
            },
          });
        } else if (data.responseCode === CUSTOMER_DOSE_NOT_EXIST) {
          sendEvent(page, "BAD MSISDN", `${state?.mobileNumber}`);
          const errorParams = { code: data.responseCode, reason: "Bad msisdn" };
          gaDataLayer({
            ...gaParams,
            eventParams: VerifyOTPFails,
            errorParams,
          });
          cEventOnVerifyOtpFailedAuthJourney('zain', 'sa', subscriptionPlan, offer.productType, offer.price, offer.currency, data.responseCode, `Customer does not exist`);
          navigate(ksaZainUrls.error, {
            state: { prevPath: location.pathname },
          });
        } else {
          sendEvent(
            page,
            OTP_FAILED,
            `OTP verified failed to this customer ${state?.mobileNumber}`
          );
          const errorParams = {
            code: data.responseCode,
            reason: "otp verification Failed",
          };
          gaDataLayer({
            ...gaParams,
            eventParams: VerifyOTPFails,
            errorParams,
          });
          gaDataLayer({ ...gaParams, eventParams: PaymentFails, errorParams });
          cEventOnVerifyOtpFailedAuthJourney('zain', 'sa', subscriptionPlan, offer.productType, offer.price, offer.currency, data.responseCode, `OTP verified failed to this customer ${state?.mobileNumber}`);
          navigate(ksaZainUrls.error, {
            state: { prevPath: location.pathname },
          });
        }
      })
      .catch((err) => {
        if (err?.code && err?.code === TIMEOUT_STRING) {
          sendEvent(
            page,
            TIMEOUT_HIT,
            `${state?.mobileNumber}`,
            JSON.stringify(err),
            "error"
          );
          cEventOnVerifyOtpFailedAuthJourney('zain', 'sa', subscriptionPlan, offer.productType, offer.price, offer.currency, err?.code, `Time out due to connection aborted`);
          navigate(ksaZainUrls.timeout, {
            state: {
              prevPath: location.pathname,
              isHE: state?.isHE,
              offer: state?.offer,
            },
          });
        } else {
          sendEvent(
            page,
            ERROR_API,
            `${state?.mobileNumber}`,
            JSON.stringify(err),
            "error"
          );
          navigate(ksaZainUrls.error, {
            state: { prevPath: location.pathname, isHE: state?.isHE },
          });
        }
      });
  };

  if (loader) {
    return <Loader loaderText={loaderText} />;
  }

  return (
    <Box className="wrapper">
      <SnackMessage
        open={open}
        msg={otpMsgBlock.msg}
        level={otpMsgBlock.level}
        close={handleClose}
      />
      <Typography
        variant="h1"
        className={classes.otpHeading}
        dangerouslySetInnerHTML={{
          __html: t(ZAIN_KSA_PACKAGES[state?.offer?.subscriptionTypeId]),
        }}
      ></Typography>
      <Typography className="mT2" variant="h4">
        {t("OTT_TLP_OTP_KwStc_subTitle")}{" "}
        <span dir="ltr" className={classes.mobileText}>
          +{state?.mobileNumber}
        </span>
      </Typography>
      <Grid className="mT4" dir="ltr">
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={4}
          inputStyle={classes.input}
          containerStyle={classes.inputContainer}
          isInputNum={true}
          shouldAutoFocus={true}
        />
      </Grid>
      {isIncorrectOtp?.show && (
        <Typography className={classes.incorrectMsg} align="center">
          {t(isIncorrectOtp.msg)}
        </Typography>
      )}
      <Typography className={classes.link}>
        <Timer seconds={timerOtp} onClick={resendCode} />
      </Typography>
      <Grid className="mT6">
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          fullWidth
          disabled={!disableBtn}
          onClick={verifyOtpReq}
        >
          <Typography variant="button">
            {t("OTT_TLP_OTP_submit_Text")}
          </Typography>
        </Button>
      </Grid>
    </Box>
  );
};

export default OtpVerification;
