import React from 'react'
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { extractUrl } from '../utils/common';

const withTelcoHOC = WC => {
  const WithTelco = () => {
    const location = useLocation();
    const { telcoName, countryCode } = extractUrl(location.pathname);
    const lang = useSelector((state) => state?.app?.lng);
    if(telcoName === 'iam') {
      return <WC telcoName= 'maroctelecom' countryCode={countryCode} lang={lang} />
    }
    return (
        <WC telcoName={telcoName} countryCode={countryCode} lang={lang} />
      );
  }
  WithTelco.displayName = 'test'
  return WithTelco;
}

export default withTelcoHOC;