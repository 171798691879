import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Input from '../../../components/Input';
import Loader from '../../../components/Loader';
import PrivacyText from '../../../components/PrivacyText';
import { extractUrl, constructUtmParams, gaDataLayer, constructGAParams, freeTrialCyclePeriod } from '../../../utils/common';
import { cEventOnSubscribeNowAuthJourney, cEventOnTelcoPageView, cEventOnUserLogin} from "../../../utils/clevertap";
import { getProductsQueryParams, sendOtp } from '../../../api/packs';
import { sendEvent } from '../../../api/events';
import {
  LANDED,
  CLICKED,
  SENDOTP_SUCCESS,
  SENDOTP_ERROR,
  CUSTOMER_EXIST,
  MOBILE_ERROR,
  SignUpButtonClicks,
  SignUpButtonFails,
  TelcoJournyStart,
} from '../../../constants/events';
import Message from '../../../components/Message';
import { SUCCESS, CUSTOMER_ALREADY_EXIST, INVALID_MOBILE_NUMBER ,EXCEPTION} from '../../../constants/sdpCodes';
import { ZAIN_KW_PACKAGES, ZAIN_KW_PACKAGES_PNC } from '../../../constants/packages';
import { kwZainURL } from '../../../constants/urls';
import Cookies from "js-cookie";



const mobileCode = '965';
const page = 'KW-ZAIN-Pack-Selection';
const telcoParams = {telcoName: 'zain', country: 'kw'};
const defaultPack = '350701';

const qsParams = qs.parse(location.search, { ignoreQueryPrefix: true });
const msisdn = qsParams.msisdn || '';
const mdnType = qsParams.mdntype || null;
let isHE = msisdn ? 'he-voice' : 'nonhe-voice';
const useStyles = makeStyles(() => ({
  incorrectMsg: {
    fontSize: 10,
    letterSpacing: '0.25px',
    color: '#ff0000',
    fontWeight: 600,
    marginTop: 5,
    marginBottom: 5
  },
}));

const SelectPackage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { telcoName, countryCode } = extractUrl(location.pathname);
  const [loader, setLoader] = useState(false);
  const [loaderText, setLoaderText] = useState('');
  const [zainPackage, setPackage] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const qsParamsWindow = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const ipAddress = useSelector((state) => state?.app?.clientIp);

  let navigate = useNavigate();

  const tid = qsParams.tid;
  const locale = qsParamsWindow?.locale ?? 'en-US';

  const kwZainUrls = kwZainURL(locale);
  const [inputVal, setInputVal] = useState('');
  const [dataVal, setDataVal] = useState('');
  const [isValidate, setIsValidate] = useState(false);
  const [isData, setIsData] = useState(false);
  const restrictHe = false;

  useEffect(() => {
      window.location.href = locale === "ar-AE" ? "https://osnplus.com/ar-kw/operators/zainkw" : "https://osnplus.com/en-kw/operators/zainkw";
      heRedirect();
      cEventOnTelcoPageView(countryCode, telcoName, window.location.href, "Telco Landing Page");
  }, [restrictHe]);

  const heRedirect = () => {
    //const stid = localStorage.getItem('stid');
    const stid = Cookies.get('stid');
    console.log('stid', stid);
    if (tid && stid == tid) {
      //localStorage.removeItem('stid');
      Cookies.remove('stid');
      const qParams = location.search;
      getProducts(qParams);
      removeOrAppendQueryParams();
      return;
    } else {
      const dStr = Date.now() + '' + Math.floor((Math.random() * 100) + 1);
      if (!stid) {
        //localStorage.setItem('stid', dStr);
        Cookies.set('stid', dStr);
        window.localStorage.setItem('utm', constructGAParams(qsParams));
        window.location.href = `${process.env.QUERY_MSN_ZAIN_KW}&tid=${dStr}`;
      } else {
        console.log('products without redirect');
        getProducts(); // for testing
      }
    }
  }

  const getProducts = qParams => {
    setLoader(true);
    setLoaderText(t('OTT_TLP_getProduct_Loader_Msg'));
    getProductsQueryParams(telcoName, countryCode, msisdn, qParams).then(data => {
      //localStorage.removeItem('stid');
      Cookies.remove('stid');
      const { customerType } = data;
      console.log('mdn type', qParams?.mdntype);
      if ( customerType === 'MBB' || mdnType === 'Data') {
        setIsData(true);
        isHE = msisdn ? 'he-mbb' : 'nonhe-mbb';
        setDataVal(msisdn.slice(3));
        data?.customerStatus !== null ?  setIsValidate(true) : setIsValidate(false);
      } else {
        if(msisdn) {
          isHE = 'he-voice';
          setInputVal(msisdn.slice(3));
          setIsValidate(true);
        }
      }
      if (data.responseCode === EXCEPTION) {
        navigate(kwZainUrls.error, { state:
            {
              prevPath: location.pathname,
              isHE,
              type: getType(zainPackage?.customerType)
            }
        });
        sendEvent(page, 'LandedError', '', '', 'error');
        setLoader(false);
      } else {
        setPackage(data);
        sendEvent(page, LANDED);
        sendEvent(page, msisdn ? 'HELANDED': 'NONHELANDED');
        setLoader(false);
      }
      const offer = data?.productDetails[0];
      gaDataLayer({ ...telcoParams, connection: isHE ,
        type:offer?.subscriptionTypeId === defaultPack ? 'dcb' : 'bundle',
        offer,
        customerStatus: data?.customerStatus,
        isFreeTrial : offer?.freeTrialCyclePeriod ? true : false,
        trialPeriod : freeTrialCyclePeriod(
          offer?.freeTrialCyclePeriod,
          offer?.freeTrialCycleNumber
        ),
        eventParams: TelcoJournyStart
      });
    }).catch(err => {
      console.log(err);
      navigate(kwZainUrls.error, { state:
        {
          prevPath: location.pathname,
          isHE,
          type: getType(zainPackage?.customerType)
        }
      });
      setLoader(false);
    });
  }


  const removeOrAppendQueryParams = () => {
    const utmP = window.localStorage.getItem('utm');
    const utm = qs.stringify(JSON.parse(utmP));
    const urlUtm = utm ? `&${utm}` : '';
    if (history.pushState) {
      localStorage.removeItem('utm');
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?tid=${tid}&locale=ar-AE${urlUtm}`;
      window.history.pushState({path:newurl},'',newurl);
    }
  }


  const onHandleClick = () => {
    sendEvent(page, CLICKED, `Submit button after pack selection ${inputVal}`);
    handleSendOtp(inputVal, dataVal);
  }

  const onHandleChange = e => {
    const targetVal = e.target.value;
    if (targetVal[0] === "0") {
      return false;
    }
    if ((validator.isNumeric(targetVal) || validator.isEmpty(targetVal)) && validator.isLength(targetVal, { max: 8 })) {
      setInputVal(e.target.value);
      validator.isLength(e.target.value, { min: 8 }) ? setIsValidate(true) : setIsValidate(false);
    }
  }

  const onHandleDataChange = e => {
    const targetVal = e.target.value;
    if (targetVal === "0") {
      return false;
    }
    if ((validator.isNumeric(targetVal) || validator.isEmpty(targetVal)) && validator.isLength(targetVal, { max: 9 })) {
      setDataVal(targetVal);
    }
  }

  const getType = () => {
   return  zainPackage?.productDetails[0]?.subscriptionTypeId !== defaultPack ? 'bundle' : 'dcb';
  }

  const handleSendOtp = async (mobileNumber, dataNumber) => {
    const mobileNumberWithCode = `${mobileCode}${mobileNumber}`;
    const number = zainPackage?.customerStatus ? null : mobileNumberWithCode;
    const filterOffer = zainPackage.productDetails;
    const offer = filterOffer[0] ? filterOffer[0] : null;
    const subscriptionPlan = offer.offerName;
    cEventOnUserLogin('', '+' + mobileNumberWithCode);
    cEventOnSubscribeNowAuthJourney(telcoName, countryCode, subscriptionPlan, offer.productType, offer.price, offer.currency);
    if(zainPackage?.customerStatus === 'ACTIVE') {
      navigate(kwZainUrls.subError, { state: {
        prevPath: location.pathname,
        isHE,
        type: getType(zainPackage?.customerType),
        offer: offer,
      }});
      return;
    }
    const params = {
      ...constructUtmParams(qsParams),
      customerId: mobileNumberWithCode,
      subscriptionTypeId: offer?.subscriptionTypeId,
      offerCode: offer?.offerCode,
      telcoName,
      countryName: countryCode,
      tid,
      ipAddress,
    };

    setLoader(true);
    if(isData) {
      navigate(kwZainUrls.otp, {
        state: {
          mobileNumber: number,
          offer,
          data: isData,
          dataNumber: `${mobileCode}${dataNumber}`,
          tid,
          isHE,
          prevPath: `${location.pathname}/pack`,
          customerStatus: zainPackage?.customerStatus,
        }
      });
    } else {
      setLoaderText(t('OTT_TLP_sendotp_Loader_Msg'));
      sendOtp(params).then(data => {
        if (data.responseCode === SUCCESS) {
          sendEvent(page, SENDOTP_SUCCESS, `OTP send to this number ${mobileNumberWithCode}`);
          gaDataLayer({
            ...telcoParams,
            eventParams: SignUpButtonClicks,
            offer: zainPackage?.productDetails[0],
            type: getType(zainPackage?.customerType),
            connection: isHE,
            customerStatus: zainPackage?.customerStatus,
          });
          navigate(kwZainUrls.otp, {
            state: {
              purchaseToken: data.purchaseId,
              mobileNumber: mobileNumberWithCode,
              dataNumber: true,
              offer,
              data: false,
              tid,
              isHE,
              prevPath: `${location.pathname}/pack`,
              customerStatus: zainPackage?.customerStatus,
            }
          });
        } else if (data.responseCode === CUSTOMER_ALREADY_EXIST) {
          sendEvent(page, CUSTOMER_EXIST, `Customer Already Exisit ${mobileNumber}`);
          navigate(kwZainUrls.subError, { state: {
            prevPath: location.pathname,
            isHE,
            type: getType(zainPackage?.customerType)
          }});
        } else if (data.responseCode === INVALID_MOBILE_NUMBER) {
          sendEvent(page, MOBILE_ERROR, `Invalid mobile number ${mobileNumber}`);
          setErrorMsg(true);
          setLoader(false);
          gaDataLayer({
            ...telcoParams,
            eventParams: SignUpButtonFails,
            offer: zainPackage?.productDetails[0],
            type: getType(zainPackage?.customerType),
            customerStatus: zainPackage?.customerStatus,
            errorParams: {
              code: data.responseCode,
              reason: 'Invalid mobile number'
            },
            connection: isHE
          });
        }else {
          gaDataLayer({
            ...telcoParams,
            eventParams: SignUpButtonFails,
            offer: zainPackage?.productDetails[0],
            type: getType(zainPackage?.customerType),
            customerStatus: zainPackage?.customerStatus,
            errorParams: {
              code: data.responseCode,
              reason: 'Something went wrong'
            },
            connection: isHE
          });
          sendEvent(page, SENDOTP_ERROR, data.responseCode);
          navigate(kwZainUrls.error, { state:
            {
              prevPath: location.pathname,
              isHE,
              type: getType(zainPackage?.customerType)
            }
          });
        }
      }).catch(err => {
        console.log(err);
        navigate(kwZainUrls.error, {state: {prevPath: location.pathname, isHE, type: getType(zainPackage?.customerType)}});
      });
    }

  }

  if (loader) {
    return (
      <Loader loaderText={loaderText} />
    );
  }



  if (zainPackage?.productDetails?.length === 0) {
    sendEvent(page, 'Landed', 'No Package Available', '');
    return (
      <Box className="wrapper">
        <Message msg="No Package Available" />
      </Box>
    );
  }
  const checkEditable = () =>{
    if(isData){
      return false;
    }
    else if(msisdn){
      return true;
    }
    return false;
  }

  return (
    <Box className="wrapper">
      <Helmet>
        <title>Stream Movies & Shows on OSN+ with Zain Kuwait | OSN+ Partners</title>
        <meta name="description" content="Enjoy 1000s of movies & shows online with OSN+ services if you are an Zain user in Kuwait. Start your free trial now by verifying your number here!" />
      </Helmet>
      {zainPackage?.productDetails?.length > 0 && (
        <>
          <Typography variant="h1">{t(ZAIN_KW_PACKAGES[zainPackage?.productDetails[0]?.subscriptionTypeId || '350701'])}</Typography>
          <Typography variant="body2" align="left" className="mT4">{t('OTT_TLP_selectPackageKwZain_selectMobile_Text')}</Typography>
          {isData && (
            <>
              <Input
                countryCode="+965"
                disabledInput={msisdn && true}
                placeHolder="XXXXXXXXX"
                value={dataVal}
                onChange={onHandleDataChange}
                id="0"
              />
              {(!zainPackage?.customerStatus || !isData) && ( <Typography variant="body2" align="center" className="mT4">{t('OTT_TLP_selectPackageKwZain_selectMobileZain_Text')}</Typography>)}
            </>
          )}
          {(!zainPackage?.customerStatus || !isData) && (

            <Input
              countryCode="+965"
              placeHolder="XXXXXXXXX"
              onChange={onHandleChange}
              disabledInput={checkEditable()}
              value={inputVal}
              id="1"
            />

          )}
          {errorMsg && <Typography className={classes.incorrectMsg}>{t('OTT_TLP_selectPackage_incorrectMsg_Text')}</Typography>}
          {!isData && <Typography variant="subtitle2" align="left">{t('OTT_TLP_selectPackage_zainkw_Otp_Text')}</Typography>}
          <Grid className="mT6">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              fullWidth
              onClick={onHandleClick}
              disabled={!isValidate}
            >
              <Typography variant="button">{t('OTT_TLP_selectPackage_Btn_Text')}</Typography>
            </Button>
          </Grid>
          <PrivacyText text={ZAIN_KW_PACKAGES_PNC[zainPackage?.productDetails[0]?.subscriptionTypeId || '350701']} />
        </>
      )}
    </Box>
  );
}



export default SelectPackage;