import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  ul: {
    color: "#d0d0d0",
    fontSize: 12,
    paddingLeft: 15,
    textAlign: "left",
    fontFamily: (props) =>
      props.lng === "ar" ? "FrutigerLTArabicLight" : "FaricyNewRegular",
    "& li": {
      marginBottom: 10,
    },
    "& span": {
      color: "#ff00d3",
    },
    "& a": {
      color: "#ff00d3",
      cursor: "pointer",
    },
  },
}));

const PrivacyText = ({ text, thirtyDaysFromToday }) => {
  const lng = useSelector((state) => state?.app?.lng);
  const classes = useStyles({ lng });
  const { t } = useTranslation();

  return (
    <Box mt={4} id="privacy-txt">
      <ul
        className={classes.ul}
        dangerouslySetInnerHTML={{
          __html: t(text, { thirtyDaysFromToday: thirtyDaysFromToday }),
        }}
      ></ul>
    </Box>
  );
};

PrivacyText.propTypes = {
  text: PropTypes.string,
  thirtyDaysFromToday: PropTypes.string,
};

export default PrivacyText;
