import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
   width: 335,
   height: 72,
  },
  msg: {
    alignSelf: 'center'
  }
}));

const Message = ({ msg }) => {
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        justify="center"
        mt={3}
        css={{ justifyContent: 'center' }}
        className={classes.wrapper}>
        <Typography  variant="body2" align="center" className={classes.msg}>{msg}</Typography>
      </Box>
    </>
  );
}

Message.propTypes = {
  msg: PropTypes.string,
}

export default Message;