import React from 'react';
import Box from "@material-ui/core/Box";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import './loader.css';
import Spinner from './progress-indicator.svg';


const Loader = ({loaderText}) => {
    return (
        <div className="loader-background">
            <div className="loader-wrapper">
                <img src={Spinner} className="rotate" alt="loader"/>
            </div>
            <Box className="text-wrapper">
                <Typography variant="h2">{loaderText}</Typography>
            </Box>
        </div>
    );
}

Loader.propTypes = {
  loaderText: PropTypes.string,
}

export default Loader;