import Cookies from "js-cookie";
import sha256 from "crypto-js/sha256";

export const extractUrl = (location) => {
  const arr = location.split("/").filter((e) => e);
  return { telcoName: arr[1], countryCode: arr[0] };
};

export const constructUtmParams = (q) => ({
  agency: "OSN",
  conversionId: q.clickid || "",
  medium: q.utm_medium || "",
  name: q.utm_campaign || "",
  source: q.utm_source || "",
});

export const readQueryParams = (key) => {
  const params = new URLSearchParams(window.location.search).get(key);
  if (params) return params;
  return null;
};

export const readCookies = (type) => {
  let value = null;
  if (type === "accessToken") {
    value = Cookies.get("accessToken") ?? process.env.ACCESS_TOKEN;
  } else if (type === "profileId") {
    value = readQueryParams(type) ?? process.env.PROFILE_ID;
  }
  return value;
};


export const getCustomerStatus = (status, offer) => {
  if (status === "undefined") {
    return null;
  } else {
    return status === null &&
      offer &&
      offer?.offerName?.toLowerCase() !== "eezee"
      ?  offer?.freeTrialCyclePeriod === null ? false : true
      : false;
  }
};

export const gaDataLayer = ({
  eventParams = {},
  offer = {},
  lng = readQueryParams("locale") === "ar-AE" ? "ar" : "en",
  errorParams = {},
  telcoName,
  country,
  type = "dcb",
  connection = "nonhe",
  isFreeTrial = undefined,
  trialPeriod = null,
  customerStatus = "undefined",
  isZainkw = country === "kw" && telcoName === "zain" ? true : false,
  user = {},
}) => {
  const localeValue = readQueryParams("locale");
  ("ma"!==country||"fr-MA"!==localeValue)&&("ma"!==country||localeValue)||(lng="fr");
  if (typeof window.dataLayer != "undefined") {
    console.log(
      "_details_",
      offer,
      isFreeTrial,
      "__VErify",
      getCustomerStatus(customerStatus, offer),
      trialPeriod
    );
    window.dataLayer.push({
      event: "TelcoJourney",
      eventCategory: eventParams.eventCategory || null,
      eventAction: eventParams.eventAction || null,
      OperatorName: telcoName,
      Country: country,
      PackageName: offer.offerName?.toLowerCase(),
      Language: lng,
      PackagePrice: offer.price,
      PaymentOption: "telco",
      Type: type?.toLowerCase(),
      Connection: connection,
      ErrorCode: errorParams.code || null,
      FailureReason: errorParams.reason || null,
      FreeTrial:
        isFreeTrial !== undefined
          ? isFreeTrial
          : getCustomerStatus(customerStatus, offer),
      FreeTrialPeriod:
        customerStatus === null
          ? isZainkw
            ? getValue(offer)
            : eventParams.freeTrialCycleNumber || trialPeriod
          : trialPeriod,
      UserID: user?.osnGatewayCustomerId || null,
      SubscriptionGroupID: user?.subscriptionGroupId || null,
    });
  }
};

export const getValue = (offer) => {
  let val = null;
  switch (offer?.offerName?.toLowerCase()) {
    case "monthly":
      val = 7;
      break;
    case "2months":
      val = 60;
      break;
    case "6months":
      val = 180;
      break;
    case "yearly":
      val = 360;
      break;
    case "eezee":
      val = null;
      break;
  }
  return val;
};

export const freeTrialCyclePeriodforGA = (period, number) => {
  const trialPeriod =
    number == 7 ? number : number + period?.charAt(0).toLowerCase();
  return trialPeriod;
};

export const freeTrialCyclePeriod = (period, number) => {
  if (period === "DAY") {
    return number * 1;
  }
  if (period === "WEEK") {
    return number * 7;
  }
  if (period === "MONTH") {
    return number * 30;
  }
  if (period === null) {
    return null;
  }
  return 0;
};

export const constructGAParams = (params) => {
  const {
    utm_source,
    utm_medium,
    utm_campaign,
    click_id,
    utm_term,
    utm_content,
    clickid,
    data2,
    fbclid,
    dclid,
    gclid,
  } = params;
  const paramsObj = {
    utm_source,
    utm_medium,
    utm_campaign,
    click_id,
    utm_term,
    utm_content,
    clickid,
    data2,
    fbclid,
    dclid,
    gclid,
  };
  return JSON.stringify(paramsObj);
};

export const encryption = (url, method, TIMESTAMP) => {
  const APPN_ID = process.env.APPN_ID;
  const SOURCE_ID = process.env.SOURCE_ID; 
  const HASH_SALT = process.env.HASH_SALT;
  // const TIMESTAMP = moment().unix();
  const preHashString = "".concat(APPN_ID, SOURCE_ID, HASH_SALT, method, url, TIMESTAMP).toLowerCase();
  const hashString = sha256(preHashString).toString();

  const authHeader = "".concat(
    "osnauth_x_application_id=",
    APPN_ID,
    "osnauth_x_source_id=",
    SOURCE_ID,
    "osnauth_x_timestamp=",
    TIMESTAMP,
    "osnauth_x_signature=",
    hashString,
  );
  return btoa(authHeader);
};

export const getFormatedDate = (date) => {
  let mm = '' + (date.getMonth() + 1),
      dd = '' + date.getDate(),
      yyyy = date.getFullYear();

    if (mm.length < 2)
        mm = '0' + mm;
    if (dd.length < 2)
        dd = '0' + dd;
    return [yyyy, mm, dd].join('-');
};

export const setPaddingForWrapperBg = (isMobile) => {
  const imgWidth = 1536, imgHeight = 768; //Hard coded dimension of ulp banner, it will impact if dimenstion get change
  const wrapperBg = document.getElementById("wrapper_bg");
  const resizeHeight = isMobile ? ((imgHeight/imgWidth)*window.innerWidth) : '0';
  wrapperBg && (wrapperBg.style.paddingTop = resizeHeight + 'px');
}

export const getBannerImageSrc = (lng, isMobile) => {
  setPaddingForWrapperBg(isMobile)
  if(lng === 'ar') {
    return isMobile ? 
    window.innerWidth > 600 ? `${process.env.S3_ENDPOINT}/ulp/banner-version-3-ar-banner-mobile@2x.jpg` : `${process.env.S3_ENDPOINT}/ulp/banner-version-3-ar-banner-mobile@1x.jpg` :
    window.innerWidth > 2000 ? `${process.env.S3_ENDPOINT}/ulp/Banner_Ar.jpg` : `${process.env.S3_ENDPOINT}/ulp/Banner_Sm_Ar.jpg`;

  } else {
    return isMobile ? 
    window.innerWidth > 600 ? `${process.env.S3_ENDPOINT}/ulp/banner-version-3-en-banner-mobile@2x.jpg` : `${process.env.S3_ENDPOINT}/ulp/banner-version-3-en-banner-mobile@1x.jpg` :
    window.innerWidth > 2000 ? `${process.env.S3_ENDPOINT}/ulp/Banner_En.jpg` : `${process.env.S3_ENDPOINT}/ulp/Banner_Sm_En.jpg`;
  }
}

