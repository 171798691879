import React from 'react';
import { Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Alert } from "@material-ui/lab";

const SnackMessage = ({open, msg, level, close}) => {
  return (
    <Snackbar
    autoHideDuration={3000}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    open={open}
    key="topcenter"
    onClose={close}
  >
    <Alert elevation={4} severity={level}>
      {msg}
  </Alert>
  </Snackbar>
  );
}

SnackMessage.propTypes = {
  open: PropTypes.bool,
  msg: PropTypes.string,
  level: PropTypes.string,
  close: PropTypes.func,
}

export default SnackMessage;