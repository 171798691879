import React from "react";
import { render } from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./index.css";
import i18n from "./i18";
import { store } from "./redux/configureStore";
import { I18nextProvider } from "react-i18next";
import { Provider as ReduxProvider } from "react-redux";
import clevertap from 'clevertap-web-sdk';
clevertap.privacy.push({optOut: false}) // Set the flag to true, if the user of the device opts out of sharing their data
clevertap.privacy.push({useIP: false})  // Set the flag to true, if the user agrees to share their IP data
clevertap.init(process.env.CLEVERTAP_ACCOUNT_ID) // Replace with values applicable to you. Refer below

window.clevertap.spa = true;
if(window.clevertap.setLogLevel) {
  window.clevertap.setLogLevel(3);
}

render(
  <ReduxProvider store={store}>
    <I18nextProvider i18={i18n}>
      <React.Suspense fallback={null}>
        <App />
      </React.Suspense>
    </I18nextProvider>
  </ReduxProvider>,
  document.getElementById("app")
);
