import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  campaignText:{
    display:"flex",
    justifyContent: "center",
  },
  successWrapper: {
    width: 375,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
     width: "auto",
     margin: "0 20px",
    },
    [theme.breakpoints.up("sm")]: {
      width: 375,
      marginLeft: "auto",
      marginRight: "auto",
     },
  },
  wrapHeading : {
    '& .MuiTypography-h2': {
      fontFamily: props => props.lng === 'ar' && 'FrutigerLTArabicLight',
      lineHeight: props => props.lng === 'ar' && 1.6
    }
  },
  heading: {
    fontFamily: props => props.lng === 'ar' ? 'FrutigerLTArabicBold' : 'FaricyNewBold',
    fontSize: 20,
    fontWeight: "bold",
    letterSpacing: "0.15px",
    textAlign: "center",
    color: "#FF00D3",
  },
  label: {
    fontFamily: props => props.lng === 'ar' ? 'FrutigerLTArabicBold' : 'FaricyNewBold',
    fontWeight: "bold",
    fontSize: 16,
    height: 44,
    borderRadius: 8,
    display: 'flex',
    with: '100%',
    textDecoration: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    background: '#B40099',
    '&:hover': {
      color: '#fff',
      textDecoration: 'none',
    }
  },
  subTitle: {
    marginTop: 20,
    fontSize: 14,
    fontFamily: props => props.lng === 'ar' ? 'FrutigerLTArabicBold' : 'FaricyNewBold',
  },
  label1: {
    ffontFamily: props => props.lng === 'ar' ? 'FrutigerLTArabicBold' : 'FaricyNewBold',
    fontWeight: "bold",
    fontSize: 16,
    height: 44,
    borderRadius: 8,
    '&:hover': {
      color: '#fff',
      textDecoration: 'none',
    }
  },
  appHeading: {
    fontFamily: props => props.lng === 'ar' ? 'FrutigerLTArabicRoman' : 'FaricyNewMedium',
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "1px",
    textAlign: "center",
  },
  storeIcons: {
    display: "flex",
    justifyContent: "center",
    "& img:first-child": {
      paddingRight: "25px",
    }
  },
  viaText: {
    color: "#fff",
    fontSize: 14,
  },
}));