export const kwZainURL = (locale) => ({
  otp: `/kw/zain/otp-verification?locale=${locale}`,
  error: `/kw/zain/error?locale=${locale}`,
  subError: `/kw/zain/subscription-error?locale=${locale}`,
  timeout: `/kw/zain/timeout?locale=${locale}`,
  insufficientBalance: `/kw/zain/insufficientBalance?locale=${locale}`,
  welcome: `/kw/zain/welcome?locale=${locale}`,
  pack: "/kw/zain",
});

export const saStc = {};

export const kwStcURL = (locale) => ({
  otp: `/kw/stc/otp-verification?locale=${locale}`,
  error: `/kw/stc/error?locale=${locale}`,
  subError: `/kw/stc/subscription-error?locale=${locale}`,
  welcome: `/kw/stc/welcome?locale=${locale}`,
  pack: `/kw/stc?locale=${locale}`,
  timeout: `/kw/stc/timeout?locale=${locale}`,
  insufficientBalance: `/kw/stc/insufficientBalance?locale=${locale}`,
  invalidMsisdn: `/kw/stc/invalid-msisdn?locale=${locale}`,
});

export const iqZainURL = (locale) => ({
  otp: `/iq/zain/otp-verification?locale=${locale}`,
  error: `/iq/zain/error?locale=${locale}`,
  subError: `/iq/zain/subscription-error?locale=${locale}`,
  welcome: `/iq/zain/welcome?locale=${locale}`,
  pack: `/iq/zain?locale=${locale}`,
  timeout: `/iq/zain/timeout?locale=${locale}`,
  insufficientBalance: `/iq/zain/insufficientBalance?locale=${locale}`,
});

export const ksaZainURL = (locale) => ({
  otp: `/sa/zain/otp-verification?locale=${locale}`,
  error: `/sa/zain/error?locale=${locale}`,
  subError: `/sa/zain/subscription-error?locale=${locale}`,
  welcome: `/sa/zain/welcome?locale=${locale}`,
  pack: `/sa/zain?locale=${locale}`,
  timeout: `/sa/zain/timeout?locale=${locale}`,
  insufficientBalance: `/sa/zain/insufficientBalance?locale=${locale}`,
  invalidMsisdn: `/kw/stc/invalid-msisdn?locale=${locale}`,
});

export const kwOoredooURL = (locale) => ({
  otp: `/kw/ooredoo/otp-verification?locale=${locale}`,
  error: `/kw/ooredoo/error?locale=${locale}`,
  subError: `/kw/ooredoo/subscription-error?locale=${locale}`,
  welcome: `/kw/ooredoo/welcome?locale=${locale}`,
  pack: `/kw/ooredoo?locale=${locale}`,
  timeout: `/kw/ooredoo/timeout?locale=${locale}`,
  insufficientBalance: `/kw/ooredoo/insufficientBalance?locale=${locale}`,
});

export const ksaStcURL = (locale) => ({
  otp: `/sa/stc/otp-verification?locale=${locale}`,
  error: `/sa/stc/error?locale=${locale}`,
  subError: `/sa/stc/subscription-error?locale=${locale}`,
  welcome: `/sa/stc/welcome?locale=${locale}`,
  pack: `/sa/stc?locale=${locale}`,
  timeout: `/sa/stc/timeout?locale=${locale}`,
  insufficientBalance: `/sa/stc/insufficientBalance?locale=${locale}`,
});

export const ksaLebaraURL = (locale) => ({
  otp: `/sa/lebara/otp-verification?locale=${locale}`,
  error: `/sa/lebara/error?locale=${locale}`,
  subError: `/sa/lebara/subscription-error?locale=${locale}`,
  welcome: `/sa/lebara/welcome?locale=${locale}`,
  pack: `/sa/lebara?locale=${locale}`,
  timeout: `/sa/lebara/timeout?locale=${locale}`,
  insufficientBalance: `/sa/lebara/insufficientBalance?locale=${locale}`,
  userNotEligible: `/sa/lebara/userNotEligible?locale=${locale}`,
});

export const bhZainURL = (locale) => ({
  otp: `/bh/zain/otp-verification?locale=${locale}`,
  error: `/bh/zain/error?locale=${locale}`,
  subError: `/bh/zain/subscription-error?locale=${locale}`,
  welcome: `/bh/zain/welcome?locale=${locale}`,
  pack: `/bh/zain?locale=${locale}`,
  timeout: `/bh/zain/timeout?locale=${locale}`,
  insufficientBalance: `/bh/zain/insufficientBalance?locale=${locale}`,
});
export const qaOoredooURL = (locale) => ({
  otp: `/qa/ooredoo/otp-verification?locale=${locale}`,
  error: `/qa/ooredoo/error?locale=${locale}`,
  subError: `/qa/ooredoo/subscription-error?locale=${locale}`,
  welcome: `/qa/ooredoo/welcome?locale=${locale}`,
  pack: `/qa/ooredoo?locale=${locale}`,
  timeout: `/qa/ooredoo/timeout?locale=${locale}`,
  insufficientBalance: `/qa/ooredoo/insufficientBalance?locale=${locale}`,
});
export const maMarocURL = (locale) => ({
  otp: `/ma/iam/otp-verification?locale=${locale}`,
  error: `/ma/iam/error?locale=${locale}`,
  subError: `/ma/iam/subscription-error?locale=${locale}`,
  welcome: `/ma/iam/welcome?locale=${locale}`,
  pack: `/ma/iam?locale=${locale}`,
  timeout: `/ma/iam/timeout?locale=${locale}`,
  insufficientBalance: `/ma/iam/insufficientBalance?locale=${locale}`,
});
export const joOrangeURL = (locale) => ({
  otp: `/jo/orange/otp-verification?locale=${locale}`,
  error: `/jo/orange/error?locale=${locale}`,
  subError: `/jo/orange/subscription-error?locale=${locale}`,
  welcome: `/jo/orange/welcome?locale=${locale}`,
  pack: `/jo/orange?locale=${locale}`,
  timeout: `/jo/orange/timeout?locale=${locale}`,
  insufficientBalance: `/jo/orange/insufficientBalance?locale=${locale}`,
});
export const ksaMobilyURL = (locale) => ({
  otp: `/sa/mobily/otp-verification?locale=${locale}`,
  error: `/sa/mobily/error?locale=${locale}`,
  subError: `/sa/mobily/subscription-error?locale=${locale}`,
  welcome: `/sa/mobily/welcome?locale=${locale}`,
  pack: `/sa/mobily?locale=${locale}`,
  timeout: `/sa/mobily/timeout?locale=${locale}`,
  insufficientBalance: `/sa/mobily/insufficientBalance?locale=${locale}`,
});
export const maOrangeURL = (locale) => ({
  otp: `/ma/orange/otp-verification?locale=${locale}`,
  error: `/ma/orange/error?locale=${locale}`,
  subError: `/ma/orange/subscription-error?locale=${locale}`,
  welcome: `/ma/orange/welcome?locale=${locale}`,
  pack: `/ma/orange?locale=${locale}`,
  timeout: `/ma/orange/timeout?locale=${locale}`,
  insufficientBalance: `/ma/orange/insufficientBalance?locale=${locale}`,
});
export const ksaRedbullmobileURL = (locale) => ({
  otp: `/sa/redbullmobile/otp-verification?locale=${locale}`,
  error: `/sa/redbullmobile/error?locale=${locale}`,
  subError: `/sa/redbullmobile/subscription-error?locale=${locale}`,
  welcome: `/sa/redbullmobile/welcome?locale=${locale}`,
  pack: `/sa/redbullmobile?locale=${locale}`,
  timeout: `/sa/redbullmobile/timeout?locale=${locale}`,
  insufficientBalance: `/sa/redbullmobile/insufficientBalance?locale=${locale}`,
});
export const tnOoredooURL = (locale) => ({
  otp: `/tn/ooredoo/otp-verification?locale=${locale}`,
  error: `/tn/ooredoo/error?locale=${locale}`,
  subError: `/tn/ooredoo/subscription-error?locale=${locale}`,
  welcome: `/tn/ooredoo/welcome?locale=${locale}`,
  pack: `/tn/ooredoo?locale=${locale}`,
  timeout: `/tn/ooredoo/timeout?locale=${locale}`,
  insufficientBalance: `/tn/ooredoo/insufficientBalance?locale=${locale}`,
});
