import React from "react";
import { Outlet } from "react-router-dom";
import Header from '../components/Header';

const Settings = () => {
  return (
    <>
      <Header withBackBtn={false} position="relative" logoEndpoint="common/logo-osn.svg"/>
      <Outlet />
    </>
  );
};

export default Settings;
