import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BusinessRules from '../constants/BusinessRules';
import MyContext from './TelcoContext';

export default class MyProvider extends Component {
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    return (
      <MyContext.Provider
        value={{
          zainsa: BusinessRules['zain-sa'],
          stcksa: BusinessRules['zain-stc'],
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}