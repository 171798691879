import React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from "@material-ui/core/styles";
import Header from '../components/Header/UlpHeader';
import Footer from '../components/Footer';
import { extractUrl, getBannerImageSrc } from '../utils/common';


const Settings = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { lng } = useSelector((state) => {
    return {
      lng: state?.app?.lng,
    };
  });
  return (
    <>
      <Header withBackBtn={false} position="relative" logoEndpoint="common/logo-osn.svg"/>
      <div className="wrapper-bg" id="wrapper_bg" style={{backgroundImage: `url(${getBannerImageSrc(lng, isMobile)})`}}>
        <div className="wrapper-out">
          <Outlet />
        </div>
      </div>
      <Footer isRevamp={true} />
    </>
  );
};

export default Settings;
