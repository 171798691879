import React from "react";
import PropTypes, { string } from 'prop-types';
import { Box, Button } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { makeStyles } from "@material-ui/core/styles";
import Moment from 'moment';
import Pagination from "../../components/Pagination";


const useStyles = makeStyles(() => ({
  tableHead: {
    fontWeight :"bold",
    backgroundColor: "#deeaff",
  },
  linkBtn: {
    '& .MuiButton-text': {
      color: '#fff',
      textDecoration: "underline",
      textTransform: "capitalize",
    }
  },
}));

const SearchCustomerDetails = ({...props}) => {
  const {userHistory, paymentTransactions, refundDetails, telcoName, openRefundBoxHandler, userRole} = props;
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [pagePayment, setPagePayment] = React.useState(0);
  const [rowsPerPagePayment, setRowsPerPagePayment] = React.useState(5);

  const handleChangePagePayment = (event, newPage) => {
    setPagePayment(newPage);
  };

  const handleChangeRowsPerPagePayment = (event) => {
    setRowsPerPagePayment(parseInt(event.target.value, 10));
    setPagePayment(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
           {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const handleTabChange = (event, newValue) =>{
    setTabValue(newValue);
  }

  const getRefundStatusComponent = (paymentId, amount) => {
    const foundSuccessRefund = refundDetails.find(refundDetail => refundDetail.paymentId === paymentId);
    return (
      <div> 
        <Button onClick={() => openRefundBoxHandler(paymentId, amount, foundSuccessRefund)}>{foundSuccessRefund ? 'Refund Issued' : 'Refund'}</Button>
      </div>
    )
  }

  const paymentBodyComponent = () => {
    let paymentBody;
    if(paymentTransactions.length>0)
    {
      paymentBody=    <TableBody>
          {(rowsPerPagePayment > 0
              ? paymentTransactions.slice(pagePayment * rowsPerPagePayment, pagePayment * rowsPerPagePayment + rowsPerPagePayment)
              : paymentTransactions
            ).map((dr,i) => (
          <TableRow
            key={i}
          >        
            <TableCell align="center">{dr.telco}</TableCell>
            <TableCell align="center">{dr.paymentType}</TableCell>
            <TableCell align="center">{dr.fromDate && Moment(dr.fromDate,'YYYY-MM-DD[T]HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}</TableCell>
            <TableCell align="center">{dr.toDate && Moment(dr.toDate,'YYYY-MM-DD[T]HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}</TableCell>
            <TableCell align="center">{dr.receivedDate && Moment(dr.receivedDate,'YYYY-MM-DD[T]HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}</TableCell>
            <TableCell align="center">{dr.amount}</TableCell>     
            <TableCell align="center">{dr.currency}</TableCell> 
            <TableCell align="center" className={classes.linkBtn}>
              {dr.attemptOutcome} 
              {userRole === 'SuperAdmin' && (telcoName === 'APPLEPAY' || telcoName === 'CREDITCARD') && parseFloat(dr.amount) > 0 && (dr.attemptOutcome === "SUCCESSFUL") &&
                getRefundStatusComponent(dr.paymentId, dr.amount)
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    }
    else
    {paymentBody = <TableBody><TableRow columns={1}> <TableCell align="center">No data found</TableCell></TableRow></TableBody>}
    
    return paymentBody
  }
  const subscriptionBodyComponent = () => {
    let subscriptionBody;
    if(userHistory && userHistory.length>0)
    {
      subscriptionBody=    <TableBody>
        {(rowsPerPage > 0
              ? userHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : userHistory
            ).map((dr) => (
          <TableRow
            key={dr.sequentialId}
          >   
            <TableCell align="center">{dr.sequentialId}</TableCell>
            <TableCell align="center">{dr.contactNumber}</TableCell>
            <TableCell align="center">{dr.subscribedOffer}</TableCell>
            <TableCell align="center">{dr.subscriptionType}</TableCell>
            <TableCell align="center">{dr.subscriptionStartDate && Moment(dr.subscriptionStartDate,'YYYY-MM-DD[T]HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}</TableCell>
            <TableCell align="center">{dr.acquisitionInterface}</TableCell>
            <TableCell align="center">{dr.lastUpdatedDate && Moment(dr.lastUpdatedDate,'YYYY-MM-DD[T]HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}</TableCell>
            <TableCell align="center">{dr.subscriptionEndDate && Moment(dr.subscriptionEndDate,'YYYY-MM-DD[T]HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}</TableCell>
            <TableCell align="center">{dr.currentStatus}</TableCell>
            <TableCell align="center">{dr.subStatus}</TableCell>
          </TableRow>
        ))}
        
      </TableBody>
    }
    else
    {subscriptionBody = <TableBody><TableRow columns={1}><TableCell align="center">No data found</TableCell></TableRow></TableBody>}

    return subscriptionBody;
  }

  return (
  <Box sx={{ width: '100%', typography: 'body1', marginTop:'20px' }}>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="Subscription Details" {...a11yProps(0)} />
          <Tab label="Payment Details" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
        <TabPanel value={tabValue} index={0}>
        <div style={{ width: '100%' }}> 
          <TableContainer>
          <Table  sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell align="center">Seq. ID</TableCell>
            <TableCell align="center">Contact Number</TableCell>
            <TableCell align="center">Subscribed Offer</TableCell>
            <TableCell align="center">Subscription Type</TableCell>
            <TableCell align="center">Subscription Start Date</TableCell>
            <TableCell align="center">Aquisition Interface</TableCell>
            <TableCell align="center">Last Updated Date</TableCell>
            <TableCell align="center">Next Renewal Date</TableCell>
            <TableCell align="center">Current Status</TableCell>
            <TableCell align="center">Sub Status</TableCell>
          </TableRow>
        </TableHead>
       {subscriptionBodyComponent()}
       <TableFooter>
            <TableRow>
              <Pagination
                listLength={userHistory.length}
                rowsPerPage={rowsPerPage}
                page={page}
                selectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
    </Table>
    </TableContainer>
        </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
        <div style={{ width: '100%' }}> 
          <TableContainer>
          <Table  sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell align="center">Partner Name</TableCell>
            <TableCell align="center">Payment Type</TableCell>
            <TableCell align="center">From Date</TableCell>
            <TableCell align="center">To Date</TableCell>
            <TableCell align="center">Received Date</TableCell>
            <TableCell align="center">Charged Amount</TableCell>
            <TableCell align="center">Currency</TableCell>
            <TableCell align="center">Attempt Outcome</TableCell>
  
  
          </TableRow>
        </TableHead>
       {paymentBodyComponent()}
       <TableFooter>
            <TableRow>
              <Pagination
                listLength={paymentTransactions.length}
                rowsPerPage={rowsPerPagePayment}
                page={pagePayment}
                selectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePagePayment}
                onRowsPerPageChange={handleChangeRowsPerPagePayment}
              />
            </TableRow>
          </TableFooter>
   
    </Table>
    </TableContainer>
     
        </div>      </TabPanel>
    
  </Box>
)

}
export default SearchCustomerDetails;
