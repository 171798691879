import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  span: {

  },
  button: {
    color: '#ff00d3',
    fontSize: 14,
    background: 'transparent',
    border: 'none'
  },
  buttonD: {
    color: 'grey',
    fontSize: 14,
    background: 'transparent',
    border: 'none'
  }
}));


function Timer({ seconds, onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [counter, setCounter] = useState(seconds);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resetTimer = () => {
    setCounter(seconds);
    onClick();
  }

  return (
    <Fragment>
      {counter === 0 && (<button className={classes.button} onClick={resetTimer} data-testid="btn">{t('OTT_TLP_OTP_resendCode')}</button>)}
      {counter > 0 && (<button disabled={true} className={classes.buttonD} onClick={resetTimer} id="resend" data-testid="btn">{t('OTT_TLP_timer_text')} <span data-testid="timer">00:{counter < 10 ? `0${counter}` : counter}</span></button>)}
    </Fragment>
  );
}

Timer.propTypes = {
  seconds: PropTypes.number,
  onClick: PropTypes.func,
}

Timer.defaultProps = {
  seconds: 15,
}

export default Timer;