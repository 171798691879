import { handleResponse, handleError, ajax } from "../utils/Api";

export const verifyLogin= (username,password) =>{    
     return ajax
     .post("/customer/verifyLogin", {userName: username,password: password,},{headers:{
     "Content-Type": "application/json",
     }})
     .then(handleResponse)
     .catch(handleError);
}

export const getCustomer = (customerId, mop) => {
  return ajax
  .post("/customer/getDetails", {mobileNumber: customerId, mop},{headers:{
  "Content-Type": "application/json",
  'Authorization': localStorage.getItem('token')}})
  .then(handleResponse)
  .catch(handleError);
}

export const getEligibleDetails = (telcoName) => {
     return ajax
     .get(`/customer/getEligibleOffersOperators?countryCode=${telcoName}`, {headers:{
     "Content-Type": "application/json",
     'Authorization': localStorage.getItem('token')}})
     .then(handleResponse)
     .catch(handleError);
}

export const updateStatus = (customerData) => {
     return ajax
     .post("/customer/updateStatus", customerData, {headers:{
     "Content-Type": "application/json",
     'Authorization': localStorage.getItem('token')}})
     .then(handleResponse)
     .catch(handleError);
   }

export const submitRefund = (refundData) => {
     return ajax
          .post("/customer/submitRefund", refundData, {headers:{
          "Content-Type": "application/json",
          'Authorization': localStorage.getItem('token')}})
          .then(handleResponse)
          .catch(handleError);
}
