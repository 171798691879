import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sendEvent } from "../../api/events";
import withTelcoHOC from "../../hoc/withTelco";

const Error = ({ telcoName, countryCode, lang }) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  useEffect(() => {
    sendEvent(
      `${telcoName}-${countryCode}-User-Not-Eligible`,
      "LANDED",
      `Something went wrong ${state?.prevPath}`
    );
    let timer = setTimeout(() => triggerDataLayer(), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const triggerDataLayer = () => {
    window.dataLayer.push({
      event: "TelcoJourney",
      OperatorName: telcoName || "",
      Country: countryCode || "",
      Language: lang || "en",
      PaymentOption: "telco",
      ErrorCode: "42",
      Type: state?.type || "dcb",
      FailureReason: "User Not Eligible",
      Connection: state?.isHE,
    });
  };

  return (
    <Box className="wrapper-error">
      <Box
        display="flex"
        justify="center"
        mt={3}
        mb={4}
        css={{ justifyContent: "center" }}
      >
        <img
          src={`${process.env.S3_ENDPOINT}/common/icon-large-error.svg`}
          alt="step-2-3"
        />
      </Box>
      <Typography variant="h1">{t("OTT_TLP_error_title")}</Typography>
      <Typography className="mT4" variant="h2" align="center">
        {t("OTT_TLP_userNotEligibleError_msg")}
      </Typography>
    </Box>
  );
};

Error.propTypes = {
  telcoName: PropTypes.string,
  countryCode: PropTypes.string,
  lang: PropTypes.string,
};

export default withTelcoHOC(Error);
