export default {
  'zain-sa': {
    MBB: {
      heading: 'OTT_TLP_selectPackage_zainksa_MBBHeading',
      requiredOTP: false,
      placeHolder: '8XXXXXXXX',
      privacyText: 'OTT_TLP_selectPackage_zainksa_MBBPrivacyText',
      offerName: 'OTT_TLP_selectPackage_zainksa_voiceText',
      category: 'VOICE'
    },
    VOICE: {
      heading: 'OTT_TLP_selectPackage_zainksa_VoiceHeading',
      requiredOTP: true,
      placeHolder: '5XXXXXXXX',
      privacyText: 'OTT_TLP_selectPackage_zainksa_VoicePrivacyText',
      offerName: 'OTT_TLP_selectPackage_zainksa_mbbText',
      category: 'MBB'
    },
    FIBER: {
      heading: 'OTT_TLP_selectPackage_zainksa_FibereHeading',
      requiredOTP: false,
      placeHolder: '7XXXXXXXX',
      privacyText: 'OTT_TLP_selectPackage_zainksa_FIBERPrivacyText',
      offerName: 'OTT_TLP_selectPackage_zainksa_fiberText',
      category: 'FIBER'
    },
    countryCode: '+966',
  },
  'stc-ksa': {
    countryCode: '+966',
    placeHolder: '5XXXXXXXX',
    MONTHLY: {
      privacyText: 'OTT_TLP_selectPackage_zainstc_montlyPrivacyText'
    },
    WEEKLY: {
      privacyText: 'OTT_TLP_selectPackage_zainstc_weeklyPrivacyText'
    },
  },
};