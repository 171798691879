import React, { useState, useEffect } from 'react';
import { Button, TextField, Select, MenuItem} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  confirmDialogClass : {
    '& .MuiSvgIcon-root': {
      display: 'block',
      width: '2em',
      height: '2em',
      color: '#ff9800',
      margin: '0 auto',
    }
  },
}));

export default function ConfirmDialog(props) {
  const classes = useStyles();
  const { openConfirmDialog, hideConfirDialogBox } = props;

  return (
    <div>
      <Dialog
        open={openConfirmDialog}
        onClose={() => {hideConfirDialogBox('no')}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.confirmDialogClass}
      >
        <DialogTitle id="alert-dialog-title">
          <WarningOutlinedIcon />
          {"Are you sure you want to update the status?"}
        </DialogTitle>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => {hideConfirDialogBox('no')}}>No</Button>
          <Button variant="contained" color="secondary" onClick={() => {hideConfirDialogBox('yes')}} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
