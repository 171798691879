import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import fallBackTranslationEN from "../../assets/locales/en/translate.json";
import fallBackTranslationAR from "../../assets/locales/ar/translate.json";
import fallBackTranslationFR from "../../assets/locales/fr/translate.json";
import { getTranslations } from "./getTranlsation";



const options = {
  order: ['querystring'],
  lookupQuerystring: 'locale'
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: fallBackTranslationEN },
      ar: { translation: fallBackTranslationAR },
      fr: { translation: fallBackTranslationFR },
    },
    detection: options,
    /* default language when load the website in browser */
    // lng: "ar",
    /* When react i18next not finding any language to as default in borwser */
    // lng: "en",
    parseMissingKeyHandler: () => {
      return "";
    },
    fallbackLng: "en",
    /* debugger For Development environment */
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
i18n.languages = ["en","ar"];

getTranslations()
  .then(({ translationAR, translationEN, translationFR }) => {
    i18n.addResourceBundle("en", "translation", translationEN, false, true);
    i18n.addResourceBundle("ar", "translation", translationAR, false, true);
    i18n.addResourceBundle("fr", "translation", translationFR, false, true);
  })
  .catch((e) => console.error(e));

export default i18n;
