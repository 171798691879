import { combineReducers } from "redux";
import app from "./appReducer";
import apiState from './apiStatusReducer';

const rootReducer = combineReducers({
  app,
  apiState,
});

export default rootReducer;
