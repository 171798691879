import React, { useState, useEffect } from 'react';
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loader from "../../components/Loader";
import { getRetryEngineDetails } from '../../api/retryEngine';
import CustomDateRangeInputs from "../../components/DateRangePicker/CustomDateRangeInputs";
import { getFormatedDate } from "../../utils/common";


const useStyles = makeStyles(() => ({
  tableHead: {
    fontWeight :"bold",
    backgroundColor: "rgba(255, 255, 255, .2)",
    '& .MuiTableCell-head': {
      color: '#fff',
      padding: 10
    }
    
  },
  formLogo: {
    textAlign: "center",
    margin: "10px 10px 10px 10px"
  },
  custContainer: {
    textAlign: "center",
    margin: "10px 10px 10px 10px",
    
  },
  tableContainer: {
    overflowX: 'unset',
    '& .MuiTableCell-body' : {
      color: 'inherit',
    }
  },
  logoutHeader: {
    textAlign: "right",
  },
  topSection: {
    position: 'relative',
    marginTop: '20px',
    minHeight: '80px',
    '& .MuiIconButton-root': {
      color: '#fff',
      padding: '0 5px 0 0',
    }
  },

  selectDateRange: {
    position: 'absolute',
    top: '5px',
    right: '10px',
    width: '290px',
    padding:5,
    border: '1px solid #fff',
    textAlign: 'left',
    '& .react-datepicker__input-container input[type="text"]': {
      width: '200px',
      outline: 'none',
      border: 0,
      background: 'rgba(255, 255, 255, .2)',
      padding: '3px 5px',
      color:'#fff',
    },
    '& .react-datepicker-wrapper' : {
      width: 'auto',
      marginLeft: 10,
      float: 'right',
    },
  },
  retryInfo : {
    display: 'flex',
    alignContent: 'space-between',
    justifyContent: 'center'
  },
  retryInfoBlock: {
    border: '1px solid #fff',
    margin: '0px 10px',
    padding: '10px',
    width: '16.5%',
    backgroundColor: "rgba(255, 255, 255, .1)",
    '& h3' : {
      fontSize: '18px',
      margin: 0,
    }    
  },
  d1 : {
    display: 'block',
    marginTop: 20,
    marginBottom: 5,
    '& small' : {
      fontSize: 20,
      color: 'red'
    },
    '& small.high' : {
      color: 'green' 
    }
  },
  d2 : {
    display: 'block',
  },
  batchSummary: {
    width: '89%',
    margin: '30px auto',
    border: '1px solid #fff',
    '& h3' : {
      fontSize: '18px',
      padding: '20px 0',
      margin: 0,
      backgroundColor: '#B40099',
    }
  },
  viewDetails : {
    float: 'right',
    cursor: 'pointer',
    color: '#B40099',
    fontWeight: 'bold',
    textDecoration: 'underline',
    padding: '20px 0'
  },
  viewRecordsPopup: {
    position: 'absolute',
    display: 'table',
    bottom : 80,
    left: -52,
    width: 210,
    minHeight: 100,
    background: '#fff',
    color: '#000',
    border: '1px solid #000',
    fontSize: 12,
    'border-radius': '5px',
    '& ul': {
      listStyle: 'none',
      textAlign: 'center',
      margin: 0,
      padding: 10,
      display: 'table-cell',
      verticalAlign: 'middle'
    },
    '& .tableCell': {
      display: 'table-cell',
      padding: 10,
      verticalAlign: 'middle'
    }
  }
}));


const RecordComponent = (props) => {
  const  {viewRecordsPopup, data: {records, totalChurnedRecords, notEligibleRecords}} = props;
  const [showItem, setShowItem] = useState(false);
  
  const showItemRecords = () => {
    setShowItem(true);
  }

  return (
    <>
    <Button color="secondary"  onMouseEnter={(event) => {setShowItem(true)}} onMouseLeave={(event) => {setShowItem(false)}}>{records}</Button>
    {showItem && 
      <div className={viewRecordsPopup}>
        <ul>
          <li>{`Total Retry Count - ${totalChurnedRecords}`}</li>
          <li>{`Eligible for Payment - ${totalChurnedRecords-notEligibleRecords}`} </li>
          <li>{`Not Eligible for Payment - ${notEligibleRecords}`} </li>
        </ul>
      </div>
    }
    </>
  )
}

const StatusComponent = (props) => {
  const  {statusPopup, errmsg} = props;
  const [showItem, setShowItem] = useState(false);
  
  return (
    <>
        <Button color="secondary" onMouseEnter={(event) => {setShowItem(true)}} onMouseLeave={(event) => {setShowItem(false)}}>FAILED</Button>
        {showItem && 
          <div className={statusPopup}>
            <div className="tableCell">{errmsg}</div>
          </div>
        }
    </>
  )
}




const RetryEngineDashboard = () => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [loaderText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [dates, setDates] = useState(() => {
    if (sessionStorage.getItem("rangeDate")) {
      const getDateFromStorage = sessionStorage.getItem("rangeDate").split(',');
      sessionStorage.removeItem("rangeDate")
      return [new Date(getDateFromStorage[0]), new Date(getDateFromStorage[1])];
    }
    return [new Date(), new Date()];
  });
  const [startDate, endDate] = dates;
  const [statsData, setStatsData] = useState(null);
  const [batchSummaryData, setBatchSummaryData] = useState([]);
 
  
  const isRetryHigher = false;
  const isSucessHigher = true;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (dates[1] !== null) {
      getReDashboardData(dates[0], dates[1])
    }
  },[dates]);

  
  const getReDashboardData = (startDate,endDate) => {
    setLoader(true);
    getRetryEngineDetails(getFormatedDate(startDate),getFormatedDate(endDate)).then((data) => {
      setStatsData(data.stats.stats)
      setBatchSummaryData(data.batchesSummary.batchesSummary);
      setLoader(false);
     }).catch(e=>{
      setLoader(false);
      localStorage.removeItem('token');
      navigate('/retryEngine');
     });
  }

  const handleLogout = () =>{
    localStorage.removeItem('token');
    localStorage.removeItem('user_data');
    sessionStorage.removeItem('rangeDate');
    navigate('/retryEngine');
  }
  const getValueInPercentage = (value, total) => {
    if(total === 0)
    return 0 + ' %'
    return (value/total*100).toFixed(2) + ' %'
  }
  
  const navigateToDetailsPage = (batchId) => {
    if(!(dates[0] instanceof Date)) {
      alert('Please select date range');
      return
    }
    sessionStorage.setItem("rangeDate", dates);
    navigate('/resummary', { state: {
      prevPath: location.pathname,
      from: 'redashboard',
      batchId,
      filterList: {
        paymentMethod : {
          title: 'Payment Mode',
          lists: [
            {name: 'All', value: false},
          ]
        },
        paymentRetryStatus : {
          title: 'Records',
          lists: [{name: 'All', value: false}]
        },
        currency : {
          title: 'Currency',
          lists: [{name: 'All', value: false}]
        },
        error : {
          title: 'Error',
          lists: [{name: 'All', value: false}]
        },
      }
    }});
  }
  if (loader) {
    return <Loader loaderText={loaderText} />;
  }
  return  (
    
  <div className={classes.custContainer}>
      <div className={classes.logoutHeader}><Button variant="contained" color="secondary" onClick={handleLogout}>Logout</Button></div>
      <div className={classes.topSection}>
        <h2>Retry Engine Dashboard</h2>
        <div className={classes.selectDateRange}><CustomDateRangeInputs dates={dates} setDates={setDates} /></div>
      </div>
      {statsData ? <div className={classes.retryInfo}>
          <div className={classes.retryInfoBlock}>
            <h3>Involuntary Churned<br/>Records<span className={classes.d1}>{statsData[0].involuntaryChurn}</span></h3>
          </div>
          <div className={classes.retryInfoBlock}>
            <h3>Not Eligible<br/>Records<span className={classes.d1}>{statsData[0].notEligible}</span></h3>
          </div>
          <div className={classes.retryInfoBlock}>
            <h3>Total Retry<br/>Count <span className={classes.d1}>{statsData[0].totalRetry}</span></h3>
          </div>
          <div className={classes.retryInfoBlock}>
            <h3>Payment Success<br/>Rate (%) <span className={classes.d1}>{getValueInPercentage(statsData[0].paymentSuccessCount,statsData[0].totalRetry)}</span></h3>
          </div>
          <div className={classes.retryInfoBlock}>
            <h3>Reactivation Success<br/>Rate (%) <span className={classes.d1}>{getValueInPercentage(statsData[0].reactivationSuccessCount,statsData[0].totalRetry)}</span></h3>
          </div>
        </div>
      : <h3>Data not found</h3>}
    <div className ={classes.batchSummary}>
      <h3>Batch Summary</h3>
        <TableContainer className={classes.tableContainer}>
          <Table  sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">Batch Start<br/>Date Time</TableCell>
                <TableCell align="center">Batch End<br/>Date Time</TableCell>
                <TableCell align="center">Batch<br/>Status</TableCell>
                <TableCell align="center">No of<br/>Records</TableCell>
                <TableCell align="center" width="135">Retry Payment<br/> Success Count</TableCell>
                <TableCell align="center" width="135">Retry Failure<br/> Count</TableCell>
                <TableCell align="center">Payment Success<br/> Rate (%)</TableCell>
                <TableCell align="center">Reactivation<br/>Success Count</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {batchSummaryData.length>0 ?
              <>
                {batchSummaryData.map(function(item, i) {
                    if(item === null) {
                      return;
                    }
                    return (<TableRow key={i}
                    >
                      <TableCell align="center"  scope="row">{item.batchDateTime}</TableCell>
                      <TableCell align="center">{item.batchEndTime}</TableCell>
                      <TableCell align="center" style={{position: "relative"}}>
                        {
                          item.batchStatus !== 'FAILED' ? item.batchStatus
                          :
                          <StatusComponent errmsg={item.batchErrorMessage} statusPopup={classes.viewRecordsPopup} />
                        }
                      </TableCell>
                      <TableCell align="center" style={{position: "relative"}}>
                        <RecordComponent data={item} viewRecordsPopup={classes.viewRecordsPopup} />
                      </TableCell>
                      <TableCell align="center">{item.chargeSuccessCount}</TableCell>
                      <TableCell align="center">{item.failureCount}</TableCell>
                      <TableCell align="center">{getValueInPercentage(item.chargeSuccessCount,item.records)}</TableCell>
                      <TableCell align="center">{item.reactivationSuccessCount}</TableCell>
                      <TableCell align="center"><span className={classes.viewDetails} onClick={() => {navigateToDetailsPage(item.batchId)}}>View Details</span></TableCell>
                    </TableRow>)
                })}
              </>
              :
              <TableRow>
                <TableCell colSpan={8}>Data not found</TableCell>
              </TableRow>
            }
          </TableBody>
          </Table>
        </TableContainer>
    </div>
  </div>)

}
export default RetryEngineDashboard;
