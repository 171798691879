import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import IconButton from '@material-ui/core/IconButton';
import CalendarToday from '@material-ui/icons/CalendarToday';

const CustomDateRangeInputs = (props) => {
    // const [dateRange, setDateRange] = useState([null, null]);
    // const [dateRange, setDateRange] = useState(props.dates);
    const [startDate, endDate] = props.dates;
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
      if(props.dates[1] !== null) {
        closeDatePicker()
      }
    },[props.dates]);

    const openDatePicker = () => {
      setIsOpen(true)
    }
    const closeDatePicker = () => {
      setIsOpen(false)
    }
    return (
      <>
        <IconButton
          aria-label="select dates"
          onClick={openDatePicker}
        >
        <CalendarToday />
      </IconButton>
      Date
        <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          props.setDates(update);
        }}
        onFocus={openDatePicker}
        isClearable={true}
        open={isOpen}
      />
      </>
    );
  };
  export default CustomDateRangeInputs;