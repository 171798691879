import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import PropTypes from 'prop-types';
import validator from 'validator';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { checkEmailAction } from '../../redux/actions/appActions';
import Banner from '../../components/Banner';
import { makeStyles } from "@material-ui/core/styles";
import qs from 'qs';
import TelcoCosumer from '../../utils/TelcoContext';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(6),
    "& input": {
      fontSize: 16,
      color: theme.palette.text.secondary,
    }
  },
  btn: {
    '&:hover': {
      color: '#fff',
      textDecoration: 'none',
    }
  }
}));



const Home = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state?.app?.lng);
  const telcoName = location.pathname.split('/').pop();
  const q = qs.parse(location.search, { ignoreQueryPrefix: true });
  const defaultEmail = q ? q.q : '';
  const isvalidate = defaultEmail ? true : false;
  const [val, setVal] = useState(defaultEmail);
  const [isValidate, setIsValidate] = useState(isvalidate);
  const welcomeToOsnImage = lng === "en" ? 'welcome-to-osn-en.png' : 'welcome-to-osn-ar.png'

  useEffect(() => {
    const req = new XMLHttpRequest();
    req.open('GET', document.location, false);
    req.send(null);
    const reqHeaders = req.getResponseHeader('sdp-test');
    console.log(reqHeaders);
  }, []);

  useEffect(() => {

  });

  const handleInput = e => {
    setVal(e.target.value)
    validator.isEmail(e.target.value) ? setIsValidate(true) : setIsValidate(false);
  }

  const isValidateEmail = useSelector((state) => {
    return state?.app;
  });

  console.log('home ===>>>>>>>>>', isValidateEmail);

  const validateEmail = () => {
    dispatch(checkEmailAction(val)).then(data => console.log('dispatch data', data));
  }

  return (
    <TelcoCosumer.Consumer>
      {context => (
        <Box className="wrapper">
          <Typography variant="h1"><small className="welcome-logo"><img src={`${process.env.S3_ENDPOINT}/common/${welcomeToOsnImage}`}/></small></Typography>
          <Banner
            imageSrc={`/assets/img/telco-welcome-${telcoName}`}
            imageAlt="mixedusps"
            fallbackSrc='/assets/img/icon-usp-mixedusps'
            txt={t('OTT_TLP_Home_Banner_subTitle')}
          />
          <Grid className={classes.container}>
            <TextField
              id="input-email"
              name="email"
              color="secondary"
              placeholder={t('OTT_TLP_Email_Text')}
              className={classes.input}
              defaultValue={defaultEmail}
              fullWidth
              onChange={handleInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/assets/img/icon-form-user.png" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid className="mT6">
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={validateEmail}
              // component={Link}
              className={classes.btn}
              // to={`/create-account?q=${val}`}
              disabled={!isValidate}
            >
              <Typography variant="button">{t('OTT_TLP_Home_Button_Text').toUpperCase()}</Typography>
            </Button>
          </Grid>
        </Box>
      )}
    </TelcoCosumer.Consumer>
  )
}

Home.propTypes = {
  context: PropTypes.shape({}),
}


export default Home;