const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const cEventUlpPageView = (countryCode, url, pageTitle) => {
    window.clevertap.event.push("Telco Universal Landing Page", {
        "Platform": "Website",
        "Country code": countryCode,
        "URL": url,
        "Page Title": pageTitle
    });
}

export const cEventUlpAuthJourney = (text, telcoCountry) => {
    window.clevertap.event.push("Telco provider selected", {
        "Platform": "Website",
        "Source Screen": "Unified Landing Page",
        "Telco Partner": capitalizeFirstLetter(text),
        "Telco Country": telcoCountry
    });
}

export const cEventOnTelcoPageView = (countryCode, telcoPartner,  url, pageTitle) => {
    window.clevertap.event.push("Telco Select Plan", {
        "Platform": "Website",
        "Country Code": countryCode.toUpperCase(),
        "URL": url,
        "Telco Partner": capitalizeFirstLetter(telcoPartner),
        "Page Title": pageTitle
    });
}

export const cEventOnSubscribeNowAuthJourney = (telcoPartner, telcoCountry, subscriptionPlan, subscriptionName, subscriptionAmount, subscriptionCurrency) => {
    window.clevertap.event.push("Telco mobile number entered", {
        "Platform": "Website",
        "Source Screen": "Telco Landing Page",
        "Telco Partner": capitalizeFirstLetter(telcoPartner),
        "Telco Country": telcoCountry.toUpperCase(),
        "Subscription Plan": 'Monthly',
        "Subscription Name": capitalizeFirstLetter(subscriptionName),
        "Subscription amount": subscriptionAmount,
        "Subscription Currency": subscriptionCurrency,
    });
}

export const cEventOnTelcoOtpPageView = (countryCode, url, pageTitle) => {
    window.clevertap.event.push("Telco OTP Verification Page", {
        "Platform": "Website",
        "Country Code": countryCode.toUpperCase(),
        "URL": url,
        "Page Title": pageTitle
    });
}


export const cEventOnVerifyOtpAuthJourney = (telcoPartner, telcoCountry) => {
    window.clevertap.event.push("Verify otp button clicked", {
        "Platform": "Website",
        "Source Screen": "OTP Page",
        "Telco Partner": capitalizeFirstLetter(telcoPartner),
        "Telco Country": telcoCountry.toUpperCase(),
    });
}
export const cEventOnVerifyOtpFailedAuthJourney = (telcoPartner, telcoCountry, subscriptionPlan, subscriptionName, subscriptionAmount, subscriptionCurrency, errorCode, errorText) => {
    window.clevertap.event.push("Mobile number verification - failed", {
        "Platform": "Website",
        "Source Screen": "OTP Page",
        "Telco Partner": capitalizeFirstLetter(telcoPartner),
        "Telco Country": telcoCountry.toUpperCase(),
        "Subscription Plan": "Monthly",
        "Subscription Name": capitalizeFirstLetter(subscriptionName),
        "Subscription amount": subscriptionAmount,
        "Subscription Currency": subscriptionCurrency,
        "Error Code": errorCode,
        "Error text": errorText,
    });
}

export const cEventOnWelcomeScreen = (telcoPartner, subscriptionPlan, subscriptionName, subscriptionAmount, subscriptionCurrency) => {
    window.clevertap.event.push("Welcome screen", {
        "Platform": "Website",
        "Telco Partner": capitalizeFirstLetter(telcoPartner),
        "Subscription Plan":  "Monthly",
        "Subscription Name": capitalizeFirstLetter(subscriptionName),
        "Subscription amount": subscriptionAmount,
        "Subscription Currency": subscriptionCurrency,
        "MOP": "Telco",
    });
}

export const cEventOnUserLogin = (customerId, phoneNo) => {
    const siteObj = {};
    if (!customerId) {
        siteObj.Identity = phoneNo;
    } else {
        siteObj.Identity = customerId;
        siteObj.Phone = phoneNo;
    }
    window.clevertap.onUserLogin.push({
        "Site": siteObj
    })
}



