import * as types from "../actions/actionTypes";

const initialState = {
  apiCallsInProgress: false,
  showLoader: false,
  loaderText: "Loading",
  error: null,
  successMsg: null,
};

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === "_SUCCESS" ? type : "";
}

export default function apiCallStatusReducer(state = initialState, action) {
  switch (action.type) {
    case types.BEGIN_API_CALL:
      return { ...state, ...action.data, apiCallsInProgress: true };
    case types.API_CALL_ERROR:
      return { ...state, error: action.error, showLoader: false };
    case actionTypeEndsInSuccess(action.type): {
      return { ...state, showLoader: false };
    }
    default:
      return initialState;
  }
}
