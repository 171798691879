import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid , Button, TextField} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from "@material-ui/core/Collapse";
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from  '@material-ui/core/Grow';
import Paper from  '@material-ui/core/Paper';
import { getCustomer, getEligibleDetails, updateStatus } from '../../api/customer';
import {useNavigate} from 'react-router-dom';
import Swal from "sweetalert2";
import { makeStyles , useTheme} from "@material-ui/core/styles";
import Moment from 'moment';
import Loader from "../../components/Loader";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import SearchCustomerDetails from "../CustomerCare/SearchCustomerDetails";
import ModifyCustomerDialog from "../CustomerCare/ModifyCustomerDialog";
import RefundCustomerDialog from "../CustomerCare/RefundCustomerDialog";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  loginCard :{
    width: "40%"
  },
  searchTerm: {
    width:"50% !important",
    '& .MuiFormLabel-root': {
      color: 'inherit'
    },
    '& input#searchTerm:-webkit-autofill,& input#searchTerm:-webkit-autofill:hover,& input#searchTerm:-webkit-autofill:focus,& input#searchTerm:-webkit-autofill:active': {
      'caret-color': '#fff',
    }
  },
  tableHead: {
    fontWeight :"bold",
    backgroundColor: "#deeaff",
  },
  formLogo: {
    textAlign: "center",
    margin: "10px 10px 10px 10px"
  },
  custContainer: {
    textAlign: "center",
    margin: "10px 10px 10px 10px",
    
  },
  tableContainer: {
    overflowX: 'unset',
    '& .MuiTableCell-body' : {
      color: 'inherit',
    }
  },
  logoutHeader: {
    textAlign: "right",
  },
  dropdownOption: {
    minWidth: 120,
    '& .MuiMenuItem-root' : {
      fontSize: 16
    }
  },
  linkBtn: {
    '& .MuiButton-text': {
      color: '#fff',
      textDecoration: "underline",
      textTransform: "capitalize",
    }
  },
  statusUpdateBar: {
    width: 420,
    position: 'fixed',
    right: 0,
    left: 0,
    top: 2,
    margin: 'auto',
    '& .MuiAlert-icon': {
      display: 'inherit'
    }
  }
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
let getDetailResponseCode;
let isTelco = false;
let eligbileData = null;
let selectedCountry = ''

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
let customerId,currentStatus,currentTelco;
let responsMessage=''
let isSuccess = false;
let paymentId, maxRefundAmount, refundedData;
const userDataOrder = [
  'Telco', 'Non_Telco'
]
const userDataOrdered = [];


const SearchCustomer = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showTable,setShowTable] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("")
  const [loader, setLoader] = useState(false);
  const [loaderText, setLoaderText] = useState("");
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const anchorRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem('user_data'));
  const [openRefundDialog, setOpenRefundDialog] = useState(false);


  useEffect(() => {
    if(!userDataOrdered.length) {
      for (var i = 0; i < userDataOrder.length; i++) {
        if (userData.mop.indexOf(userDataOrder[i]) > -1) {
          userDataOrdered.push(userDataOrder[i]);
        }
      }
    }
  },[]);

  const handleClick = index => {
    if (selectedIndex === index) {
      setSelectedIndex("")
    } else {
      setSelectedIndex(index)
    }
  }
  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const navigate = useNavigate();

  const handleError = () => {
    setLoader(false);
    localStorage.removeItem('token');
    navigate('/customercare');
  };

  const handleClickOpen = (custId,currtStatus,telcoCountry, telcoName) => {
    customerId = custId;
    currentStatus = currtStatus;
    currentTelco = telcoName;
    eligbileData = null;
    selectedCountry= telcoCountry;
    if (currtStatus === 'ACTIVE') {
      setOpenDialog(true);
      return;
    }
    setLoader(true);
    getEligibleDetails(telcoCountry).then((data) => {
      eligbileData = data;
      setLoader(false);
      setOpenDialog(true);
     }).catch(e=>{
      handleError()
     });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  
  const alertShow = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    },3000)
  }
  
  const handleUpdate = (data) =>{
    if(data.operation === "ACTIVE") {
      data.operation = 'REACTIVATE';
    }
    if(data.operation === "INACTIVE") {
      data.operation = 'SUSPEND';
    }
    updateStatus(data).then((response)=> {
      if(response.responseCode === 0) {
        isSuccess = true;
        responsMessage = "Status is updated successfully"
        handleClose();
        alertShow();
        handleSearchClick("Telco");
      } else {
        isSuccess = false;
        responsMessage = "Status is not updated. Something went wrong";
        handleClose();
        alertShow();
      }
    }).catch(e=>{
      handleError()
    });
  }

  const handleChange = (e)=>{
    setSearchTerm(e.target.value);
  }
  const handleLogout = () =>{
    localStorage.removeItem('token');
    localStorage.removeItem('user_data');
    navigate('/customercare');
  }
  const handleSearchClick = (mop) =>{
    isTelco = (mop === 'Telco' ? true : false);
    setSelectedIndex("")
    setShowTable(false);
    if(searchTerm.length <=3){
      Swal.fire({customClass:{confirmButton: 'MuiButton-root MuiButton-containedSecondary'},buttonsStyling:false,title: 'Error', text:'Please enter correct Customer ID',icon: 'error'})
    }
    else{   
      setLoader(true);
      setLoaderText("Loading details");
      getCustomer(searchTerm, mop).then((data) => {
        let rows = [];
        getDetailResponseCode = data.responseCode;
        if (data && data.responseCode === 0) {
          rows = data.subscriptionDetails;
        }
        setSearchResults(rows);
        setShowTable(true);
        setLoader(false);
      }).catch(e=>{
        handleError()
      });
    }
  }
  const afterRefundSuccessHandler = () => {
    handleSearchClick("Non_Telco")
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleCloseDrop = (e) => {
    e.target.dataset.value && handleSearchClick(e.target.dataset.value)
    setOpen(false);
  };
  const openRefundBoxHandler = (paymentTransactionsId, amount, foundSuccessRefund) => {
    paymentId = paymentTransactionsId;
    maxRefundAmount= amount;
    refundedData = foundSuccessRefund;
    setOpenRefundDialog(true)
  }
  const closeRefundDialog = () => {
    setOpenRefundDialog(false);
  };
  const searchResultRows = (searchResults) => {
    return searchResults.map((searchResult, index) => {
      return (
        <>
        <TableRow
            key={searchResults.customerId}
          >
            <TableCell align="center"  scope="row">{searchResult.telcoName}</TableCell>
            <TableCell align="center">{searchResult.telcoCountry}</TableCell>
            <TableCell align="center">{searchResult.customerId}</TableCell>
            <TableCell align="center">{searchResult.subscribedOffer}</TableCell>
            <TableCell align="center">{searchResult.subscriptionStartDate && Moment(searchResult.subscriptionStartDate,'YYYY-MM-DD[T]HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}</TableCell>
            <TableCell align="center">{searchResult.subscriptionEndDate && Moment(searchResult.subscriptionEndDate,'YYYY-MM-DD[T]HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}</TableCell>
            <TableCell align="center">{searchResult.currentStatus}</TableCell>
            <TableCell align="center">{searchResult.subStatus}</TableCell>
            <TableCell align="center">{searchResult.gwId}</TableCell>
             <TableCell align="center" className={classes.linkBtn}>
              <Button onClick={() => handleClick(index)}>Details</Button>
              {userData.role === 'SuperAdmin' && isTelco && 
              <> 
              <Button onClick={() => {handleClickOpen(searchResult.customerId,searchResult.currentStatus, searchResult.telcoCountry, searchResult.telcoName)}}>Modify</Button>
              </>
              }
              </TableCell> 
          </TableRow>
          <TableRow>
            <TableCell colSpan={10}>
              <Collapse in={index === selectedIndex} component="div" style={{ display: "block" }}>
                <div>
                  <SearchCustomerDetails userHistory={searchResult.userHistory} paymentTransactions={searchResult.paymentTransactions} refundDetails={searchResult.refundDetails} telcoName={searchResult.telcoName} openRefundBoxHandler={openRefundBoxHandler} userRole={userData.role}/>
                </div>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )
    })
  }

  let searchTable;
  if(showTable && searchResults.length > 0)
  {
    searchTable = <TableContainer className={classes.tableContainer}>
    <Table sx={{ minWidth: 650 }} aria-label="customized table">
      <TableHead className={classes.tableHead}>
        <TableRow>
          <TableCell align="center">Partner Name</TableCell>
          <TableCell align="center">Country</TableCell>
          <TableCell align="center">Customer ID</TableCell>
          <TableCell align="center">Subscribed Offer</TableCell>
          <TableCell align="center">Subscription Start Date</TableCell>
          <TableCell align="center">Next Renewal Date</TableCell>
          <TableCell align="center">Subscription Status</TableCell>
          <TableCell align="center">Sub Status</TableCell>
          <TableCell align="center">GW User ID</TableCell>
          <TableCell align="center">Options</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchResults && searchResults.length > 0 && searchResultRows(searchResults)}
      </TableBody>
  </Table>
  </TableContainer>
  }
  else if(showTable && getDetailResponseCode === 0 && searchResults.length === 0){
    searchTable = <h4>No results found</h4>
  }
  else if(showTable && getDetailResponseCode === 26){
    searchTable = <h4>Customer does not exists</h4>
  }
  else if(showTable && getDetailResponseCode !== 0){
    searchTable = <h4>Something went wrong</h4>
  }
  if (loader) {
    return <Loader loaderText={loaderText} />;
  }
return  (
  <div className={classes.custContainer}>
    <div className={classes.logoutHeader}><Button variant="contained" color="secondary" onClick={handleLogout}>Logout</Button></div>
    {showAlert && 
      <div className={classes.statusUpdateBar}>
       <Alert severity={isSuccess ? "success" : "error"}>{responsMessage}</Alert>
      </div>
    }
    <h2>Customer Search</h2>
    <Grid >
      <TextField id="searchTerm" className={classes.searchTerm} label="Customer ID/Mobile No./Email" value={searchTerm} variant="standard" onChange={ handleChange } />
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant="contained" color="secondary"
        endIcon={<ArrowDropDownIcon />}
      >
        Search
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        className={classes.dropdownOption}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseDrop}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {userDataOrdered.map((list, index) =>
                    <MenuItem key={list} data-value={list} onClick={handleCloseDrop}>{list}</MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
    <br/>
    {searchTable}
    {openDialog && <ModifyCustomerDialog handleUpdate={handleUpdate} openDialog={openDialog} handleClose={handleClose} customerIdProp={customerId} currentStatusProp={currentStatus} currentTelcoProp={currentTelco} eligbileData={eligbileData} selectedCountry={selectedCountry}/>}
    {openRefundDialog && <RefundCustomerDialog  openDialog={openRefundDialog} handleClose={closeRefundDialog} paymentId={paymentId} maxRefundAmount={maxRefundAmount} refundedData={refundedData} selectedIndex={selectedIndex} afterRefundSuccessHandler={afterRefundSuccessHandler}/>}
  </div>
)
}
export default SearchCustomer;
