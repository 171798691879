import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Grid, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import withTelcoHOC from "../../hoc/withTelco";
import { sendEvent } from "../../api/events";

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: "FaricyNewBold",
    fontWeight: "bold",
    fontSize: 16,
    height: 44,
    borderRadius: 8,
    display: "flex",
    width: "100%",
    textDecoration: "none",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    background: "#B40099",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const SubscriptionError = ({ telcoName, countryCode, lang }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { state } = useLocation();

  useEffect(() => {
    sendEvent(
      `${telcoName}-${countryCode}-Subscription-Error`,
      "LANDED",
      "Subscription issue found"
    );
    let timer = setTimeout(() => triggerDataLayer(), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const triggerDataLayer = () => {
    window.dataLayer.push({
      event: "TelcoJourney",
      eventCategory: "Telco - SUF",
      eventAction: "Step 3 - Already Subscribe",
      OperatorName: telcoName || "",
      Country: countryCode || "",
      Language: lang,
      PaymentOption: "telco",
      errorCode: "25",
      FailureReason: "User is already subscribed",
      FreeTrial: false,
      FreeTrialPeriod: null,
      PackageName: state?.offer?.offerName,
      PackagePrice: state?.offer?.price,
      Type: state?.type || "dcb",
      Connection: state?.isHE || "nonehe",
    });
  };

  return (
    <Box className="wrapper-error">
      <Typography variant="h1">
        {t("OTT_TLP_subscriptionError_title")}
      </Typography>
      <Typography className="mT4" variant="h2" align="center">
        {t("OTT_TLP_subscriptionError_msg")}
      </Typography>
      <Grid className="mT4">
        <Link
          color="secondary"
          className={classes.label}
          href={process.env.GET_STARTED_LINK}
        >
          {t("OTT_TLP_Welcome_startWatching")}
        </Link>
      </Grid>
    </Box>
  );
};

SubscriptionError.propTypes = {
  telcoName: PropTypes.string,
  countryCode: PropTypes.string,
  lang: PropTypes.string,
};

export default withTelcoHOC(SubscriptionError);
