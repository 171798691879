export async function getTranslations() {
  let translationEN = {};
  let translationAR = {};
  let translationFR = {};
  const responseEN = await fetch(`${process.env.LOCALE_ENDPOINT}/en.json`);
  const responseAR = await fetch(`${process.env.LOCALE_ENDPOINT}/ar.json`);
  const responseFR = await fetch(`${process.env.LOCALE_ENDPOINT}/fr.json`);

  if (responseEN.status == 200) {
    const jsonEN = await responseEN.json();
    translationEN = jsonEN;
  }

  if (responseAR.status == 200) {
    const jsonAR = await responseAR.json();
    translationAR = jsonAR;
  }
  if (responseFR.status == 200) {
    const jsonFR = await responseFR.json();
    translationFR = jsonFR;
  }

  return { translationEN, translationAR, translationFR };
}
