export const STCKSAPACKAGES = {
  MONTLY: "3507011", //350701
  WEEKLY: "350777",
  RAMADANOFFER: "3507001",
};
export const LEBARAKSAPACKAGES = {
  MONTLY: "350701",
  WEEKLY: "350777",
  RAMADANOFFER: "3507001",
};
export const ZAIN_KW_PACKAGES = {
  PKOSN12M: "OTT_TLP_selectPackageKwZain_Title",
  PKOSN02M: "OTT_TLP_selectPackageKwZain_package2_Title",
  OSNPREP: "OTT_TLP_selectPackageKwZain_package3_Title",
  350701: "OTT_TLP_selectPackageKwZain_package_default_Title",
  350801: "OTT_TLP_selectPackageKwZain_package_default_Title",
  PKOSN06M: "OTT_TLP_selectPackageKwZain_package4_Title",
};
export const ZAIN_KW_PACKAGES_PNC = {
  PKOSN12M: "OTT_TLP_selectPackage_zainkw_12montlyPrivacyText",
  PKOSN02M: "OTT_TLP_selectPackage_zainkw_02montlyPrivacyText",
  OSNPREP: "OTT_TLP_selectPackage_zainkw_ezeemontlyPrivacyText",
  350701: "OTT_TLP_selectPackage_zainkw_dcbmontlyPrivacyText",
  350801: "OTT_TLP_selectPackage_zainkw_dcbmontlyPrivacyText",
  PKOSN06M: "OTT_TLP_selectPackage_zainkw_06monthlyPrivacyText",
};
export const ZAINIQPACKAGES = {
  MONTHLY: "350701",
  WEEKLY: "350777",
};
export const ZAINBHPACKAGES = {
  PREMIUM: "350701",
  STANDARD: "350702",
};
export const OOREDOO_QA_PACKAGES = {
  MONTLY: "350701",
  STANDARD: "350777",
};
export const MAROC_MA_PACKAGES = {
  PREMIUM: "350701",
  STANDARD: "355001",
};
export const ORANGE_JO_PACKAGES = {
  PREMIUM: "350101",
  STANDARD: "350201",
};
export const MOBILY_SA_PACKAGES = {
  MONTLY: "350701",
  WEEKLY: "350777",
};
export const ORANGE_MA_PACKAGES = {
  PREMIUM: "350101",
  STANDARD: "350201",
};
export const REDBULLMOBILE_SA_PACKAGES = {
  MONTLY: "350701",
  WEEKLY: "350777",
};
export const OOREDOO_TN_PACKAGES = {
  PREMIUM: "350701",
  STANDARD: "355001",
};

export const ZAIN_KSA_DEFAULT_PACKAGES = {
  MBB: {
    heading: "OTT_TLP_selectPackage_zainksa_MBBHeading",
    requiredOTP: false,
    placeHolder: "8XXXXXXXX",
    privacyText: "OTT_TLP_selectPackage_zainksa_MBBPrivacyText",
    offerName: "OTT_TLP_selectPackage_zainksa_mbbText",
    category: "MBB",
  },
  VOICE: {
    heading: "OTT_TLP_selectPackage_zainksa_VoiceHeading",
    requiredOTP: true,
    placeHolder: "5XXXXXXXX",
    privacyText: "OTT_TLP_selectPackage_zainksa_VoicePrivacyText",
    offerName: "OTT_TLP_selectPackage_zainksa_voiceText",
    category: "VOICE",
  },
  FIBER: {
    heading: "OTT_TLP_selectPackage_zainksa_FibereHeading",
    requiredOTP: false,
    placeHolder: "7XXXXXXXX",
    privacyText: "OTT_TLP_selectPackage_zainksa_FIBERPrivacyText",
    offerName: "OTT_TLP_selectPackage_zainksa_fiberText",
    category: "FIBER",
  },
};

let ZAIN_KSA_PACKAGES = {};
let ZAIN_KSA_DCB_OFFERS = {};
let HARD_LIFETIME_BUNDLES = {};
if (process.env.ENV === "production") {
  ZAIN_KSA_PACKAGES = {
    1000032333: "OTT_TLP_selectPackage_zainksa_fiberPack1",
    1000031838: "OTT_TLP_selectPackage_zainksa_fiberPack2",
    1000044651: "OTT_TLP_selectPackage_zainksa_mbb1",
    1000031751: "OTT_TLP_selectPackage_zainksa_mbb2",
    1000031836: "OTT_TLP_selectPackage_zainksa_mbb3",
    1000033305: "OTT_TLP_selectPackage_zainksa_mbb4",
    1000044649: "OTT_TLP_selectPackage_zainksa_voice1",
    1000031731: "OTT_TLP_selectPackage_zainksa_voice2",
    1000032971: "OTT_TLP_selectPackage_zainksa_voice3",
    1000034495: "OTT_TLP_selectPackage_zainksa_voice4",
    1000043689: "OTT_TLP_selectPackage_zainksa_voice5",
    "Voice-HB": "OTT_TLP_selectPackage_zainksa_voicehb",
    "MBB-HB": "OTT_TLP_selectPackage_zainksa_mbbhb",
    "FTTH-HB": "OTT_TLP_selectPackage_zainksa_ftthhb",
  };
  ZAIN_KSA_DCB_OFFERS = ["1000044649", "1000044651", "1000032333"];
  HARD_LIFETIME_BUNDLES = [
    "Voice-HB",
    "MBB-HB",
    "FTTH-HB",
    "1000033305",
    "1000034495",
  ];
} else {
  ZAIN_KSA_PACKAGES = {
    1000036441: "OTT_TLP_selectPackage_zainksa_fiberPack1",
    1000036443: "OTT_TLP_selectPackage_zainksa_fiberPack2",
    1000036437: "OTT_TLP_selectPackage_zainksa_mbb1",
    1000036439: "OTT_TLP_selectPackage_zainksa_mbb2",
    1000036429: "OTT_TLP_selectPackage_zainksa_mbb3",
    1000034649: "OTT_TLP_selectPackage_zainksa_mbb4",
    1000036431: "OTT_TLP_selectPackage_zainksa_voice1",
    1000036433: "OTT_TLP_selectPackage_zainksa_voice2",
    1000036435: "OTT_TLP_selectPackage_zainksa_voice3",
    1000034651: "OTT_TLP_selectPackage_zainksa_voice4",
    1000043689: "OTT_TLP_selectPackage_zainksa_voice5",

    1000044649: "OTT_TLP_selectPackage_zainksa_voice1",
    1000044651: "OTT_TLP_selectPackage_zainksa_mbb1",
    "Voice-HB": "OTT_TLP_selectPackage_zainksa_voicehb",
    "MBB-HB": "OTT_TLP_selectPackage_zainksa_mbbhb",
    "FTTH-HB": "OTT_TLP_selectPackage_zainksa_ftthhb",
  };
  ZAIN_KSA_DCB_OFFERS = ["1000036441", "1000036437", "1000036431"];
  HARD_LIFETIME_BUNDLES = [
    "Voice-HB",
    "MBB-HB",
    "FTTH-HB",
    "1000034651",
    "1000034649",
  ];
}

export { ZAIN_KSA_PACKAGES, ZAIN_KSA_DCB_OFFERS, HARD_LIFETIME_BUNDLES };
