import React from "react";
import {useNavigate} from 'react-router-dom';

const CustomerAuth = (props)=>{
    const { children, from } = props;
    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user_data'));
    if(!token && from === 'customercare')
    {
        navigate('/customercare');
    } else if(!token && from === 'retryEngine' || token && userData.role !== "RetryEngine" && from === 'retryEngine')
    {
        navigate('/retryEngine');
    }
    
    return ( children );
}

export default CustomerAuth;