export const SUCCESS = 0;
export const INCORRECTOTP = 43;
export const EXCEPTION = 99;
export const OTPLIMITREACHED = 44;
export const CUSTOMER_ALREADY_EXIST = 25;
export const INVALID_MOBILE_NUMBER = 45;
export const USER_BLOCKED = 46;
export const INSUFFICIENT_BALANCE = 42;
export const INCORRECT_CIVIL_ID = 47;
export const TIMEOUT_STRING = 'ECONNABORTED';
export const TIMEOUT = 48;
export const CUSTOMER_DOSE_NOT_EXIST = 26;
export const OTP_EXPIRED = 49;
export const USER_NOT_ELIGIBLE = 97;