import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Link } from "@material-ui/core";
import { useLocation, Navigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { freeTrialCyclePeriodforGA as freeTrialCyclePeriod  } from "../../../utils/common";
import { cEventOnWelcomeScreen } from "../../../utils/clevertap"
import { useStyles } from "./styles";
import { sendEvent } from "../../../api/events";
import withTelcoHOC from "../../../hoc/withTelco";
import {
  HARD_LIFETIME_BUNDLES,
  ZAIN_KSA_PACKAGES,
} from "../../../constants/packages";


const SubscriptionSuccessPage = ({ telcoName, countryCode, lang }) => {
  const lng = useSelector((state) => state?.app?.lng);
  const classes = useStyles({lng});
  const { t } = useTranslation();
  const { state } = useLocation();
  const [mobileSrc, setMobileSrc] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const telcoText = `${t(`OTT_TLP_${telcoName}_Text`, "")}`;
  const page = `${telcoName}-${countryCode}-thankyou`;

  useEffect(() => {
    const offer = state?.data?.productOffer;
    const subscriptionPlan = offer?.offerName;
    let timer = setTimeout(
      () =>
        triggerDataLayer(
          { event: "TelcoJourney" },
          state?.data?.productOffer,
          state?.data
        ),
      500
    );
    offer?.productType && cEventOnWelcomeScreen(telcoName, subscriptionPlan, offer?.productType, offer.price, offer.currency)
    sendEvent(page, "Landed", `${state?.prevPath}`, { ...state?.data });
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setMobileSrc(process.env.APPLE_STORE_LINK);
    } else {
      setMobileSrc(process.env.GOOGLE_STORE_LINK);
    }
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const triggerDataLayer = (params = {}, offer = {}, data = {}) => {
    window.dataLayer.push({
      event: params.event,
      UserID: data.osnGatewayCustomerId,
      SubscriptionGroupID: data.subscriptionGroupId,
      OperatorName: telcoName || "",
      Country: countryCode || "",
      PackageName: offer?.offerName?.toLowerCase() || null,
      Language: lang || "en",
      PackagePrice: offer.price,
      PaymentOption: "telco",
      Type: state?.type || "dcb",
      Connection: state?.isHE || "nonhe",
      FreeTrialPeriod: data?.isFreeTrial
        ? freeTrialCyclePeriod(
            state?.data?.productOffer?.freeTrialCyclePeriod,
            state?.data?.productOffer?.freeTrialCycleNumber
          )
        : null,
      FreeTrial: data.isFreeTrial,
    });
  };

  const handleEvents = () => {
    sendEvent(page, "GETSTARTED CLICK", "");
  };

  if (!state?.offer && !state?.data?.osnGatewayCustomerId) {
    return <Navigate to={`/${countryCode}/${telcoName}`} />;
  }

  return (
    <Fragment>
      <Box className={classes.successWrapper}>
        <Box className={classes.campaignText} mt={2}>
          <Box className={classes.icon}>
            <img
              src="/assets/img/icon-informative-success-icon.png"
              srcSet="/assets/img/icon-informative-success-icon@2x.png 2x,
              /assets/img/icon-informative-success-icon@3x.png 3x"
              className="logo-osn"
            />
          </Box>
        </Box>
        <Typography
          gutterBottom
          align="center"
          variant="h2"
          className={classes.subTitle}
          id="welcome-txt"
        >
          {t("OTT_TLP_Welcome_subscriptionContentA")}
        </Typography>
        <Typography variant="h2" align="center">
          {state?.data?.newCustomer ? (
            <>
              <Typography variant="h2" align="center" className={classes.heading}>
                {t("OTT_TLP_Welcome_Title").toUpperCase()}{" "}
                <span className={classes.viaText}>
                  {t("OTT_TLP_Generic_Welcome_Via_Text")}
                </span>{" "}
                {telcoText}
              </Typography>
              <Box mt={4} className={classes.wrapHeading}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h2"
                  style={{ marginTop: 15 }}
                >
                  {t("OTT_TLP_thankYou_msg3")}
                </Typography>
                {HARD_LIFETIME_BUNDLES.includes(
                  state?.data?.productOffer?.subscriptionTypeId
                ) ||
                ZAIN_KSA_PACKAGES[
                  state?.data?.productOffer?.subscriptionTypeId
                ] === "OTT_TLP_selectPackage_zainksa_fiberPack2" ? (
                  <Typography
                    gutterBottom
                    align="center"
                    variant="h2"
                    style={{ marginTop: 15 }}
                  >
                    {t(
                      "OTT_TLP_selectPackage_zainksa_thankYouMsg_hardLifeBundle",
                      {
                        days: `${state?.data?.productOffer?.freeTrialCycleNumber} ${state?.data?.productOffer?.freeTrialCyclePeriod}`,
                      }
                    )}
                  </Typography>
                ) : (
                  <>
                    {state?.data?.productOffer?.freeTrialCyclePeriod !== null ? (
                      <>
                    <Typography
                      gutterBottom
                      align="center"
                      variant="h2"
                      style={{ marginTop: 15 }}
                    >
                      {t("OTT_TLP_thankYou_msg1", {
                        days: `${state?.data?.productOffer?.freeTrialCycleNumber} ${state?.data?.productOffer?.freeTrialCyclePeriod}`,
                      })}
                    </Typography>
                    <Typography
                      gutterBottom
                      align="center"
                      variant="h2"
                      style={{ marginTop: 15 }}
                    >
                      {t("OTT_TLP_thankYou_msg2", {
                        currency: state?.data?.productOffer?.currency,
                        price: state?.data?.productOffer?.price,
                        cycle: state?.data?.productOffer?.cyclePeriod,
                      })}
                    </Typography>
                    </>
                    ) : (
                      <Typography
                        gutterBottom
                        align="center"
                        variant="h2"
                        style={{ marginTop: 15 }}
                        dangerouslySetInnerHTML={{__html: t("OTT_TLP_thankYou_weekly_msg2", {
                          currency: state?.data?.productOffer?.currency,
                          price: state?.data?.productOffer?.price,
                          cycle: t(state?.data?.productOffer?.cyclePeriod),
                        })}}
                      >
                        {/* {t("OTT_TLP_thankYou_weekly_msg2", {
                          currency: state?.data?.productOffer?.currency,
                          price: state?.data?.productOffer?.price,
                          cycle: t(state?.data?.productOffer?.cyclePeriod),
                        })}  */}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h2" align="center" className={classes.heading}>
                {t("OTT_TLP_thankYou_existing_title").toUpperCase()}{" "}
                <span className={classes.viaText}>
                  {t("OTT_TLP_Generic_Welcome_Via_Text")}
                </span>{" "}
                {telcoText}
              </Typography>
              <Box mt={4}>
                {HARD_LIFETIME_BUNDLES.includes(
                  state?.data?.productOffer?.subscriptionTypeId
                ) ||
                ZAIN_KSA_PACKAGES[
                  state?.data?.productOffer?.subscriptionTypeId
                ] === "OTT_TLP_selectPackage_zainksa_fiberPack2" ? (
                  <Typography
                    gutterBottom
                    align="center"
                    variant="h2"
                    style={{ marginTop: 15 }}
                  >
                    {t(
                      "OTT_TLP_selectPackage_zainksa_thankYouMsg_hardLifeBundle_back"
                    )}
                  </Typography>
                ) : state?.data?.isFreeTrial ? (
                  <>
                    <Typography
                      gutterBottom
                      align="center"
                      variant="h2"
                      style={{ marginTop: 15 }}
                    >
                      {t("OTT_TLP_thankYou_msg1", {
                        days: `${state?.data?.productOffer?.freeTrialCycleNumber} ${state?.data?.productOffer?.freeTrialCyclePeriod}`,
                      })}
                    </Typography>
                    <Typography
                      gutterBottom
                      align="center"
                      variant="h2"
                      style={{ marginTop: 15 }}
                    >
                      {t("OTT_TLP_thankYou_msg2", {
                        currency: state?.data?.productOffer?.currency,
                        price: state?.data?.productOffer?.price,
                        cycle: state?.data?.productOffer?.cyclePeriod,
                      })}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    gutterBottom
                    align="center"
                    variant="h2"
                    style={{ marginTop: 15 }}
                  >
                    {t("OTT_TLP_thankYou_existing_msg1", {
                      currency: state?.data?.productOffer?.currency,
                      price: state?.data?.productOffer?.price,
                      cycle: state?.data?.productOffer?.cyclePeriod,
                    })}
                  </Typography>
                )}

                <Typography
                  gutterBottom
                  align="center"
                  variant="h2"
                  style={{ marginTop: 15 }}
                >
                  {t("OTT_TLP_thankYou_existing_msg2")}
                </Typography>
              </Box>
            </>
          )}
        </Typography>

        {isMobile ? (
          <>
            <Box mt={5}>
              <Link
                color="secondary"
                className={classes.label}
                href={mobileSrc}
              >
                {t("OTT_TLP_Welcome_getStarted")}
              </Link>
            </Box>
            <Box component="p" className={classes.appHeading} mt={3}>
              {t("OTT_TLP_Welcome_playStoreAppsHeading")}
            </Box>
            <Box className={classes.storeIcons}>
              <a href={process.env.APPLE_STORE_LINK}>
                <img
                  src="/assets/img/store-apple.png"
                  srcSet="/assets/img/store-apple@2x.png 2x,
          /assets/img/store-apple@3x.png 3x"
                  className="logo-osn"
                  id="start-watch-apple"
                />
              </a>
              <a href={process.env.GOOGLE_STORE_LINK}>
                <img
                  src="/assets/img/store-google.png"
                  srcSet="/assets/img/store-google@2x.png 2x,
          /assets/img/store-google@3x.png 3x"
                  className="logo-osn"
                  id="start-watch-google"
                />
              </a>
            </Box>
          </>
        ) : (
          <>
            <Box mt={5}>
              <Link
                color="secondary"
                className={classes.label}
                onClick={handleEvents}
                href={process.env.GET_STARTED_LINK}
                id="start-watch"
              >
                {t("OTT_TLP_Welcome_startWatching")}
              </Link>
            </Box>
          </>
        )}
      </Box>
    </Fragment>
  );
};

SubscriptionSuccessPage.propTypes = {
  telcoName: PropTypes.string,
  countryCode: PropTypes.string,
  lang: PropTypes.string,
};

export default withTelcoHOC(SubscriptionSuccessPage);
