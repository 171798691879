import axios from "axios";
import { encryption, readCookies } from "../utils/common"

const baseUrl = process.env.BACKEND_ENDPOINT;
export const ajax = axios.create({
  baseURL: baseUrl + "/api",
});

export const ajaxWithAuth = axios.create({
  baseURL: baseUrl + "/api",
});

export const getTime = async () => {
  return ajax
    .get(`/telco/get-time`)
    .then(handleResponse)
    .catch(handleError);
}

const token = readCookies("accessToken");
// Add a request interceptor
ajaxWithAuth.interceptors.request.use(async (request) => {
  const { url, method } = request;
  // Do something before request is sent
  // declare time
  let time = null;

  // Will call the time API only if the securiy is enabled.
  if (process.env.BYPASS_SECURITY === "false") {
    time = await getTime();
  }
  return {
    ...request,
    headers: {
      Authorization: `${token}`,
      authHeader: encryption(url, method, time?.unix),
      "Content-Type": "application/json",
    },
  };
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export async function handleResponse(response) {
  if (response.status === 200) {
    if (response.data) return response?.data;
    return;
  }
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    console.log("err__", response?.data);
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  console.log('API FAILED ===>>', JSON.stringify(error));
  throw ({
    code: error?.code,
    message: error?.message,
  });
}

