import React, { useState, useEffect, useCallback } from 'react';
import { Grid , Button, TextField, Checkbox, FormGroup, FormControlLabel  } from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import JsonToCSVButton from "../../components/JsonToCSVButton";
import { getReTryEngineBatchDetails } from '../../api/retryEngine';

let customeData = [];
let isError = false;

const useStyles = makeStyles(() => ({
  
  custContainer: {
    width:  'calc(100% - 20px)',
    margin: '10px',
    float: 'left'
  },
  leftSec: {
    width: '260px',
    paddingTop: '140px',
    position: 'relative',
    float: 'left',    
    '& .MuiIconButton-root': {
      color: '#fff',
      paddingTop: 0,
      paddingRight: 0,
    }
  },
  handleBack: {
    position: 'absolute',
    top: '10px'
  },
  leftBlock: {
    border: '1px solid #fff',
    marginBottom: '10px',
    '& .topHe': {
      padding: '10px',
      cursor: 'pointer',
      fontSize: '18px',
      position: 'relative',
      paddingLeft: '24px',
      backgroundColor: "#B40099",
      margin: 0,
      "&:before": {
        content: '"-"',
        fontSize: '24px',
        display: 'block',
        position: 'absolute',
        top: 4,
        left: 5,
      }
    },
    '&.hide .topHe': {
      "&:before": {
        content: '"+"',        
      } 
    },
    '& ul': {
      listStyle: 'none',
      margin: '0',
      padding: '10px 20px',
      borderTop: '1px solid #fff',
    },
    '&.hide ul' : {
      display: 'none'
    },
    '& ul li': {
      paddingTop: '5px',
    },
    '& ul li label span:last-child': {
      width: 160,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  },
  rightSec: {
    width:  'calc(100% - 280px)',
    float: 'right'
  },
  tableHead: {
    fontWeight :"bold",
    backgroundColor: "#deeaff",
    '& .MuiTableCell-head': {
      // lineHeight: '18px',
      padding: 10
    }
    
  }, 
  tableContainer: {
    overflowX: 'unset',
    '& .MuiTableCell-body' : {
      color: 'inherit',
    }
  },
  logoutHeader: {
    textAlign: "right",
  },
  topSection: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: '20px',
    minHeight: '80px',
    '& .MuiIconButton-root': {
      color: '#fff',
      padding: '0 5px 0 0',
    }
  },

  selectDateRange: {
    position: 'absolute',
    top: '5px',
    right: '10px',
    width: '290px',
    padding:5,
    border: '1px solid #fff',
    textAlign: 'left',
    '& .react-datepicker__input-container input[type="text"]': {
      width: '200px',
      outline: 'none',
      border: 0,
      background: 'rgba(255, 255, 255, .2)',
      padding: '3px 5px',
      color:'#fff',
    },
    '& .react-datepicker-wrapper' : {
      width: 'auto',
      marginLeft: 10,
      float: 'right',
    },
  },
  retryInfo : {
    display: 'flex',
    alignContent: 'space-between',
  },
  retryInfoBlock: {
    border: '1px solid #fff',
    margin: '0px 10px',
    padding: '10px',
    width: '25%',
    '& h3' : {
      fontSize: '18px'
    }
  },
  retryChartBlock: {
    display: 'flex', justifyContent: 'center'
  },
  batchSummary: {
    marginTop: '20px'
  },
  formControlLabel : {
    marginBottom : 0,
    '& .MuiCheckbox-root': {
      padding: 0,
      marginBottom: 0
    },
    '& .MuiTypography-body1': {
      color: '#fff',
      fontSize: 16,
      marginLeft: 10,
    }
  }

}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const RetryEngineSummary = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state, state:{filterList, batchId} } = useLocation();
  const [checkedState, setCheckedState] = useState(filterList);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loader, setLoader] = useState(false);
  const [loaderText] = useState("");
  const [batchDetailsData, setBatchDetailsData] = useState([])
  let csvBatchDetailsData = null;
  const filteredKey = {
    paymentMethod: [],
    paymentRetryStatus: [],
    currency: [],
    error: [],
  }
  useEffect(() => {
    filteredKey.paymentMethod = [];
    filteredKey.paymentRetryStatus = [];
    filteredKey.currency = [];
    filteredKey.error = [];
    Object.keys(checkedState).forEach((item) => {
      checkedState[item].lists.forEach((list, i) => {
        if(!(checkedState[item].lists[0].value)) {
          // const name = list.name.toUpperCase();
          const name = list.name;
          if(list.value && item === 'paymentMethod') {
            filteredKey.paymentMethod.push(name);
          }
          if(list.value && item === 'paymentRetryStatus') {
            filteredKey.paymentRetryStatus.push(name);
          }
          if(list.value && item === 'currency') {
            filteredKey.currency.push(name);
          }
          if(list.value && item === 'error') {
            filteredKey.error.push(name);
          }
        }
      })
    })
    setBatchDetailsData(multiPropsFilter(customeData, filteredKey))
  },[checkedState]);

  const multiPropsFilter = (customeData, filteredKey) => {
    const filterKeys = Object.keys(filteredKey);
    return customeData.filter(product => {
      return filterKeys.every(key => {
        if (!filteredKey[key].length) return true;
        if (Array.isArray(product[key])) {
          return product[key].some(keyEle => filteredKey[key].includes(keyEle));
        }
        return filteredKey[key].includes(product[key]);
      });
    });
  };

  useEffect(() => {
    getBatchDetails();
  },[]);


  const getBatchDetails = () => {
    setLoader(true);
    getReTryEngineBatchDetails(batchId).then((data) => {
      const uniqueCurrency = [];
      const uniqueStatus = [];
      const uniqueErrorCode = [];
      const uniquePaymentMethod = [];

      data.batchDetails.forEach((details) => {
        if(!details && !isError) {
          isError = true;
        }
        if(details && uniqueCurrency.indexOf(details.currency) === -1) {
          uniqueCurrency.push(details.currency);
          filterList.currency.lists.push({name: details.currency, value: false});
        }
        if(details && uniquePaymentMethod.indexOf(details.paymentMethod) === -1) {
          uniquePaymentMethod.push(details.paymentMethod);
          filterList.paymentMethod.lists.push({name: details.paymentMethod, value: false})
        }
        if(details && uniqueStatus.indexOf(details.paymentRetryStatus) === -1) {
          uniqueStatus.push(details.paymentRetryStatus);
          filterList.paymentRetryStatus.lists.push({name: details.paymentRetryStatus, value: false})
        }
        if(details && details.error && uniqueErrorCode.indexOf(details.error) === -1) {
          uniqueErrorCode.push(details.error);
          filterList.error.lists.push({name: details.error, value: false})
        }
      })
      setBatchDetailsData(data.batchDetails);
      customeData = data.batchDetails;
      setLoader(false);
     }).catch(e=>{
      setLoader(false);
      localStorage.removeItem('token');
      navigate('/retryEngine');
     });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnChange = (event, facet, position) => {
    const targetVal = event.target.checked;
    if (position === 0) {
      setCheckedState({...checkedState, [facet]: {...checkedState[facet],lists:checkedState[facet].lists.map((item, index) => {
        return {...item, value:targetVal}
      })}});
    } else {
      let allTrue = true;
      const updatedCheckedState = checkedState[facet].lists.map((item, index) => {
        if(index === 0 && item.value) {
          return {...item, value:false}
        }
        else if (index === position) {
          if(!targetVal) {
            allTrue = false;
          }
          return {...item, value:targetVal}
        } else {
          if(index !== 0 && !item.value) {
            allTrue = false;
          }
          return {...item, value:item.value}
        }
      })
      if(allTrue) {
        updatedCheckedState[0].value = true;
      }
      setCheckedState({...checkedState, [facet]: {...checkedState[facet],lists:updatedCheckedState}});
    }
  }
  const handleLogout = () =>{
    localStorage.removeItem('token');
    localStorage.removeItem('user_data');
    sessionStorage.removeItem('rangeDate');
    navigate('/retryEngine');
  }
  const handleBack = () =>{
    navigate('/redashboard');
  }
  
  const toggleList = (e) => {
      e.target.parentElement.classList.toggle('hide')
  }

  const getPerpageBatchDetails = (batchDetailsData,rowsPerPage, page) => {
    if(rowsPerPage > 0) {
      csvBatchDetailsData = batchDetailsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      return csvBatchDetailsData;
    }
    csvBatchDetailsData = batchDetailsData
    return csvBatchDetailsData;
  }

  if(state?.prevPath !== '/redashboard') {
    return(
      <Navigate to={'/redashboard'} />
    )
  }

  if (loader) {
    return <Loader loaderText={loaderText} />;
  }
  return  (
    
  <div className={classes.custContainer}>
    <div className={classes.leftSec}>
      <Button variant="contained" color="secondary" className={classes.handleBack} onClick={handleBack}>Back</Button>
      {Object.keys(filterList).map((item, index) => {       
        return (
          <div key={`${item}`} className={classes.leftBlock}>
            <h4 className="topHe"  onClick={(e) => {toggleList(e)}}>{filterList[item].title}</h4>
            <FormGroup>
              <ul style={{maxHeight: 160, overflow: 'auto'}}>
                {filterList[item].lists.map((list, i) => {
                  return <li key={`${item}_list_${i}`}>
                    <FormControlLabel 
                      className={classes.formControlLabel} 
                      control={
                        <Checkbox 
                          inputProps={{
                            "data-test": checkedState[item],
                          }}
                          onChange={(e) => handleOnChange(e,item, i)}
                          color="primary" 
                          checked={checkedState[item].lists[i].value}
                        />
                      } 
                      label={list.name}
                    />
                  </li>
                })}
              </ul>
            </FormGroup>
          </div>
        )
      })}
    </div>
    <div className={classes.rightSec}>      
      <div className={classes.logoutHeader}>
        <Button variant="contained" color="secondary" onClick={handleLogout}>Logout</Button>
      </div>
      <div className={classes.topSection}>
        <h2>Batch Details Report</h2>
        <JsonToCSVButton json={customeData} isRetryMap={true} isError={isError} csvName="SDPRetryReport"/>
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table  sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center" width={250}>Customer ID</TableCell>
              <TableCell align="center">Charge Amount</TableCell>
              <TableCell align="center">Currency</TableCell>
              <TableCell align="center">Retry Status</TableCell>
              <TableCell align="center">Error</TableCell>
              <TableCell align="center">Reactivation<br /> Success</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {batchDetailsData.length>0 ?
              <>
                {(getPerpageBatchDetails(batchDetailsData,rowsPerPage, page)).map((item, i) => {
              if(!item) {
                return (<TableRow key={i}>
                  <TableCell colSpan={7}>Data having empty value</TableCell>
                </TableRow>)
              }
              return (
                <TableRow key={i}
                >
                  <TableCell align="center"  scope="row">{item.retryTime}</TableCell>
                  <TableCell align="center">{item.customerId}</TableCell>
                  <TableCell align="center">{item.chargeAmount}</TableCell>
                  <TableCell align="center">{item.currency}</TableCell>
                  <TableCell align="center">{item.paymentRetryStatus}</TableCell>
                  <TableCell align="center">{item.error}</TableCell>
                  <TableCell align="center">{item.reactivationStatus}</TableCell>
                </TableRow>
                )
            })}
              </>
              :
              <TableRow>
                <TableCell colSpan={7}>Data not found</TableCell>
              </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow>
            <Pagination
                listLength={batchDetailsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                selectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>  
    </div>

  </div>)

}
export default RetryEngineSummary;
