import React, { useState, useEffect } from 'react';
import { Button, TextField, Select, MenuItem} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from "@material-ui/core/styles";
import { submitRefund } from '../../api/customer';
import Loader from '../../components/Loader';

const useStyles = makeStyles(() => ({
  modifyPopup : {
    color: '#000',
    fontSize: 14,
    '& .loader-background': {
      backgroundColor: 'rgba(6, 0, 17, .9)',
    },
    '& .MuiDialog-paperScrollPaper': {
       border: '5px solid #B40099',
    },
    '& .MuiTypography-colorTextSecondary' : {
      color: '#000',
    },
    '& .MuiInputLabel-root': {
      display: 'inline-block',
      marginRight: 10,
    },
    '& .MuiDialogContent-root': {
      fontSize: 14,
    },
    '& .MuiDialogContentText-root': {
      fontSize: 16,
    },
    '& .row-wise': {
      marginBottom: 14,
      '& .MuiInput-multiline': {
        width: '100%',
        border: '1px solid #cbcbcb',
        padding: 5,
      },
      '&.comment-box': {
        '& .MuiFormControl-root':  {
          width: '70%'
        }
      },
      '& strong': {
        minWidth: 130,
        display: 'inline-block'
      },
      '&.amount-box': {
        '& .MuiFormControl-root':  {
          width: '24%'
        },
        '& .MuiInputBase-root': {
          width: '100%'
        },
        '& .MuiInput-input': {
          border: '1px solid #cbcbcb',
          padding: 5,
          color:'#333'
        }
      },
      '&.chargeAmount-box':{
        '& span': {
          marginLeft: 8,
        }
      },
      '& .error': {
        fontSize:11,
        color: 'red',
        marginLeft: 10
      },
      '&.reason-box': {
        '& .MuiFormControl-root':  {
          width: '70%'
        },
        '& .MuiInput-input': {
          width: '100%',
        }
      },
    },
    '& .MuiFormControl-root': {
      'flex-direction': 'row',
    },
    '& .MuiInput-root': {
      marginLeft: 10,
      fontSize: 14,
    },
    '& .MuiSelect-selectMenu': {
      padding: '5px 25px 5px 5px',
      minWidth: 250,
      border: '1px solid #cbcbcb',
    }
  },
  modifyPopupTitle: {
    backgroundColor: '#B40099',
    color: '#fff',
    padding: '3px 24px',
    '& h2': {
      fontSize: '1.15rem',
    }
  },
  modifyPopupContent: {
    paddingTop: 30,
    '& sup': {
      color: '#B40099'
    }
  }
}));

let refundResponsMessage = ""



const RefundCustomerDialog = (props) => {
  const {handleClose, openDialog, maxRefundAmount, paymentId, refundedData, selectedIndex, afterRefundSuccessHandler } = props;
  const classes = useStyles();
  const [commentValue,setCommentValue] = useState("");
  const [amountValue,setAmountValue] = useState("");
  const [currentReason,setCurrentReason] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [currentDate, setCurrentDate] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [amountError, setAmmountError] = useState(false)
  const [showLoaderRefund, setShowLoaderRefund] = useState(false);
  const [isSucessRefund, setSucessRefund] = useState(false)

  const refundReasonOptions = [
    'B2C wrong collection',
    'Changing billing plan (Yearly To Monthly)',
    'Double collection by customer',
    'Double collection by billing batch',
    'Collection after cancellation',
    'Customer Care Refund (Manager approval)',
    'Service not working (Devices not supported)',
    'Wrong charge (Offer not reflected / applied)',
  ];
 
  useEffect(() => {
    const today = new Date();
    const hiddenDate = today.toJSON().slice(0,10) + ' ' + today.toTimeString().split(' ')[0]
    setCurrentDate(hiddenDate)
  },[]);

  useEffect(() => {
    let isValidAmount = false;
    if(parseFloat(maxRefundAmount) < parseFloat(amountValue)) {
      isValidAmount = true;
      setAmmountError(true)
    } else {
      amountError && setAmmountError(false)
    }
    if(amountValue.length && currentReason && !isValidAmount) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
  },[amountValue, currentReason]);

  const handleReasonChange = (e)=>{
    setCurrentReason(e.target.value);
  }
  const handleAmountChange = (e)=>{
    setAmountValue(e.target.value);
  }

  const submitRefundForm = () => {
    const obj = {
      "refundDate": currentDate,
      "refundAmount": amountValue,
      "refundReason": currentReason,
      "comment": commentValue,
      "paymentId": paymentId
    }
    setShowLoaderRefund(true);
    submitRefund(obj).then((response)=> {
      setShowLoaderRefund(false);
      if(response.responseCode === 0) {
        refundResponsMessage = "Success";
      } else {
        refundResponsMessage = "Failed";
      }
      setSucessRefund(true);
      
    }).catch(e=>{
      setShowLoaderRefund(false);
      console.log("error>>>", e)
    });
  }
  const handleCloseWithRedirect = () => {
    handleClose();
    if(refundResponsMessage == "Success") {
      afterRefundSuccessHandler()
    }
  }
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown className={classes.modifyPopup} fullWidth={true} maxWidth='sm' minWidth='sm' open={openDialog} onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle className={classes.modifyPopupTitle} id="max-width-dialog-title">{refundedData ? 'Refund Details' : 'Refund Initiation Form'}</DialogTitle>
      {refundedData ?
        <>
          <DialogContent className={classes.modifyPopupContent}>
            
            {`Refund Date : ${refundedData.refundDate}`}
            <br/>
            {`Refund Amount : ${refundedData.refundAmount}`}
            <br/>
            {`Refund transaction ID : ${refundedData.refundTransactionID}`}
            <br/>
            {`Refund processed by : ${refundedData.refundProcessedBy}`}
            <br/>
            {`Refund Reason : ${refundedData.refundReason}`}
            <br/>
            {`Status : ${refundedData.refundStatus}`}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose} >Close</Button>
          </DialogActions>
          </>
        :
        <>
          {showLoaderRefund && <Loader loaderText=""/>}

          {isSucessRefund ? 
            <>
              <DialogContent className={classes.modifyPopupContent}>
                  {refundResponsMessage === "Success" ? <span style={{color:"green"}}>Refund Initiated Successfully!</span> : <span style={{color:"red"}}>Refund Initiation Failed!</span>}
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={handleCloseWithRedirect} >Close</Button>
              </DialogActions>
            </>
            :
            <>
            <DialogContent className={classes.modifyPopupContent}>
                <Box className='row-wise chargeAmount-box'>
                  <strong>Charged Amount</strong> <span>{maxRefundAmount}</span>
                </Box>
                <Box className='row-wise amount-box'>
                  <strong>Refund Amount<sup>*</sup></strong>
                  <TextField
                    placeholder="amount"
                    required={true}
                    value={amountValue}
                    type="number"
                    onChange={(e) => handleAmountChange(e)} 
                  />
                  {amountError && <span className="error">Amount should be less than or equal to {maxRefundAmount}</span>}
                </Box>
                <Box className='row-wise reason-box'>
                  <strong>Refund Reason<sup>*</sup></strong>
                  <Select
                    labelId="status-select-label"
                    id="demo-simple-select"
                    value={currentReason}
                    displayEmpty
                    label="Status"
                    onChange={handleReasonChange}
                  >
                    <MenuItem value="">Choose your reason</MenuItem>
                    {refundReasonOptions.map((reason, index) => {
                      return <MenuItem key={index} value={reason}>{reason}</MenuItem>
                    })}
                  </Select>
                  {reasonError && <span className="error">Please select any reason</span>}
                </Box>
                <Box className='row-wise comment-box'>
                  <strong>Comments</strong>
                  <TextField
                    placeholder="300 Limits"
                    multiline
                    rows={3}
                    maxRows={3}
                    inputProps={{ maxLength: 300 }}
                    required={true}
                    value={commentValue}
                    onChange={(e) => setCommentValue(e.target.value)} 
                  />
                </Box>
            
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClose} >Cancel</Button>
              <Button variant="contained" disabled={btnDisabled} onClick={submitRefundForm} color="secondary">Submit</Button>
            </DialogActions>
          </>
          }
          
          
        </>
        }
    </Dialog>  
  )

}
export default RefundCustomerDialog;
