import React from 'react';
import PropType from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';

const BootstrapInput = withStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    // backgroundColor: theme.palette.input.main,
    fontSize: 16,
    color: '#fff',
    padding: 10,
  },
}))(InputBase);

const BootstrapInput1 = withStyles((theme) => ({
  root: {
    width: '20%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    // backgroundColor: theme.palette.input.main,
    color: '#fff',
    fontSize: 16,
    textAlign:'center',
    verticalAlign:'middle'
 
  },
}))(InputBase);

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: 4,
    // background: theme.palette.input.main,
    border: '1px solid #a0a0a0',
  },
  divider: {
    margin: '6px 0',
  },
  line: {
    '&::after': {
      content: '""',
      height: '80%',
      width: 2,
      top: 0,
      right: 0,
      backgroundColor: '#a0a0a0',
    }
  },
  edit: {
    color: '#ff0000',
    cursor: 'pointer'
  }
}));

const Input = ({countryCode, placeHolder, onChange, value, disabledInput = false, isEdit = false, onEditClick, disableCountryCode = false, id = 0}) => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.root} mt={1} mb={1} dir='ltr'>
      {disableCountryCode === false && (<BootstrapInput1 
        disabled 
        className={classes.line} 
        defaultValue={countryCode}
        inputProps={{ maxLength: 3, style: {direction: 'ltr'} }}
      />)}
      <BootstrapInput
        name='mobileInput'
        placeholder={placeHolder}
        onChange={onChange}
        value={value}
        disabled={disabledInput}
        id={`mobile-input-${id}`}
        endAdornment={isEdit ? <InputAdornment position="start">
          <span className={classes.edit} onClick={onEditClick}>Edit</span>
      </InputAdornment> : null}
        inputProps={{
          pattern: "[0-9]*",
          inputMode: "numeric"
        }}
      />
    </Box>
  );
}

Input.propTypes = {
  countryCode: PropType.string,
  placeHolder: PropType.string,
  onChange: PropType.func,
  value: PropType.string,
  disabledInput: PropType.bool,
  isEdit: PropType.bool,
  onEditClick: PropType.func,
  disableCountryCode: PropType.bool,
};

export default Input;