import React, { memo } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import PageNotFound from "./containers/PageNotFound";
import ULP from "./containers/ULP/Loadable";
import SelectPackageZain from "./containers/KwZain/SelectPackage/SelectPackagePage";
import Welcome, { GenericWelcome } from "./containers/Welcome/Loadable";
import Marketing from "./containers/Home/MarketingHome";
import OtpVerificationZain from "./containers/KwZain/OtpVerification/Loadable";
import ThankyouKwZain from "./containers/KwZain/ThankYou";
import Error from "./containers/CommonPages/Error";
import SubscriptionError from "./containers/CommonPages/SubscriptionError";
import Timeout from "./containers/CommonPages/Timeout";
import InsufficientBalance from "./containers/CommonPages/InsufficentBalance";
import InvalidMsisdn from "./containers/CommonPages/InvalidMsisdn";
import Common from "./Layouts/Common";
import Zain from "./Layouts/Zain";
import UlpHeader from "./Layouts/Ulp";
import Home from "./containers/Home/HomePage";
import SelectPackageZainSa from "./containers/SaZain/SelectPackage";
import OTPZainSa from "./containers/SaZain/OtpVerifcation";
import ThankYouSaZain from "./containers/SaZain/ThankYou";
import SelectPackagekwStc from "./containers/KwStc/SelectPackage/Loadable";
import OtpVerificationkwStc from "./containers/KwStc/OtpVerification/Loadable";
import SelectPackageIqZain from "./containers/IqZain/SelectPackage/Loadable";
import OtpVerificationIqZain from "./containers/IqZain/OtpVerification/Loadable";
import SelectPackagekwOoredoo from "./containers/KwOoredoo/SelectPackage/Loadable";
import OtpVerificationkwOoredoo from "./containers/KwOoredoo/OtpVerification/Loadable";
import SelectPackageZainBh from "./containers/BhZain/SelectPackage/Loadable";
import OTPZainBh from "./containers/BhZain/OtpVerification/Loadable";
import SelectPackageQaOoredoo from "./containers/QaOoredoo/SelectPackage/Loadable";
import OtpVerificationQaOoredoo from "./containers/QaOoredoo/OtpVerification/Loadable";
import SelectPackageJoOrange from "./containers/JoOrange/SelectPackage/Loadable";
import OtpVerificationJoOrange from "./containers/JoOrange/OtpVerification/Loadable";
import SelectPackageMobilySa from "./containers/SaMobily/SelectPackage/Loadable";
import OTPMobilySa from "./containers/SaMobily/OtpVerification/Loadable";
import SelectPackageMaOrange from "./containers/MaOrange/SelectPackage/Loadable";
import OTPMaOrange from "./containers/MaOrange/OtpVerification/Loadable";
import SelectPackage from "./containers/STC/SelectPackage/Loadable";
// import OtpVerification from "./containers/STC/OtpVerification/Loadable";
import SelectPackageIamMa from "./containers/MaIam/SelectPackage/Loadable";
// import OTPIamMa from "./containers/MaIam/OtpVerification/Loadable";
import SelectPackageRedbullmobileSa from "./containers/SaRedbullmobile/SelectPackage/Loadable";
// import OTPRedbullmobileSa from "./containers/SaRedbullmobile/OtpVerification/Loadable";
import SelectPackageSaLebara from "./containers/SaLebara/SelectPackage/Loadable";
// import OTPSaLebara from "./containers/SaLebara/OtpVerification/Loadable";

import Login from "./containers/CustomerCare/Login";
import SearchCustomer from "./containers/CustomerCare/SearchCustomer";
import RetryEngineDashboard from "./containers/RetryEngine/RetryEngineDashboard";
import RetryEngineSummary from "./containers/RetryEngine/RetryEngineSummary";

import CustomerAuth from "./utils/CustomerAuth";
import UserNotEligible from "./containers/CommonPages/UserNotEligible";

let telcoNav = [];
let genericNav = [];
telcoNav = [
  {
    path: "sa/stc",
    element: <SelectPackage />,
  },
  // {
  //   path: "sa/stc/otp-verification",
  //   element: <OtpVerification />,
  // },
  {
    path: "kw/zain",
    element: <SelectPackageZain />,
  },
  {
    path: "kw/zain/otp-verification",
    element: <OtpVerificationZain />,
  }, //// welcome pages
  // {
  //   path: "kw/stc/welcome",
  //   element: <GenericWelcome />,
  // },
  {
    path: "kw/ooredoo/welcome",
    element: <GenericWelcome />,
  },
  {
    path: "qa/ooredoo/welcome",
    element: <GenericWelcome />,
  },
  {
    path: "kw/zain/welcome",
    element: <ThankyouKwZain />,
  },
  // {
  //   path: "iq/zain/welcome",
  //   element: <GenericWelcome />,
  // },
  // {
  //   path: "sa/stc/welcome",
  //   element: <GenericWelcome />,
  // },
  // {
  //   path: "sa/lebara/welcome",
  //   element: <GenericWelcome />,
  // },
  {
    path: "sa/zain/welcome",
    element: <ThankYouSaZain />,
  },
  // {
  //   path: "bh/zain/welcome",
  //   element: <GenericWelcome />,
  // },
  // {
  //   path: "ma/iam/welcome",
  //   element: <GenericWelcome />,
  // },
  // {
  //   path: "jo/orange/welcome",
  //   element: <GenericWelcome />,
  // },
  // {
  //   path: "sa/mobily/welcome",
  //   element: <GenericWelcome />,
  // },
  {
    path: "ma/orange/welcome",
    element: <GenericWelcome />,
  },
  // {
  //   path: "sa/redbullmobile/welcome",
  //   element: <GenericWelcome />,
  // },

  {
    path: ":countryCode/:telco/subscription-error",
    element: <SubscriptionError />,
  },
  {
    path: ":countryCode/:telco/error",
    element: <Error />,
  },
  {
    path: ":countryCode/:telco/timeout",
    element: <Timeout />,
  },
  {
    path: ":countryCode/:telco/insufficientBalance",
    element: <InsufficientBalance />,
  },
  {
    path: ":countryCode/:telco/userNotEligible",
    element: <UserNotEligible />,
  },
  {
    path: ":countryCode/:telco/invalid-msisdn",
    element: <InvalidMsisdn />,
  },
  {
    path: "sa/zain/home",
    element: <Home />,
  },
  {
    path: "sa/zain/marketing",
    element: <Marketing />,
  },
  {
    path: "sa/zain",
    element: <SelectPackageZainSa />,
  },
  {
    path: "sa/zain/otp-verification",
    element: <OTPZainSa />,
  },
  {
    path: "kw/stc",
    element: <SelectPackagekwStc />,
  },
  // {
  //   path: "kw/stc/otp-verification",
  //   element: <OtpVerificationkwStc />,
  // },
  {
    path: "iq/zain",
    element: <SelectPackageIqZain />,
  },
  // {
  //   path: "iq/zain/otp-verification",
  //   element: <OtpVerificationIqZain />,
  // },
  {
    path: "kw/ooredoo",
    element: <SelectPackagekwOoredoo />,
  },
  {
    path: "kw/ooredoo/otp-verification",
    element: <OtpVerificationkwOoredoo />,
  },
  {
    path: "bh/zain",
    element: <SelectPackageZainBh />,
  },
  // {
  //   path: "bh/zain/otp-verification",
  //   element: <OTPZainBh />,
  // },
  {
    path: "qa/ooredoo",
    element: <SelectPackageQaOoredoo />,
  },
  {
    path: "qa/ooredoo/otp-verification",
    element: <OtpVerificationQaOoredoo />,
  },
  {
    path: "ma/iam",
    element: <SelectPackageIamMa />,
  },
  // {
  //   path: "ma/iam/otp-verification",
  //   element: <OTPIamMa />,
  // },
  {
    path: "jo/orange",
    element: <SelectPackageJoOrange />,
  },
  // {
  //   path: "jo/orange/otp-verification",
  //   element: <OtpVerificationJoOrange />,
  // },
  {
    path: "sa/mobily",
    element: <SelectPackageMobilySa />,
  },
  // {
  //   path: "sa/mobily/otp-verification",
  //   element: <OTPMobilySa />,
  // },
  {
    path: "ma/orange",
    element: <SelectPackageMaOrange />,
  },
  {
    path: "ma/orange/otp-verification",
    element: <OTPMaOrange />,
  },
  {
    path: "sa/redbullmobile",
    element: <SelectPackageRedbullmobileSa />,
  },
  // {
  //   path: "sa/redbullmobile/otp-verification",
  //   element: <OTPRedbullmobileSa />,
  // },
  {
    path: "sa/lebara",
    element: <SelectPackageSaLebara />,
  },
  // {
  //   path: "sa/lebara/otp-verification",
  //   element: <OTPSaLebara />,
  // },
];

genericNav = [
  {
    path: "welcome",
    element: <Welcome />,
  },
  {
    path: "telco",
    element: <ULP />,
  },
  {
    path: "marketing",
    element: <Marketing />,
  },
];

const ulp = [
  {
    path: "/",
    element: <Navigate to="/partner" />,
  },
  {
    path: "partner",
    element: <ULP />,
  },
];

if (process.env.ENV === "production") {
  telcoNav = [
    // SA ZAIN
    {
      path: "sa/zain",
      element: <SelectPackageZainSa />,
    },
    {
      path: "sa/zain/otp-verification",
      element: <OTPZainSa />,
    },
    {
      path: "sa/zain/welcome",
      element: <ThankYouSaZain />,
    },
    // END
    // {
    //   path: "kw/stc/welcome",
    //   element: <GenericWelcome />,
    // },
    {
      path: ":countryCode/:telco/subscription-error",
      element: <SubscriptionError />,
    },
    {
      path: ":countryCode/:telco/error",
      element: <Error />,
    },
    {
      path: ":countryCode/:telco/timeout",
      element: <Timeout />,
    },
    {
      path: ":countryCode/:telco/insufficientBalance",
      element: <InsufficientBalance />,
    },
    {
      path: ":countryCode/:telco/userNotEligible",
      element: <UserNotEligible />,
    },
    {
      path: ":countryCode/:telco/invalid-msisdn",
      element: <InvalidMsisdn />,
    },
    {
      path: "kw/stc",
      element: <SelectPackagekwStc />,
    },
    // {
    //   path: "kw/stc/otp-verification",
    //   element: <OtpVerificationkwStc />,
    // },
    {
      path: "kw/zain",
      element: <SelectPackageZain />,
    },
    {
      path: "kw/zain/otp-verification",
      element: <OtpVerificationZain />,
    },
    {
      path: "kw/zain/welcome",
      element: <ThankyouKwZain />,
    },
    // {
    //   path: "iq/zain/welcome",
    //   element: <GenericWelcome />,
    // },
    {
      path: "iq/zain",
      element: <SelectPackageIqZain />,
    },
    // {
    //   path: "iq/zain/otp-verification",
    //   element: <OtpVerificationIqZain />,
    // },
    {
      path: "kw/ooredoo/welcome",
      element: <GenericWelcome />,
    },
    {
      path: "kw/ooredoo",
      element: <SelectPackagekwOoredoo />,
    },
    {
      path: "kw/ooredoo/otp-verification",
      element: <OtpVerificationkwOoredoo />,
    },
    // {
    //   path: "sa/stc/welcome",
    //   element: <GenericWelcome />,
    // },
    {
      path: "sa/stc",
      element: <SelectPackage />,
    },
    // {
    //   path: "sa/stc/otp-verification",
    //   element: <OtpVerification />,
    // },
    // {
    //   path: "bh/zain/welcome",
    //   element: <GenericWelcome />,
    // },
    {
      path: "bh/zain",
      element: <SelectPackageZainBh />,
    },
    // {
    //   path: "bh/zain/otp-verification",
    //   element: <OTPZainBh />,
    // },
    // {
    //   path: "ma/iam/welcome",
    //   element: <GenericWelcome />,
    // },
    {
      path: "ma/iam",
      element: <SelectPackageIamMa />,
    },
    // {
    //   path: "ma/iam/otp-verification",
    //   element: <OTPIamMa />,
    // },
    // {
    //   path: "jo/orange/welcome",
    //   element: <GenericWelcome />,
    // },
    {
      path: "jo/orange",
      element: <SelectPackageJoOrange />,
    },
    // {
    //   path: "jo/orange/otp-verification",
    //   element: <OtpVerificationJoOrange />,
    // },
    // {
    //   path: "sa/mobily/welcome",
    //   element: <GenericWelcome />,
    // },
    {
      path: "sa/mobily",
      element: <SelectPackageMobilySa />,
    },
    // {
    //   path: "sa/mobily/otp-verification",
    //   element: <OTPMobilySa />,
    // },
    {
      path: "qa/ooredoo/welcome",
      element: <GenericWelcome />,
    },
    {
      path: "qa/ooredoo",
      element: <SelectPackageQaOoredoo />,
    },
    {
      path: "qa/ooredoo/otp-verification",
      element: <OtpVerificationQaOoredoo />,
    },
    {
      path: "ma/orange/welcome",
      element: <GenericWelcome />,
    },
    {
      path: "ma/orange",
      element: <SelectPackageMaOrange />,
    },
    {
      path: "ma/orange/otp-verification",
      element: <OTPMaOrange />,
    },
    // {
    //   path: "sa/redbullmobile/welcome",
    //   element: <GenericWelcome />,
    // },
    {
      path: "sa/redbullmobile",
      element: <SelectPackageRedbullmobileSa />,
    },
    // {
    //   path: "sa/redbullmobile/otp-verification",
    //   element: <OTPRedbullmobileSa />,
    // },
    {
      path: "sa/lebara",
      element: <SelectPackageSaLebara />,
    },
    // {
    //   path: "sa/lebara/otp-verification",
    //   element: <OTPSaLebara />,
    // },
    // {
    //   path: "sa/lebara/welcome",
    //   element: <GenericWelcome />,
    // },
  ];

  genericNav = [
    {
      path: "welcome",
      element: <Welcome />,
    },
  ];
}

const AppRoutes = [
  {
    path: "",
    element: <UlpHeader />,
    children: [...ulp],
  },
  {
    path: "",
    element: <Common />,
    children: [...telcoNav],
  },
  {
    path: "",
    element: <Zain />,
    children: [...genericNav],
  },
];

const Route = () => {
  const element = useRoutes([
    ...AppRoutes,
    { path: "customercare", element: <Login from="customercare"></Login> },
    {
      path: "search",
      element: (
        <CustomerAuth from="customercare">
          <SearchCustomer />
        </CustomerAuth>
      ),
    },
    { path: "retryEngine", element: <Login from="retryEngine"></Login> },
    {
      path: "redashboard",
      element: (
        <CustomerAuth from="retryEngine">
          <RetryEngineDashboard />
        </CustomerAuth>
      ),
    },
    {
      path: "resummary",
      element: (
        <CustomerAuth from="retryEngine">
          <RetryEngineSummary />
        </CustomerAuth>
      ),
    },
    { path: "*", element: <PageNotFound /> },
  ]);

  return element;
};

export default memo(Route);
