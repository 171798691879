  
import thunk from 'redux-thunk'
import { applyMiddleware } from 'redux'
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";

const logger = (store) => (next) => (action) => {
    console.group(action.type)
    console.log('The action: ', action)
    const result = next(action)
    console.log('The new state: ', store.getState())
    console.groupEnd()
    return result
}
  
export default applyMiddleware(
  thunk,
  logger,
  reduxImmutableStateInvariant(),
)