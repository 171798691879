import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography, InputAdornment, TextField, Button } from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  imageWrapper: {
    position: 'relative',
    marginBottom: '1rem',
  },
  imageBackground: {
    position: 'relative',
    height: 420,
    [theme.breakpoints.down("sm")]: {
      height: 250,
    },
    [theme.breakpoints.up("sm")]: {
      height: 440,
    },
    [theme.breakpoints.up("md")]: {
      height: 450,
    }
  },
  overlayLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'middle',
  },
  whiteGradient: {
    height: '50%',
    position: 'absolute',
    left: 0,
    bottom: -1,
    width: '100%',
    background: 'linear-gradient(0deg, #fff 0, #fff 2rem, rgba(250, 250, 250, 0.0001) 100%)',
  },
  blackGradient: {
    height: '30%',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0, rgba(0, 0, 0, 0.0001) 100%)',
  },
  heading: {
    bottom: '1rem',
    margin: '0 1rem',
  },
  contentWrapper: {
    padding: '0 20px',
    alignItems: 'center',
  },
  input: {
    height: 44,
    paddingRight: 5,
    maxWidth: 500,
    '& input': {
      height: 7,
    },
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
    },
  },
  formWrapper: {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column'
    }
  },
  btn: {
    width: 200,
    height: 44,
    display: 'inline-flex',
    borderRadius: 8,
    boxShadow: '0 16px 12px -16px rgb(255 0 0 / 70%)',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      marginTop: 20,
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
    },
  },
  inputLable: {
    fontSize: 18,
  }
}));

const MarketingHome = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [val, setVal] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleInput = e => {
    setVal(e.target.value);
  }

  return(
    <Box className={classes.wrapper}>
     <Grid container className={classes.imageWrapper} direction="column">
       <Box className={classes.imageBackground}>
         <Box className={classes.overlayLayer}>
           {isMobile ? <img className={classes.image} alt="page-header" src="/assets/img/zain-banner-mobile.png"/> :
            <img className={classes.image} alt="page-header" src="/assets/img/zain-banner-web.png"/>}
         </Box>
         <Box className={classes.whiteGradient} />
         <Box className={classes.blackGradient} />
       </Box>
        <Typography className={classes.heading} variant="h1" align="center">UNLIMITED MOVIES, ENTERTAINMENT, TV SHOWS & MORE</Typography>
      </Grid>
      <Grid container className={classes.contentWrapper} direction="column">
        <Typography variant="h2" className={classes.inputLable}>Please enter your email address</Typography>
        <Box mt={2} className={classes.formWrapper}>
        <TextField
            id="input-email"
            name="email"
            color="secondary"
            classes={{root: classes.input}}
            variant="outlined"
            placeholder="Email Address"
            fullWidth
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src="/assets/img/icon-form-user.png" />
                </InputAdornment>
              ),
            }}
          />
            <Button
            variant="contained"
            color="secondary"
            fullWidth
            component={Link}
            className={classes.btn}
            to={`/home?q=${val}`}
          >
            <Typography variant="button">Get started</Typography>
          </Button>
          </Box>
      </Grid>
    </Box>
  )
}

export default MarketingHome;