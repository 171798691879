import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => {
  return ({
    footer: {
      fontFamily: 'proxima-nova'
    },
    footerTop: {
      backgroundColor: '#0f0125',
      padding: '40px 100px',
      width: '100%',
      overflow: 'hidden',
      [theme.breakpoints.down("sm")]: {
        padding: '32px 20px',
      },
    },
    tagline: {
      textAlign: 'left',
      fontSize: 24,
      fontFamily: props => props.lng !== 'ar' ? 'proxima-nova' : 'FrutigerLTArabicRoman',
      fontWeight: 700,
      lineHeight: 1.2,      
      [theme.breakpoints.up("md")]: {
        width: '40%',
        float: 'left',
      },
    },
    supportedDevice: {
      [theme.breakpoints.up("md")]: {
        width: '50%',
        float: 'right',
        position: 'relative',
        marginTop: -8,
      },
      '& img': {
        maxWidth: '100%'
      }
    },
    footerBottom: {
      padding: '35px 100px',
      position: 'relative',
      [theme.breakpoints.down("sm")]: {
        padding: '35px 20px',
        textAlign: 'center'
      },
  
    },
    footerMenu : {
      textAlign: 'center',    
      [theme.breakpoints.down("sm")]: {
        
      },
      [theme.breakpoints.up("sm")]: {
        
      },
      [theme.breakpoints.up("md")]: {
        width: '50%',
        float: 'left',
      },
      [theme.breakpoints.up("lg")]: {
      
      },
    },
    
    storeIcons: {      
      [theme.breakpoints.down("sm")]: {
        '& a': {
          margin: '0 5px',
        },
      },
      [theme.breakpoints.up("md")]: {
        width: '40%',
        float: 'right',
        textAlign: 'right',  
        '& a': {
          marginLeft: 10,
        },    
      },
    },
    
    list : {    
      margin: 0,
      padding: 0,
      listStyle: 'none',
      fontFamily: props => props.lng !== 'ar' ? 'proxima-nova' : 'FrutigerLTArabicRoman',
      '& li' : {
        display: 'inline-block',
      },
      [theme.breakpoints.down("sm")]: {
        '& li' : {
          margin: '0 20px'
        },
      },
      [theme.breakpoints.down("xs")]: {
        '& li' : {
          margin: '0 16px'
        },
      },
      [theme.breakpoints.up("md")]: {
        textAlign: 'left',
        '& li' : {
          marginRight: 30
        },
      },
    },
    socialLinkWrap : {
      margin: '16px 0 40px 0',
      textAlign: 'center',
      [theme.breakpoints.up("md")]: {
        position: 'absolute',
        left: 100,
        right: 100,
        top: 100,
      },
    },
    socialLink : {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      '& li': {
        display: 'inline-block',
        margin: '0 15px',
        overflow: 'hidden'
      },
      [theme.breakpoints.down("xs")]: {
        '& li': {
          margin: '0 14px',
        },
      },
      '& li a': {
        display: 'block',
        width: 32,
        height:32,
        textIndent: props => props.lng !== 'ar' ? -99999 : 99999,
        backgroundImage: 'url("/assets/img/social-icons.png")',
        backgroundRepeat: 'no-repeat'
      },
      '& li .fb': {
        backgroundPosition: '0 0'
      },
      '& li .tw': {
        backgroundPosition: '-72px 0'
      },
      '& li .yt': {
        backgroundPosition: '-144px 0'
      },
      '& li .insta': {
        backgroundPosition: '-216px 0'
      },
      '& li .tiktok': {
        backgroundPosition: '-288px 0'
      },
    },
    copyRight : {
      textAlign: "center",
      color: "#938c9d",
      marginTop: 125,
      [theme.breakpoints.down("sm")]: {
        marginTop: 20,
      },
    }    
  })
});