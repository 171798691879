import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: "none",
    background: "#0F0125",
  },
  langMenu: {
    minHeight: 36,
    paddingLeft: 10,
    paddingRight: 10,
  },
  menuButton: {
    minWidth: 74,
    display: "flex",
    justifyContent: "center",
    fontSize: 17,
    color: '#fff',
    fontFamily: props => props.lng !== 'ar-AE' ? 'FrutigerLTArabicRoman' : 'FaricyNewRegular',
  },
  pL: {
    paddingLeft: 10,
  },
  iconColor: {
    color: "#808080",
  },
  ulpHeader: {
    position: 'relative',
  },
  ulpToolBar: {
    justifyContent: 'center',
    paddingLeft: 80,
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 35,
    }
  }
}));