import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

export const initGA = () => {       
  ReactGA.initialize('UA-xxxxxxx-x'); // put your tracking id here
}

export const initGTM = () => {
  const tagManagerArgs = {
    gtmId: process.env.GTM_ID,
  }
  TagManager.initialize(tagManagerArgs)
}

export const Noscript = props => {
  return <noscript dangerouslySetInnerHTML={{ __html: props.children }} />;
};

Noscript.propTypes = {
  children: PropTypes.node
}