import * as types from "./actionTypes";
import { checkEmail } from '../../api/home';
import { getCountryWithIP } from '../../api/packs';

const checkEmailSuceess = (data) => {
  return { type: types.CHECK_EMAIL, data };
}

const getIPSuccess = (data) => {
  return { type: types.GET_IP, data };
}

export function beginApiCall(data) {
  return {
    type: types.BEGIN_API_CALL,
    data,
  };
}

export function apiCallError(error) {
  return { type: types.API_CALL_ERROR, error };
}

export function changeLanguage(data) {
  return (dispatch) => {
    dispatch({ type: types.CHANGE_LNG, data });
  };
}

export const getIpCall = () => {
  return function (dispatch) {
    return getCountryWithIP()
      .then((data) => {
        console.log('Reducer data', data);
        const obj = {
          clientIp: data.headers['client-ip-identified'] || '234.099.99.00',
          country: data?.data?.defaultSettings?.countryCode || 'NO',
        }
        dispatch(getIPSuccess(obj));
        return data;
      })
      .catch((error) => {
        console.log('error ======>', error);
        dispatch(getIPSuccess({clientIp: '234.099.99.00', country: 'NO'}));
      });
  };
}

export const checkEmailAction = email => {
  return function (dispatch) {
    dispatch(
      beginApiCall({
        showLoader: true,
        loaderText: "Checking email",
      })
    );
    return checkEmail(email)
      .then((data) => {
        dispatch(checkEmailSuceess(data));
        return data;
      })
      .catch((error) => {
        console.log('error ======>', error);
      });
  };
}
