import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import validator from "validator";
import qs from "qs";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import Package from "../../../components/Package/ZainPackage";
import Input from "../../../components/Input";
import PrivacyText from "../../../components/PrivacyText";
import {
  ZAIN_KSA_DEFAULT_PACKAGES,
  ZAIN_KSA_PACKAGES,
  ZAIN_KSA_DCB_OFFERS,
} from "../../../constants/packages";
import {
  getProductsQueryParams,
  sendOtp,
  subscribed,
} from "../../../api/packs";
import { validateIP } from "../../../api/home";
import { sendEvent } from "../../../api/events";
import {
  SUCCESS,
  CUSTOMER_ALREADY_EXIST,
  INVALID_MOBILE_NUMBER,
  TIMEOUT,
  CUSTOMER_DOSE_NOT_EXIST,
} from "../../../constants/sdpCodes";
import {
  extractUrl,
  constructUtmParams,
  gaDataLayer,
  freeTrialCyclePeriod,
} from "../../../utils/common";
import {
  cEventOnSubscribeNowAuthJourney,
  cEventOnTelcoPageView,
  cEventOnUserLogin,
  cEventOnVerifyOtpAuthJourney,
  cEventOnVerifyOtpFailedAuthJourney,
} from "../../../utils/clevertap";
import { ksaZainURL } from "../../../constants/urls";
import {
  SENDOTP_SUCCESS,
  SignUpButtonClicks,
  SignUpButtonFails,
  MOBILE_ERROR,
  CUSTOMER_EXIST,
  SENDOTP_ERROR,
  LANDED,
  PaymentSuccess,
  TelcoJournyStart,
  PaymentFails,
  TIMEOUT_HIT,
} from "../../../constants/events";

const page = "sa-zain-pack-selection";
const telcoParams = { telcoName: "zain", country: "sa" };
const mobilecode = "966";
let isHeFlow = false;

const useStyles = makeStyles(() => ({
  incorrectMsg: {
    fontSize: 10,
    letterSpacing: "0.25px",
    color: "#ff0000",
    fontWeight: 600,
    marginTop: 5,
    marginBottom: 5,
  },
  heading: {
    "& span": {
      color: "#95c11f",
    },
  },
  promoTitle: {
    color: "#ff00d3",
  },
}));

const SelectPackage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  let navigate = useNavigate();
  const [isValidate, setIsValidate] = useState(false);
  const { telcoName, countryCode } = extractUrl(location.pathname);
  const [defaultPackage, setDefaultPackage] = useState(null);
  const [zainPackage, setZainPackage] = useState("");
  const [inputVal, setInputVal] = useState("");
  const [loaderText, setLoaderText] = useState("");
  const [inputValMob, setInputValMob] = useState("");
  const [loader, setLoader] = useState(false);
  const ipAddress = useSelector((state) => state?.app?.clientIp);
  const lng = useSelector((state) => state?.app?.lng);
  const [heFiberFlow, setHeFiberFlow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showMsg, setShowMsg] = useState({ toShow: false, msg: "" });
  const qsParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const locale = qsParams?.locale ?? "en-US";

  const ksaZainURLs = ksaZainURL(locale);

  useEffect(() => {
    window.location.href = locale === "ar-AE" ? "https://osnplus.com/ar-sa/operators/zainksa" : "https://osnplus.com/en-sa/operators/zainksa";
    sendEvent(page, LANDED);
    getHttpHeaders();
    if (process.env.ENV !== "production") {
      if (qsParams.isHE === "true") {
        isHeFlow = true;
        getProduct(qsParams.mobileNumber);
        sendEvent(page, `HE${LANDED}`);
      }
    }
    cEventOnTelcoPageView(
      countryCode,
      telcoName,
      window.location.href,
      "Telco Landing Page"
    );
  }, []);

  const getHttpHeaders = () => {
    const req = new XMLHttpRequest();
    req.open("GET", document.location, false);
    req.send(null);
    const msisdn = req.getResponseHeader("msisdn") || "";
    const tmsisdn = msisdn.trim();
    const headers = req.getAllResponseHeaders().toLowerCase();
    sendEvent(page, "HEADERS", JSON.stringify(headers));
    sendEvent(page, "HE MSISDN", msisdn);
    if (tmsisdn && tmsisdn != null) {
      isHeFlow = true;
      getProduct(tmsisdn);
      sendEvent(page, `HE${LANDED}`);
    } else {
      sendEvent(page, `NONHE${LANDED}`);
      isHeFlow = false;
      setDefaultPackage("VOICE");
      setTimeout(
        () => gaDataLayer({ ...telcoParams, connection: "nonhe" }),
        10
      );
    }
  };

  const getProduct = async (number) => {
    setLoader(true);
    setInputVal(number.slice(3));
    setLoaderText(t("OTT_TLP_getProduct_Loader_Msg"));
    try {
      const product = await getProductsQueryParams(
        telcoName,
        countryCode,
        number
      );
      const offer = product.productDetails[0];
      setDefaultPackage(product.customerType);
      const isFreeTrial =
        offer?.subscriptionTypeId === "1000034651" ||
        offer?.subscriptionTypeId === "1000034649"
          ? false
          : offer?.freeTrialCyclePeriod
          ? true
          : false;
      const trialPeriod = freeTrialCyclePeriod(
        offer?.freeTrialCyclePeriod,
        offer?.freeTrialCycleNumber
      );
      if (product.customerType === "VOICE" || product.customerStatus) {
        setIsValidate(true);
      }
      product.customerType === "VOICE"
        ? sendEvent(page, `HE${LANDED}VOICE`)
        : sendEvent(page, `HE${LANDED}MBB`);
      setZainPackage(product);
      setHeFiberFlow(true);
      setLoader(false);
      gaDataLayer({
        ...telcoParams,
        connection: `he-${product.customerType.toLowerCase()}`,
        customerStatus: product.customerStatus,
        type: getType(offer.subscriptionTypeId),
        offer,
        isFreeTrial,
        trialPeriod,
        eventParams: TelcoJournyStart,
      });
    } catch (err) {
      sendEvent(page, `Error in fetching offers ${number}`);
      navigate(ksaZainURLs.error);
    }
  };

  const choosePackage = (pack) => {
    setInputVal("");
    setDefaultPackage(pack);
    if (!isHeFlow && pack === "MBB") {
      sendEvent(page, "MBB_TILE_CLICKED");
      setShowMsg({
        toShow: true,
        msg: "OTT_TLP_selectPackage_zainksa_mbbWifiText",
      });
    } else if (pack === "FIBER") {
      sendEvent(page, "FIBER_TILE_CLICKED");
      if (qsParams.isFiber !== "skip") {
        checkIpRange();
      }
    } else {
      sendEvent(page, "VOICE_TILE_CLICKED");
      setShowMsg({ toShow: false, msg: "" });
    }
    setIsValidate(false);
  };

  const checkIpRange = async () => {
    try {
      const check = await validateIP(ipAddress);
      const ipCheck = check.isValidIP;
      sendEvent(
        page,
        `${
          ipCheck ? "IP-RANGE-VALIDATED" : "IP-RANGE-NOVALIDATED"
        } ${ipAddress}`
      );
      ipCheck
        ? setShowMsg({ toShow: false, msg: "" })
        : setShowMsg({
            toShow: true,
            msg: "OTT_TLP_selectPackage_zainksa_fiberWifiText",
          });
    } catch (err) {
      setShowMsg({ toShow: false, msg: "" });
    }
  };

  const subscribeCustomer = (
    customerId,
    zainSelectedPackage,
    mobileNumber,
    customerStatus,
    type
  ) => {
    let customerIdCode = customerId;
    if (type === "MBB") {
      customerIdCode = `${mobilecode}${customerId}`;
    }
    const params = {
      telcoName: "zain",
      countryName: "sa",
      offerCode: zainSelectedPackage.subscriptionTypeId,
      type: "Subscribe",
      tid: Date.now(),
      customerNumber: customerIdCode,
      mobileNumber: mobileNumber || null,
    };
    sendEvent(page, `SUBSCRIBER_API_CALLED_${type}`);
    setLoader(true);
    setLoaderText(t("OTT_TLP_OTP_verifyOtp_loader_msg"));
    const subscriptionPlan = zainSelectedPackage.offerName;
    subscribed(params)
      .then((data) => {
        // const data = {
        //   osnGatewayCustomerId: "f0c9f912-1830-41cd-b7e1-16b97778f89b",
        //   subscriptionGroupId: "29a1297b-da04-41f2-8720-361efe8fbe25",
        //   responseCode: 0,
        //   messageResponse: "Success",
        //   productOffer: {
        //     productCode: "06",
        //     offerCode: "350901",
        //     offerName: "monthly",
        //     productCategory: "FIBER",
        //     price: 39,
        //     cycleNumber: 1,
        //     freeTrialCycleNumber: 8,
        //     cyclePeriod: "MONTH",
        //     freeTrialCyclePeriod: "DAY",
        //     currency: "SAR",
        //     subscriptionTypeId: "1000034651",
        //   },
        //   isFreeTrial: true,
        //   newCustomer: true,
        // };
        setLoader(false);
        if (data.responseCode === SUCCESS) {
          const isFreeTrial =
            data?.productOffer?.subscriptionTypeId === "1000034651" ||
            data?.productOffer?.subscriptionTypeId === "1000034649"
              ? false
              : data?.isFreeTrial;
          const trialPeriod = isFreeTrial
            ? freeTrialCyclePeriod(
                data?.productOffer?.freeTrialCyclePeriod,
                data?.productOffer?.freeTrialCycleNumber
              )
            : null;

          // success scenario
          sendEvent(page, `SUBSCRIBER_API_SUCCESS_${type}`);
          gaDataLayer({
            ...telcoParams,
            eventParams: PaymentSuccess,
            offer: zainSelectedPackage,
            lng: lng,
            connection: gaHE(isHeFlow),
            type: getType(zainSelectedPackage.subscriptionTypeId),
            isFreeTrial,
            trialPeriod,
            customerStatus: customerStatus,
          });
          cEventOnVerifyOtpAuthJourney("zain", "sa");
          navigate(ksaZainURLs.welcome, {
            state: {
              offer: zainSelectedPackage,
              data: {
                ...data,
                isFreeTrial,
              },
              prevPath: location.pathname,
              type: getType(zainSelectedPackage.subscriptionTypeId),
              isHE: gaHE(isHeFlow),
            },
          });
        } else if (
          data.responseCode === CUSTOMER_ALREADY_EXIST ||
          data.responseCode === 29
        ) {
          // customer already exist
          sendEvent(
            page,
            `${CUSTOMER_EXIST}_SUB_${type}`,
            `Customer Already Exist ${customerId}`
          );
          cEventOnVerifyOtpFailedAuthJourney(
            "zain",
            "sa",
            subscriptionPlan,
            zainSelectedPackage.productType,
            zainSelectedPackage.price,
            zainSelectedPackage.currency,
            data.responseCode,
            "Customer Already Exist"
          );
          navigate(ksaZainURLs.subError, {
            state: {
              prevPath: location?.pathname,
              isHE: gaHE(isHeFlow),
              type: getType(zainSelectedPackage?.subscriptionTypeId),
            },
          });
        } else if (data.responseCode === TIMEOUT) {
          // timeout
          sendEvent(page, `${TIMEOUT_HIT}_SUB_${type}`, `${customerId}`);
          const errorParams = { code: data.responseCode, reason: "Timeout" };
          gaDataLayer({
            ...telcoParams,
            eventParams: PaymentFails,
            errorParams,
            offer: zainSelectedPackage,
            lng: lng,
            connection: gaHE(isHeFlow),
            type: getType(zainSelectedPackage?.subscriptionTypeId),
          });
          cEventOnVerifyOtpFailedAuthJourney(
            "zain",
            "sa",
            subscriptionPlan,
            zainSelectedPackage.productType,
            zainSelectedPackage.price,
            zainSelectedPackage.currency,
            data.responseCode,
            `Time out due to backend error`
          );
          navigate(ksaZainURLs.timeout, {
            state: {
              prevPath: location.pathname,
              isHE: gaHE(isHeFlow),
              offer: zainPackage,
              customerStatus: zainPackage?.customerStatus,
            },
          });
        } else if (data.responseCode === CUSTOMER_DOSE_NOT_EXIST) {
          sendEvent(page, `BAD MSISDN ${type}`, `${customerId}`);
          const errorParams = {
            code: data.responseCode,
            reason: data.messageResponse,
          };
          gaDataLayer({
            ...telcoParams,
            eventParams: PaymentFails,
            errorParams,
            offer: zainSelectedPackage,
            lng: lng,
            connection: gaHE(isHeFlow),
            type: getType(zainSelectedPackage?.subscriptionTypeId),
          });
          cEventOnVerifyOtpFailedAuthJourney(
            "zain",
            "sa",
            subscriptionPlan,
            zainSelectedPackage.productType,
            zainSelectedPackage.price,
            zainSelectedPackage.currency,
            data.responseCode,
            `Customer does not exist`
          );
          setErrorMsg(true);
        } else if (data.responseCode === 52) {
          cEventOnVerifyOtpFailedAuthJourney(
            "zain",
            "sa",
            subscriptionPlan,
            zainSelectedPackage.productType,
            zainSelectedPackage.price,
            zainSelectedPackage.currency,
            data.responseCode,
            data.messageResponse
          );
          navigate(ksaZainURLs.error, {
            state: {
              responseCode: data.responseCode,
              messageResponse: data.messageResponse,
              telcoName: telcoParams.telcoName,
              country: telcoParams.country,
            },
          });
        } else {
          sendEvent(page, `SUBSCRIBER_API_FAILED_${type}`);
          const errorParams = {
            code: data.responseCode,
            reason: "subscriber api failed",
          };
          gaDataLayer({
            ...telcoParams,
            eventParams: PaymentFails,
            errorParams,
            offer: zainSelectedPackage,
            lng: lng,
            connection: gaHE(isHeFlow),
            type: getType(zainSelectedPackage?.subscriptionTypeId),
          });
          cEventOnVerifyOtpFailedAuthJourney(
            "zain",
            "sa",
            subscriptionPlan,
            zainSelectedPackage.productType,
            zainSelectedPackage.price,
            zainSelectedPackage.currency,
            data.responseCode,
            `subscriber api failed`
          );
          navigate(ksaZainURLs.error, {
            state: {
              prevPath: location?.pathname,
              isHE: gaHE(isHeFlow),
              type: getType(zainSelectedPackage?.subscriptionTypeId),
            },
          });
        }
      })
      .catch((err) => {
        navigate(ksaZainURLs.error, {
          state: {
            prevPath: location?.pathname,
            isHE: gaHE(isHeFlow),
            type: getType(zainSelectedPackage?.subscriptionTypeId),
          },
        });
        console.log(err);
      });
  };

  const getType = (offerCode) =>
    ZAIN_KSA_DCB_OFFERS.includes(offerCode) ? "dcb" : "bundle";

  const handleSendOtp = (tid, offer, product, number) => {
    const params = {
      ...constructUtmParams(qsParams),
      customerId: number,
      subscriptionTypeId: offer?.subscriptionTypeId,
      offerCode: offer?.offerCode,
      telcoName,
      countryName: countryCode,
      ipAddress,
      tid,
    };
    sendEvent(page, "total_send_otp_req");
    sendOtp(params)
      .then((data) => {
        // const data = {
        //   responseCode: 0,
        //   messageResponse: "Success",
        //   purchaseId: "d2e84fa8-eb44-41ec-9a2c-4790a32f8537",
        // };
        if (data.responseCode === SUCCESS) {
          const subscriptionPlan = offer.offerName;
          sendEvent(page, SENDOTP_SUCCESS, `OTP send to this number ${number}`);
          cEventOnUserLogin("", "+" + number);
          cEventOnSubscribeNowAuthJourney(
            telcoName,
            countryCode,
            subscriptionPlan,
            offer.productType,
            offer.price,
            offer.currency
          );
          gaDataLayer({
            ...telcoParams,
            eventParams: SignUpButtonClicks,
            offer: offer,
            type: getType(offer?.subscriptionTypeId),
            connection: gaHE(isHeFlow),
            customerStatus: product?.customerStatus,
          });
          navigate(ksaZainURLs.otp, {
            state: {
              purchaseToken: data.purchaseId,
              mobileNumber: number,
              offer,
              isHE: gaHE(isHeFlow),
              tid,
              type: getType(offer?.subscriptionTypeId),
              customerStatus: product?.customerStatus,
              prevPath: `${location?.pathname}/pack`,
            },
          });
        } else if (data.responseCode === CUSTOMER_ALREADY_EXIST) {
          sendEvent(page, CUSTOMER_EXIST, `Customer Already Exisit ${number}`);
          navigate(ksaZainURLs.subError, {
            state: {
              prevPath: location?.pathname,
              isHE: gaHE(isHeFlow),
              type: getType(zainPackage?.customerType),
            },
          });
        } else if (data.responseCode === INVALID_MOBILE_NUMBER) {
          sendEvent(page, MOBILE_ERROR, `Invalid mobile number ${number}`);
          setErrorMsg(true);
          setLoader(false);
          gaDataLayer({
            ...telcoParams,
            eventParams: SignUpButtonFails,
            offer: offer,
            type: getType(offer?.subscriptionTypeId),
            errorParams: {
              code: data.responseCode,
              reason: "Invalid mobile number",
            },
            connection: gaHE(isHeFlow),
          });
        } else if (data.responseCode === TIMEOUT) {
          sendEvent(page, TIMEOUT_HIT, `${number}`);
          navigate(ksaZainURLs.timeout, {
            state: {
              prevPath: location.pathname,
              isHE: gaHE(isHeFlow),
              offer: offer,
              customerStatus: zainPackage?.customerStatus,
            },
          });
        } else {
          gaDataLayer({
            ...telcoParams,
            eventParams: SignUpButtonFails,
            offer: offer,
            type: getType(offer?.subscriptionTypeId),
            errorParams: {
              code: data.responseCode,
              reason: "Something went wrong",
            },
            connection: gaHE(isHeFlow),
          });
          sendEvent(page, SENDOTP_ERROR, data.responseCode);
          navigate(ksaZainURLs.error, {
            state: {
              prevPath: location?.pathname,
              isHE: gaHE(isHeFlow),
              type: getType(offer?.subscriptionTypeId),
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        navigate(ksaZainURLs.error, {
          state: {
            prevPath: location?.pathname,
            isHE: gaHE(isHeFlow),
            type: getType(offer?.subscriptionTypeId),
          },
        });
      });
  };

  const checkEligibilityAndSendOtp = async () => {
    const tid = Date.now();
    const number = `${mobilecode}${inputVal}`;
    setLoader(true);
    setLoaderText(t("OTT_TLP_getProduct_Loader_Msg"));
    let product = {};
    try {
      product = await getProductsQueryParams(telcoName, countryCode, number);
    } catch (error) {
      sendEvent(page, "Product-API-FAILED-VOICE-NONHE", number);
      navigate(ksaZainURLs.error, {
        state: { prevPath: location?.pathname, isHE: isHeFlow, type: null },
      });
    }
    if (product.responseCode === SUCCESS) {
      if (product.customerStatus === "ACTIVE") {
        navigate(ksaZainURLs.subError, {
          state: {
            prevPath: location?.pathname,
            isHE: `${
              isHeFlow ? "he" : "nonhe"
            }-${product.customerType.toLowerCase()}`,
            type: getType(
              product?.productDetails[0]?.subscriptionTypeId || null
            ),
          },
        });
        return;
      }
      setZainPackage(product);
      setLoaderText(t("OTT_TLP_sendotp_Loader_Msg"));
      const offer = product.productDetails[0];

      // handleSendOtp(tid, offer, product, number);
      if (offer.subscriptionTypeId.toLowerCase() === "voice-hb") {
        subscribeCustomer(
          number,
          offer,
          number,
          offer.customerStatus,
          offer.customerType
        );
      } else {
        handleSendOtp(tid, offer, product, number);
      }
    }
  };

  const validationOnNumber = (e) => {
    const firstChar = Number(e[0]);
    if (!firstChar) return true;
    if (defaultPackage === "VOICE" && firstChar === 5) {
      return true;
    } else if (defaultPackage === "MBB" && firstChar === 8) {
      return true;
    } else if (defaultPackage === "FIBER" && firstChar === 7) {
      return true;
    }
    return false;
  };

  const onHandleChange = (e) => {
    const lengthValidation = defaultPackage === "MBB" ? 12 : 9;
    const val = e.target.value;
    if (val[0] === "0") {
      return false;
    }
    if (
      (validator.isNumeric(val) || validator.isEmpty(val)) &&
      validator.isLength(val, { max: lengthValidation })
    ) {
      const validate = validationOnNumber(val);
      if (validate) {
        setInputVal(val);
        validator.isLength(val, { min: lengthValidation })
          ? setIsValidate(true)
          : setIsValidate(false);
      }
    }
    if (
      !zainPackage.customerStatus &&
      defaultPackage !== "VOICE" &&
      heFiberFlow
    ) {
      validator.isLength(val, { min: lengthValidation }) &&
      validator.isLength(inputValMob, { min: lengthValidation })
        ? setIsValidate(true)
        : setIsValidate(false);
    }
  };

  const onHandleMobChange = (e) => {
    const targetValue = e.target.value;
    if (targetValue[0] === "0") {
      return false;
    }
    if (
      (validator.isNumeric(targetValue) || validator.isEmpty(targetValue)) &&
      validator.isLength(targetValue, { max: 9 })
    ) {
      setInputValMob(targetValue);
      validator.isLength(targetValue, { min: 9 }) &&
      validator.isLength(inputVal, { min: 9 })
        ? setIsValidate(true)
        : setIsValidate(false);
    }
  };

  const onHandleSubmitClick = () => {
    const tid = Date.now();
    const number = `${inputVal}`;
    const number2 = inputValMob ? `${mobilecode}${inputValMob}` : null;
    const offer = zainPackage.productDetails[0];
    const customerStatus = zainPackage.customerStatus;
    const customerType = zainPackage.customerType;
    if (defaultPackage === "VOICE") {
      sendEvent(page, "Click on voice he", number);
      handleSendOtp(tid, offer, zainPackage, `${mobilecode}${number}`);
    } else {
      sendEvent(page, "Click on subscriber btn", number);
      subscribeCustomer(number, offer, number2, customerStatus, customerType);
    }
  };

  const onHandleClick = async () => {
    if (defaultPackage === "VOICE") {
      sendEvent(page, "Click on voice nonhe");
      checkEligibilityAndSendOtp();
    } else if (defaultPackage === "FIBER") {
      sendEvent(page, "Click on fiber nonhe");
      const customerNumber = `${mobilecode}${inputVal}`;
      setLoader(true);
      setLoaderText(t("OTT_TLP_getProduct_Loader_Msg"));
      try {
        const zainFiberPack = await getProductsQueryParams(
          telcoName,
          countryCode,
          customerNumber
        );
        if (zainFiberPack && zainFiberPack.productDetails.length > 0) {
          setZainPackage(zainFiberPack);
          setHeFiberFlow(true);
        }
        if (!zainFiberPack.customerStatus) {
          setIsValidate(false);
        } else {
          setIsValidate(true);
        }
        setLoader(false);
      } catch (err) {
        sendEvent(page, `Error in fiber package load ${customerNumber}`);
        navigate(ksaZainURLs.error, {
          state: {
            prevPath: location?.pathname,
            isHE: gaHE(isHeFlow),
            type: null,
          },
        });
      }
    }
  };

  const gaHE = (boolHe) => {
    let heVal = "";
    if (defaultPackage === "VOICE") {
      heVal = boolHe ? "he-voice" : "nonhe-voice";
    } else if (defaultPackage === "FIBER") {
      heVal = "nonhe-fiber";
    } else if (defaultPackage === "MBB") {
      heVal = "he-mbb";
    } else {
      heVal = "nonhe";
    }
    return heVal;
  };
  if (loader) {
    return <Loader loaderText={loaderText} />;
  }
  return (
    <>
      <Box className="wrapper">
        {heFiberFlow ? (
          <>
            {zainPackage?.productDetails && (
              <Typography
                variant="h1"
                className={classes.heading}
                dangerouslySetInnerHTML={{
                  __html: t(
                    ZAIN_KSA_PACKAGES[
                      zainPackage?.productDetails[0]?.subscriptionTypeId
                    ]
                  ),
                }}
              ></Typography>
            )}
            <Typography variant="body2" align="left" className="mT4">
              {t([
                `${ZAIN_KSA_DEFAULT_PACKAGES[defaultPackage]?.heading}`,
                "OTT_TLP_selectPackage_zainksa_VoiceHeading",
              ])}
            </Typography>
            <Input
              countryCode="+966"
              placeHolder={
                ZAIN_KSA_DEFAULT_PACKAGES[defaultPackage]?.placeHolder
              }
              onChange={(e) => onHandleChange(e)}
              value={inputVal}
              isEdit={defaultPackage === "VOICE" && true}
              disabledInput
              onEditClick={() => setHeFiberFlow(false)}
              disableCountryCode={defaultPackage === "MBB"}
            />
            {ZAIN_KSA_DEFAULT_PACKAGES[defaultPackage]?.requiredOTP && (
              <Typography variant="subtitle2" align="left">
                {t("OTT_TLP_selectPackage_zainksa_Otp_Text")}
              </Typography>
            )}
            {errorMsg && (
              <Typography className={classes.incorrectMsg}>
                {t("OTT_TLP_selectPackage_incorrectMsg_Text")}
              </Typography>
            )}
            {!zainPackage.customerStatus && defaultPackage !== "VOICE" && (
              <>
                <Typography variant="body2" align="left" className="mT4">
                  {t("OTT_TLP_selectPackageKwZain_selectMobileZain_Text")}
                </Typography>
                <Input
                  countryCode="+966"
                  placeHolder="XXXXXXXXX"
                  onChange={(e) => onHandleMobChange(e)}
                  value={inputValMob}
                />
              </>
            )}
            <Grid className="mT4">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                onClick={onHandleSubmitClick}
                disabled={!isValidate}
              >
                <Typography variant="button">
                  {t("OTT_TLP_selectPackage_zainksa_submitText")}
                </Typography>
              </Button>
            </Grid>
            {/* <PrivacyText
              text={ZAIN_KSA_DEFAULT_PACKAGES[defaultPackage]?.privacyText}
            /> */}
            <PrivacyText
              text={ZAIN_KSA_DEFAULT_PACKAGES["VOICE"]?.privacyText}
            />
          </>
        ) : (
          <>
            {/* <Typography variant="h1">{t('OTT_TLP_selectPackage_Title_Ramadan')}</Typography>
            <Typography className="mT2" variant="body2">{t('OTT_TLP_selectPackage_Banner_subTitle_Ramadan')}</Typography> */}
            <Typography variant="h1">
              {t("OTT_TLP_selectPackage_Title")}
            </Typography>
            {!isHeFlow && (
              <Box display="flex" mt={2} mb={3} flexDirection="column">
                {/* {Object.keys(ZAIN_KSA_DEFAULT_PACKAGES).map((p, key) => (
                  <Package
                    key={key}
                    isDisabled={
                      ZAIN_KSA_DEFAULT_PACKAGES[p].category != defaultPackage
                    }
                    onClick={() =>
                      choosePackage(ZAIN_KSA_DEFAULT_PACKAGES[p].category)
                    }
                    isActive={
                      defaultPackage
                        ? ZAIN_KSA_DEFAULT_PACKAGES[p].category ===
                          defaultPackage
                        : true
                    }
                    text={t(ZAIN_KSA_DEFAULT_PACKAGES[p].offerName)}
                  />
                ))} */}
                <Package
                  key={0}
                  isDisabled={
                    ZAIN_KSA_DEFAULT_PACKAGES["VOICE"].category !=
                    defaultPackage
                  }
                  onClick={() =>
                    choosePackage(ZAIN_KSA_DEFAULT_PACKAGES["VOICE"].category)
                  }
                  isActive={
                    defaultPackage
                      ? ZAIN_KSA_DEFAULT_PACKAGES["VOICE"].category ===
                        defaultPackage
                      : true
                  }
                  text={t(ZAIN_KSA_DEFAULT_PACKAGES["VOICE"].offerName)}
                />
              </Box>
            )}
            {showMsg.toShow ? (
              <Typography variant="h2">{t(showMsg.msg)}</Typography>
            ) : (
              <>
                <Typography variant="body2" align="left">
                  {t([
                    `${ZAIN_KSA_DEFAULT_PACKAGES[defaultPackage]?.heading}`,
                    "OTT_TLP_selectPackage_zainksa_VoiceHeading",
                  ])}
                </Typography>
                <Input
                  countryCode="+966"
                  placeHolder={
                    defaultPackage
                      ? ZAIN_KSA_DEFAULT_PACKAGES[defaultPackage]?.placeHolder
                      : "5XXXXXXXX"
                  }
                  onChange={(e) => onHandleChange(e)}
                  value={inputVal}
                />
                {errorMsg && (
                  <Typography className={classes.incorrectMsg}>
                    {t("OTT_TLP_selectPackage_incorrectMsg_Text")}
                  </Typography>
                )}
                {ZAIN_KSA_DEFAULT_PACKAGES[defaultPackage]?.requiredOTP && (
                  <Typography variant="subtitle2" align="left">
                    {t("OTT_TLP_selectPackage_zainksa_Otp_Text")}
                  </Typography>
                )}
                <Grid className="mT4">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    fullWidth
                    onClick={onHandleClick}
                    disabled={!isValidate}
                  >
                    <Typography variant="button">
                      {t("OTT_TLP_selectPackage_common_JoinNowText")}
                    </Typography>
                  </Button>
                </Grid>
                <PrivacyText
                  text={
                    defaultPackage
                      ? ZAIN_KSA_DEFAULT_PACKAGES[defaultPackage]?.privacyText
                      : "OTT_TLP_selectPackage_zainksa_VoicePrivacyText"
                  }
                />
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default SelectPackage;
