import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AppBar, Grid, Button } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useStyles } from "./header.styles";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/actions/appActions";

const Header = ({ withBackBtn, position, logoEndpoint }) => {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const locale = new URLSearchParams(window.location.search).get("locale");

  const classes = useStyles({ lng: locale });
  const pathname = window.location.pathname.split("/");
  const countryCode = pathname[1];
  const telcoName = pathname[2];

  useEffect(() => {
    // const lng = (countryCode !== 'ma' && telcoName !== 'iam' ) ? (locale === "ar-AE" ? "ar" : "en") : (locale === "en-US" ? "en" : "fr");
    let lng = "en";
    if (countryCode !== "ma" && telcoName !== "iam" && countryCode !== "tn") {
      lng = locale === "ar-AE" ? "ar" : "en";
    } else if (countryCode === "ma" && telcoName === "orange") {
      lng = locale === "ar-AE" ? "ar" : "fr";
    } else if (countryCode === "tn" && telcoName === "ooredoo") {
      lng = locale === "fr-TN" ? "fr" : "ar";
    } else {
      lng = locale === "en-US" ? "en" : "fr";
    }
    i18n.changeLanguage(lng);
    dispatch(changeLanguage({ lng }));
  }, [locale]);

  const handleClick = () => {
    // setAnchorEl(event.currentTarget);
    const href = window.location;
    const newUrl = new URL(href);
    if (telcoName === "iam" && countryCode === "ma") {
      if (!locale || locale === "fr-MA") {
        newUrl.searchParams.set("locale", "en-US");
      } else {
        newUrl.searchParams.set("locale", "fr-MA");
      }
    } else if (telcoName === "orange" && countryCode === "ma") {
      if (!locale || locale === "fr-MA") {
        newUrl.searchParams.set("locale", "ar-AE");
      } else {
        newUrl.searchParams.set("locale", "fr-MA");
      }
    } else if (telcoName === "ooredoo" && countryCode === "tn") {
      if (!locale || locale === "ar-AE") {
        newUrl.searchParams.set("locale", "fr-TN");
      } else {
        newUrl.searchParams.set("locale", "ar-AE");
      }
    } else {
      if (!locale || locale === "en-US") {
        newUrl.searchParams.set("locale", "ar-AE");
      } else {
        newUrl.searchParams.set("locale", "en-US");
      }
    }
    navigate(newUrl, { state: locationState });
    //  event.currentTarget.firstElementChild.firstElementChild.textContent !== "English" ?selectLang("ar"):selectLang("en")
  };
  // const selectLang = (lng) => {
  //   i18n.changeLanguage(lng);
  //   dispatch(changeLanguage({ lng }));
  //   setAnchorEl(null);
  // };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const state = useSelector((state) => ({
    lng: state?.app?.lng,
  }));
  return (
    <AppBar position={position} className={classes.appBar}>
      <Toolbar
        style={{
          justifyContent: "center",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Grid container justify="flex-start" className={classes.pL} item xs={2}>
          {withBackBtn && (
            <IconButton
              component={Link}
              to="././"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <ArrowBackIosIcon className={classes.iconColor} />
            </IconButton>
          )}
        </Grid>
        <Grid container item xs={8} justify="center">
          <img
            src={`${process.env.S3_ENDPOINT}/${logoEndpoint}`}
            className="logo-osn"
            id="logo"
          />
        </Grid>
        <Grid container justify="flex-end" item xs={2}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            id="lng-toggle"
          >
            <Typography className={classes.menuButton} noWrap>
              {telcoName !== "iam" &&
                countryCode !== "ma" &&
                countryCode !== "tn" &&
                ((state?.lng === "ar" && `${t("OTT_Language_Arabic")}`) ||
                  t("OTT_Language_Arabic"))}
              {telcoName === "iam" &&
                countryCode === "ma" &&
                ((state?.lng === "fr" && `${t("OTT_Language_French")}`) ||
                  t("OTT_Language_French"))}
              {((telcoName === "orange" && countryCode === "ma") ||
                (telcoName === "ooredoo" && countryCode === "tn")) &&
                `${t("OTT_Language_Arabic_Ma_Orange")}`}
            </Typography>
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  withBackBtn: PropTypes.bool,
  position: PropTypes.string,
  logoEndpoint: PropTypes.string,
};

export default Header;
