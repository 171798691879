import React, { useLayoutEffect, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { create } from "jss";
import rtl from "jss-rtl";
import {
  ThemeProvider,
  StylesProvider,
  jssPreset,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BrowserRouter as Router } from "react-router-dom";
import Loader from './components/Loader';
import AppRoutes from "./AppRoutes";
import StandardTheme from "./theme";
import GlobalCss from './globalCss';
import TelcoProvider from './utils/TelcoProvider';
import { initGTM } from './utils/GA';
import { getIpCall } from "./redux/actions/appActions";
import { setPaddingForWrapperBg } from './utils/common';

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const jss = true && create({ plugins: [...jssPreset().plugins, rtl()] });
  const dispatch = useDispatch();

  const { lng, loader, loaderText } = useSelector((state) => {
    return {
      lng: state?.app?.lng,
      loader: state?.apiState?.showLoader,
      loaderText: state?.apiState?.loaderText,
    };
  });


  useEffect(() => {
    window.addEventListener('resize', () => {setPaddingForWrapperBg(isMobile)})
  }, [isMobile])

  useEffect(() => {
    dispatch(getIpCall());
    initGTM();
  }, [])

  useLayoutEffect(() => {
    document.body.setAttribute("dir", lng === "ar" ? "rtl" : "ltr");
  }, [lng]);

  return (
    <TelcoProvider>
      <StylesProvider jss={jss}>
        <ThemeProvider
          theme={{ ...StandardTheme, direction: lng === "ar" ? "rtl" : "ltr" }}
        >
          <GlobalCss />
          {loader && <Loader {...{ loaderText }} />}
          <Router>
            <AppRoutes />
          </Router>
        </ThemeProvider>
      </StylesProvider>
    </TelcoProvider>
  );
}

export default App;
