import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  body1: {
    marginTop: 10,
    lineHeight: 1,
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: props => props.lng === 'ar' ? 'FrutigerLTArabicRoman' : props.fontFamili,
  }
}));

const Banner = ({ imageSrc, imageAlt, txt, fallbackSrc, id, lng, fontFamili="FaricyNewRegular", isArabicImg=false, alignment='center', imgWidth= '300', imgHeight = 'auto', mt=3 }) => {
  const classes = useStyles({lng, fontFamili});
  const [src, setSrc] = useState(imageSrc);

  const onError = () => {
    setSrc(fallbackSrc);
  }

  return (
    <>
      <Box display="flex" justify="center" mt={mt} css={{ justifyContent: alignment }} id={id}>
        {isArabicImg && 
          <img
            src={lng === 'ar' ? `${src}_ar.png` : `${src}.png`}
            srcSet={lng === 'ar' ? `${src}_ar@2x.png 2x, ${src}_ar@3x.png 3x` :  `${src}@2x.png 2x, ${src}@3x.png 3x`}
            alt={imageAlt}
            width={imgWidth}
            height={imgHeight}
            onError={onError}
        />
        }
        {!isArabicImg && 
          <img
            src={`${src}.png`}
            srcSet={`${src}@2x.png 2x,
            ${src}@3x.png 3x`}
            alt={imageAlt}
            width={imgWidth}
            height={imgHeight}
            onError={onError}
          />
        }
      </Box>
      {txt && <Typography variant="h4" align={alignment} className={classes.body1}>{txt}</Typography>}
    </>
  );
}

Banner.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  txt: PropTypes.string,
  fallbackSrc: PropTypes.string,
  id: PropTypes.string,
  lng: PropTypes.string,
  isArabicImg:PropTypes.bool,
  alignment: PropTypes.string,
  fontFamili: PropTypes.string,
}

Banner.defaultProps = {
  fallbackSrc: '/assets/img/icon-usp-mixedusps',
}

export default Banner;