export const LANDED = 'LANDED';
export const CLICKED = 'CLICKED JOINNOW';
export const CLICKED_OTP = 'CLICKED OTP';
export const SUCCESS_API = 'SUCCESS_API';
export const ERROR_API = 'ERROR_API';
export const LANDED_WITH_REDIRECT = 'LANDED_WITH_REDIRECT';
export const LANDED_WITH_ERROR = 'LANDED_WITH_ERROR';
export const CLICKED_RESEND = 'CLICKED RESEND';
export const CLICKED_RESEND_FAILED = 'CLICKED RESEND FAILED';
export const SENDOTP_SUCCESS = 'SENDOTP SUCCESS';
export const SENDOTP_ERROR = 'SENDOTP ERROR';
export const CUSTOMER_EXIST = 'CUSTOMER EXIST';
export const USER_NOT_ELIGIBLE_EVENTS = 'USER NOT ELIGIBLE';
export const MOBILE_ERROR = 'INVALID MOBILE';
export const OTP_SUCCESS = 'Verify otp success';
export const OTP_INCORRECT = 'Verify otp incorrect';
export const OTP_FAILED = 'Verify otp failed';
export const CIVIL_SUCCESS = 'CIVIL SUCCESS';
export const CIVIL_FAILED = 'CIVIL FAILED';
export const TIMEOUT_HIT = 'TIMEOUT';

export const SignUpButtonClicks = {
  eventCategory: 'Telco - SUF',
  eventAction: 'Step 1 - Signup button clicks'
};

export const SignUpButtonFails = {
  eventCategory: 'Telco - SUF',
  eventAction: 'Step 1 - Signup button Fail'
};

export const VerifyOTP = {
  eventCategory: 'Telco - SUF',
  eventAction: 'Step 2 - Verify OTP',
};

export const VerifyOTPFails = {
  eventCategory: 'Telco - SUF',
  eventAction: 'Step 2 - Verify OTP Fail',
};

export const VerifyCivilId = {
  eventCategory: 'Telco - SUF',
  eventAction: 'Step 2 - Verify CivilId',
};

export const VerifyCivilIdFails = {
  eventCategory: 'Telco - SUF',
  eventAction: 'Step 2 - Verify CivilId Fail',
};

export const PaymentSuccess = {
  eventCategory: 'Telco - SUF',
  eventAction: 'Step 3 - Payment Success',
};

export const PaymentFails = {
  eventCategory: 'Telco - SUF',
  eventAction: 'Step 3 - Payment Fail',
};

export const TelcoJournyStart = {
  eventCategory:'Telco - SUF',
  eventAction:'Telco Journey Starts',
}