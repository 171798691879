import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from "react-i18next";
import { useStyles } from './footer.styles';
import Banner from '../../components/Banner';

import {
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
const currentYear = new Date().getFullYear();

const Footer = ({ isRevamp= false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const lng = useSelector((state) => state?.app?.lng);
  const classes = useStyles({lng: lng});
  const { t } = useTranslation();
  if (!isRevamp) {
    return null;
  }
  return (
    <Grid container className={classes.footer} direction="column">
      <Box className={classes.footerTop}>
          <Typography
              variant="h4"
              className={classes.tagline}
              dangerouslySetInnerHTML={{
                __html: t('OTT_TLP_footer_tagline'),
              }}
            ></Typography>
            <div className={classes.supportedDevice}>
              <Banner
                imageSrc={`${process.env.S3_ENDPOINT}/ulp/support-devices`}
                imageAlt="mixedusps"
                fallbackSrc={`/assets/img/support-devices`}
                txt=""
                id='support-devices'
                lng={lng}
                imgWidth= {isMobile ? "380" : "505"}
                isArabicImg={true}
                alignment='right'
                mt={1}
              />
            </div>
      </Box>
      <Box className={classes.footerBottom}>
        <div className={classes.footerMenu}>
          <ul className={classes.list} dangerouslySetInnerHTML={{__html: t('OTT_TLP_footer_link'),}}></ul>          
        </div>
        <div className={classes.socialLinkWrap}>
          <ul className={classes.socialLink}>
            <li><a href="https://www.facebook.com/OSNPlusOfficial" rel="noopener noreferrer" target="_blank" className="s-icon fb">facebook</a></li>
            <li><a href="https://twitter.com/OSNplus" rel="noopener noreferrer" target="_blank" className="s-icon tw">twitter</a></li>
            <li><a href="https://www.youtube.com/c/OSNplus" rel="noopener noreferrer" target="_blank" className="s-icon yt">youtube</a></li>
            <li><a href="https://www.instagram.com/osn/" rel="noopener noreferrer" target="_blank" className="s-icon insta">instagram</a></li>
            <li><a href="https://www.tiktok.com/@osnplus" rel="noopener noreferrer" target="_blank" className="s-icon tiktok">tiktok</a></li>
          </ul>
        </div>
        <div className={classes.storeIcons}>
          <a href={process.env.APPLE_STORE_LINK}>
            <img
              src="/assets/img/store-apple.png"
              srcSet="/assets/img/store-apple@2x.png 2x, /assets/img/store-apple@3x.png 3x"
              className="logo-osn"
              id="start-watch-apple"
            />
          </a>
          <a href={process.env.GOOGLE_STORE_LINK}>
            <img
              src="/assets/img/store-google.png"
              srcSet="/assets/img/store-google@2x.png 2x, /assets/img/store-google@3x.png 3x"
              className="logo-osn"
              id="start-watch-google"
            />
          </a>
        </div>
        <Box className={classes.copyRight} dangerouslySetInnerHTML={{__html: t('OTT_TLP_COPYRIGHT', {currentYear}),}}></Box>
      </Box>
      

    </Grid>
  );
}

Footer.propTypes = {
  withBackBtn: PropTypes.bool,
  position: PropTypes.string,
  logoEndpoint: PropTypes.string,
};

export default Footer;