import React, { lazy, Suspense } from 'react';
import Loader from '../components/Loader'


const loadable = (importFunc) => {
  const LazyComponent = lazy(importFunc);

  // eslint-disable-next-line react/display-name
  return props => (
    <Suspense fallback={<Loader />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;